import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import { DownloadIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { GetCompanyCaptableQuery, useGetCompanyCaptableQuery } from '@blockpulse3/graphql/hooks';
import { useGenerateCaptableUrlMutation } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { ShareholderTable } from '../ShareholderTable';

type Props = unknown;

/**
 * CapTableShareholders.
 *
 * @returns {JSX.Element}
 */
export function CapTableShareholders(): JSX.Element {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredCapTable, setFilteredCapTable] = useState<
    GetCompanyCaptableQuery['company']['capTable'] | null | undefined
  >(null);

  const t = useTranslations();

  const { companyId = '' } = useParams();

  const [generateCaptableUrl, { loading: isCaptableLoading }] = useGenerateCaptableUrlMutation();

  const captableReq = useGetCompanyCaptableQuery({
    variables: { companyId },
    onCompleted: (data) => {
      setFilteredCapTable(data?.company?.capTable);
    },
  });
  const initialCaptable = captableReq.data?.company?.capTable;

  const handleDownloadCaptable = (): void => {
    if (!companyId) return;

    generateCaptableUrl({
      variables: { companyId },
      onCompleted: (data) => {
        window.open(data?.generateCaptableUrl, '_blank');
      },
    });
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  if (captableReq.loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  if (captableReq.error) {
    return <ErrorQueryCard />;
  }

  const shareholders = filteredCapTable?.shareholders || [];

  const handleSearchInputSubmit = (value: string): void => {
    if (!filteredCapTable) {
      return;
    }

    if (!value) {
      setFilteredCapTable(initialCaptable);
      return;
    }

    const searchInputLowerCase = value.toLowerCase();
    const newShareholder = initialCaptable?.shareholders?.filter((shareholder) => {
      if (!shareholder.identity) return false;
      return (
        shareholder.identity.name.toLowerCase().includes(searchInputLowerCase) ||
        shareholder.identity.email?.toLowerCase().includes(searchInputLowerCase) ||
        shareholder.identity.identifier?.toLowerCase().includes(searchInputLowerCase)
      );
    });

    setFilteredCapTable((crr) => ({ ...crr, shareholders: newShareholder }));
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    setFilteredCapTable(initialCaptable);
  };

  return (
    <Card>
      <CardHeader
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('Shareholder', { nb: shareholders.length })}</Heading>
      </CardHeader>
      <CardBody as={Stack} spacing="4">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <HStack>
            <InputGroup width={{ base: 'full', md: '300px' }}>
              <Input
                paddingRight="80px"
                placeholder={t('Search')}
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyDown={(e): void => {
                  if (e.key === 'Enter') {
                    handleSearchInputSubmit(searchInput);
                  }
                }}
              />
              <InputRightElement
                flexDirection="row-reverse"
                justifyContent="space-between"
                paddingX="1"
                width="80px"
              >
                <IconButton
                  aria-label="search-database"
                  icon={<Icon as={SearchIcon} />}
                  size="sm"
                  variant="secondary"
                  onClick={(): void => handleSearchInputSubmit(searchInput)}
                />
                {!!searchInput && (
                  <IconButton
                    aria-label="reset"
                    icon={<Icon as={XCircleIcon} />}
                    size="sm"
                    variant="ghost"
                    onClick={handleSearchInputReset}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <Text
              color="gray.800"
              fontSize="sm"
              px={{ base: '0', md: '3' }}
              rounded="md"
              textAlign="center"
            >
              {t('ResultsCount', { nb: shareholders?.length })}
            </Text>
          </HStack>
          {companyId && shareholders.length > 0 && (
            <Button
              isLoading={isCaptableLoading}
              loadingText={t('DocumentGenerationInProgress')}
              variant="secondary"
              width={{ base: 'fit-content' }}
              onClick={(): void => handleDownloadCaptable()}
            >
              <HStack>
                <Text>{t('ExportAction')}</Text>
                <Icon as={DownloadIcon} boxSize="5" color="secondary" />
              </HStack>
            </Button>
          )}
        </Stack>
        <ShareholderTable capTable={filteredCapTable} />
      </CardBody>
    </Card>
  );
}

export type CapTableShareholdersProps = Props;
