import { Button, Card, HStack, Icon, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { UploadIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { SignatureDocumentCache, routes } from '@blockpulse3/data/shared';
import {
  DocumentStatus,
  FundraisingType,
  OperationDocumentType,
  OperationStatus,
  useGetBankAccountByCompanyQuery,
  useGetCompanyQuery,
  useGetOperationQuery,
  usePreviewOperationDocumentLazyQuery,
  useValidateOperationDocumentMutation,
} from '@blockpulse3/graphql/hooks';
import { getURLObject } from '@blockpulse3/helpers';
import {
  ErrorQueryCard,
  IconButtonWithTooltip,
  CONTENT_ERROR as PDF_URL_ERROR,
  CONTENT_LOADING as PDF_URL_LOADING,
  Progression,
  ProgressionStep,
  SignDocumentModal,
  SignatureDocumentButton,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { getFundraisingDocuments, getFundraisingProgressionStep } from '../utils';
import { UpdateMifidFormModal } from './UpdateMifidFormModal';
import { UploadSignedPVModal } from './UploadSignedPVModal';

/**
 * FundraisingProgression.
 * Progression section with sign document CTAs of the Fundraising.
 *
 * @returns {JSX.Element}
 */
export function FundraisingProgression(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');

  const { user } = useAuthUser();

  const { operationId = '', companyId = '' } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const signatureModal = useDisclosure();
  const uploadModal = useDisclosure();
  const updateMifidModal = useDisclosure();

  const isSuperAdmin = user?.isSuperAdmin;

  const companyReq = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const operationReq = useGetOperationQuery({ variables: { operationId }, skip: !operationId });
  const bankAccountReq = useGetBankAccountByCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const [loadPreview] = usePreviewOperationDocumentLazyQuery();
  const [validateDocument, { loading: isDocumentValidating }] =
    useValidateOperationDocumentMutation();

  const company = companyReq.data?.company;
  const operation = operationReq.data?.operation;
  const bankAccount = bankAccountReq.data?.getBankAccountByCompany;

  /* ** Redefine to upperCase constants for clarity (cf. graphql naming conventions) ** */
  const PV_EXERCICE_VERSION =
    operation?.pvExerciceDocumentType || OperationDocumentType.PV_EXERCICE;
  const PV_CONSTATATION_VERSION =
    operation?.pvConstatationDocumentType || OperationDocumentType.PV_CONSTATATION;

  /* ** Signature modal states ** */
  const [signatureModalTitle, setSignatureModalTitle] = useState<string>('');
  const [isSignatureModalDisabled, setIsSignatureModalDisabled] = useState<boolean>(false);
  /* ** Current document in signature ** */
  const [signDocumentType, setSignDocumentType] = useState<OperationDocumentType | null>(null);
  const [signDocumentStatus, setSignDocumentStatus] = useState<DocumentStatus | null>(null);
  /* ** Cache fundraising document previews ** */
  const [documentPreviews, setDocumentPreviews] = useState<
    SignatureDocumentCache<OperationDocumentType>
  >({
    [PV_EXERCICE_VERSION]: PDF_URL_LOADING,
    [PV_CONSTATATION_VERSION]: PDF_URL_LOADING,
  });

  if (companyReq.loading || operationReq.loading || bankAccountReq.loading) {
    return (
      <Skeleton>
        <Card h="160px" />
      </Skeleton>
    );
  }

  if (companyReq.error || operationReq.error || !(company && operation?.fundraising)) {
    return <ErrorQueryCard h="160px" />;
  }

  const documents = operation.documents || [];
  const fundraising = operation.fundraising;

  /* ** Is current user a signer and able to be redirected to SignatureIt ** */
  const isUserSigner = company?.signer?.individualIdentity?.id === user?.individualIdentity?.id;

  const fundraisingRoutes =
    fundraising?.type === FundraisingType.CROWDFUNDING
      ? routes.company.newFundraising.crowdfunding
      : routes.company.newFundraising.private;

  /* ** Preview URL rendered in the signature modal ** */
  const signDocumentURL = signDocumentType ? documentPreviews[signDocumentType] : PDF_URL_LOADING;

  /* ** Fundraising documents to sign ** */
  const fundraisingDocuments = getFundraisingDocuments(documents);

  /* ** Sign button states ** */
  const currentStep = getFundraisingProgressionStep(operation.status);
  const isOperationEditable = operation.status === OperationStatus.DRAFT;
  const isMifidEditable = isSuperAdmin && isOperationEditable && operation.rawMifidSections;
  const isPVExerciceDisabled = !(operation.isExternalIBAN || bankAccount);
  const isPVConstatationDisabled = !(
    currentStep >= 3 && !!fundraisingDocuments[PV_CONSTATATION_VERSION]?.document
  );

  /* ** Fundraising document status ** */
  const pvExerciceStatus = fundraisingDocuments[PV_EXERCICE_VERSION]?.document?.status;
  const pvConstatationStatus = fundraisingDocuments[PV_CONSTATATION_VERSION]?.document?.status;

  const handlePreviewModalClose = (): void => {
    signatureModal.onClose();
  };

  const handleUploadModalClose = (): void => {
    uploadModal.onClose();
  };

  const handleDocumentSign = (): void => {
    if (!signDocumentType || !operation) return;

    if (fundraising) {
      validateDocument({
        variables: {
          validateOperationDocumentInput: {
            operationId: operation.id,
            documentType: signDocumentType,
          },
        },
        onCompleted: (data) => {
          if (data.validateOperationDocument?.signer?.signingUrl) {
            window.open(data.validateOperationDocument.signer.signingUrl, '_self');
          } else {
            successToast({ title: t('DocumentSentToSignatory') });
            setIsSignatureModalDisabled(true);
          }
          operationReq.refetch();
        },
        onError: () => {
          errorToast({ title: t('SignatureSendingError') });
        },
      });
    }
  };

  const handleDocumentClick = (type: OperationDocumentType): void => {
    const title = type as IntlDocumentValues;
    const fundraisingDocument = fundraisingDocuments[type];

    setSignDocumentType(type);
    setSignatureModalTitle(i18nDocumentValues(title));

    if (fundraisingDocument) {
      const { lastRemindEmailSentAt, createdAt } = fundraisingDocument.document.signature || {};
      const shouldRemind = dayjs(lastRemindEmailSentAt || createdAt).isBefore(
        dayjs().subtract(1, 'h'),
      );
      setIsSignatureModalDisabled(!shouldRemind);
      setSignDocumentStatus(fundraisingDocument.document.status);
    } else {
      setIsSignatureModalDisabled(false);
      setSignDocumentStatus(null);
    }

    signatureModal.onOpen();

    if (typeof documentPreviews[type] !== 'string') {
      loadPreviewURL(type);
    }
  };

  const handleUploadDocumentClick = (type: OperationDocumentType): void => {
    const title = type as IntlDocumentValues;

    setSignDocumentType(type);
    setSignatureModalTitle(i18nDocumentValues(title));

    uploadModal.onOpen();
  };

  const loadPreviewURL = (type: OperationDocumentType): void => {
    if (fundraising) {
      loadPreview({
        variables: {
          documentType: type,
          previewOperationDocumentId: operation.id,
        },
        onCompleted: ({ pdfURL }: { pdfURL: string }) => {
          const objURL = getURLObject(pdfURL, 'application/pdf');
          setDocumentPreviews((curr) => ({ ...curr, [type]: objURL }));
        },
        onError: () => {
          setDocumentPreviews((curr) => ({
            ...curr,
            [type]: PDF_URL_ERROR,
          }));
        },
      });
    }
  };

  return (
    <Card>
      <Progression currentStep={currentStep} totalSteps={4}>
        <ProgressionStep isCompleted title={t('Setup')}>
          {isOperationEditable && (
            <Link
              to={generatePath(fundraisingRoutes.edit.full, {
                companyId,
                operationId,
              })}
            >
              <Text fontSize="xs" fontWeight="semibold" textDecoration="underline">
                {t('Modify')}
              </Text>
            </Link>
          )}
          {isMifidEditable && (
            <Button size="sm" variant="secondary" w="fit-content" onClick={updateMifidModal.onOpen}>
              {t('MifidUpdateLabel')}
            </Button>
          )}
        </ProgressionStep>
        <ProgressionStep isCompleted={currentStep > 1} title={t('LaunchFundraising')}>
          <Text color="gray.600" fontSize="xs">
            {t.rich('SignPVToStart', {
              important: (chunks) => <b>{chunks}</b>,
            })}
          </Text>
          <HStack>
            <SignatureDocumentButton
              data-cy="sign-president-exercise"
              flex="1"
              isDisabled={isPVExerciceDisabled}
              size="sm"
              status={pvExerciceStatus}
              title={i18nDocumentValues('PV_EXERCICEshort')}
              onClick={(): void => handleDocumentClick(PV_EXERCICE_VERSION)}
            />
            {pvExerciceStatus !== DocumentStatus.SIGNED && !isPVExerciceDisabled && (
              <IconButtonWithTooltip
                aria-label="upload-signed-pv-exercise"
                data-cy="upload-signed-pv-exercise"
                icon={<Icon as={UploadIcon} boxSize="18px" color="gray.700" />}
                isDisabled={isPVExerciceDisabled}
                label={t('UploadSignedPVExercice')}
                size="sm"
                variant="secondary"
                onClick={(): void => handleUploadDocumentClick(PV_EXERCICE_VERSION)}
              />
            )}
          </HStack>
        </ProgressionStep>
        <ProgressionStep isCompleted={currentStep > 2} title={t('Subscription', { nb: 1 })}>
          <Text color="gray.600" fontSize="xs">
            {t('SubscribersParticipatingInFundraising')}
          </Text>
        </ProgressionStep>
        <ProgressionStep isCompleted={currentStep > 3} title={t('Recognition')}>
          <Text color="gray.600" fontSize="xs">
            {t.rich('FundraisingClosureInfo', {
              important: (chunks) => <b>{chunks}</b>,
            })}
          </Text>
          <HStack>
            <SignatureDocumentButton
              data-cy="sign-pv-constatation"
              flex="1"
              isDisabled={isPVConstatationDisabled}
              size="sm"
              status={pvConstatationStatus}
              title={i18nDocumentValues('PV_CONSTATATIONshort')}
              onClick={(): void => handleDocumentClick(PV_CONSTATATION_VERSION)}
            />
            {pvConstatationStatus !== DocumentStatus.SIGNED && !isPVConstatationDisabled && (
              <IconButtonWithTooltip
                aria-label="upload-signed-pv-constatation"
                data-cy="upload-signed-pv-constatation"
                icon={<Icon as={UploadIcon} boxSize="18px" color="gray.700" />}
                isDisabled={isPVConstatationDisabled}
                label={t('UploadSignedPVConstatation')}
                size="sm"
                variant="secondary"
                onClick={(): void => handleUploadDocumentClick(PV_CONSTATATION_VERSION)}
              />
            )}
          </HStack>
        </ProgressionStep>
      </Progression>
      {signatureModal.isOpen && (
        <SignDocumentModal
          isDisabled={isSignatureModalDisabled}
          isOpen={signatureModal.isOpen}
          isSignatureLoading={isDocumentValidating}
          isUserSigner={isUserSigner}
          src={signDocumentURL}
          status={signDocumentStatus}
          title={signatureModalTitle}
          onCancel={handlePreviewModalClose}
          onClose={handlePreviewModalClose}
          onSign={handleDocumentSign}
        />
      )}
      {uploadModal.isOpen && signDocumentType && (
        <UploadSignedPVModal
          documentType={signDocumentType}
          isOpen={uploadModal.isOpen}
          onClose={handleUploadModalClose}
        />
      )}
      {updateMifidModal.isOpen && (
        <UpdateMifidFormModal isOpen={updateMifidModal.isOpen} onClose={updateMifidModal.onClose} />
      )}
    </Card>
  );
}
