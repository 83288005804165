import { Box, Card, CardBody, CardHeader, Heading, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

/**
 * OpportunityOperationInformations.
 * Computed information section of the opportunity operation.
 *
 * @returns {JSX.Element}
 */
export function OpportunityOperationInformations(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading, error } = useGetOperationQuery({
    variables: { operationId },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="260px" />
      </Skeleton>
    );
  }

  if (!data || error) {
    return <ErrorQueryCard h="260px" />;
  }

  const { operation } = data;
  if (operation.data?.__typename !== 'OpportunityData') {
    return <ErrorQueryCard h="260px" />;
  }

  const { totalApprovedAmount, totalApprovedSubscriptions, totalApprovedOrdinaryShares } =
    operation.data;

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('GlobalInformation')}</Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing="4">
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('ExpressedIntents')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberInt(totalApprovedSubscriptions)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('CountOfSharesToPurchase')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberInt(totalApprovedOrdinaryShares || 0)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalAmountOfIntents')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(totalApprovedAmount)}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
}
