import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyDocumentInfosFragment,
  CompanyDocumentType,
  DocumentStatus,
  IdentityType,
  RepresentativeRole,
  SpvStatus,
  useGetCompanyDocumentsQuery,
  useGetSpvQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, SignatureDocumentButton } from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

import { SPV_DOCUMENT_TYPE_TO_SIGN, cleanDraftDocuments } from '../../../utils';

type Props = {
  onDocumentClick: (type: CompanyDocumentType) => void;
};

let spvTimeoutId: NodeJS.Timeout | null = null;

/**
 * SPVMatriculationDocuments.
 * Document section with signature status.
 *
 * @returns {JSX.Element}
 */
export function SPVMatriculationDocuments({ onDocumentClick }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const { companyId = '' } = useParams();

  const { data, loading, error, refetch } = useGetSpvQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const companyDocumentsReq = useGetCompanyDocumentsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const companyDocuments = companyDocumentsReq?.data?.getCompanyDocuments;

  const { getBadge } = useBadge(null, [{ value: true, label: t('Validated'), color: 'green' }], {
    label: t('RequiredAction', { nb: 1 }),
    color: 'gray',
  });

  const company = data?.company;
  const president = company?.companyRepresentative?.find(
    (rep) => rep.role === RepresentativeRole.PRESIDENT,
  );
  const isIndividual = president?.representativeIdentity.type === IdentityType.INDIVIDUAL;

  const allDocumentsSigned =
    companyDocuments &&
    SPV_DOCUMENT_TYPE_TO_SIGN.every(
      (docType) =>
        companyDocuments?.some(({ document }) => {
          if (!isIndividual && docType === CompanyDocumentType.DNC) return true;
          return document.type === docType && document.status === DocumentStatus.SIGNED;
        }),
    );

  useEffect(() => {
    const activeStep =
      company?.spvStatus &&
      [SpvStatus.PAID, SpvStatus.VALIDATED].includes(company.spvStatus) &&
      !allDocumentsSigned
        ? true
        : false;

    setIsAccordionOpen(activeStep);
  }, [allDocumentsSigned, company, companyDocuments]);

  useEffect(() => {
    if (!companyDocuments?.length) return;

    const isAnyDocumentPending = Object.values(cleanDraftDocuments(companyDocuments)).some((doc) =>
      [DocumentStatus.SIGNATURES_PENDING, DocumentStatus.ONGOING].includes(doc.document.status),
    );

    if (!isPollingStarted && isAnyDocumentPending) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      companyDocumentsReq.startPolling(2000);

      if (spvTimeoutId) clearTimeout(spvTimeoutId);
      spvTimeoutId = setTimeout(() => {
        companyDocumentsReq.stopPolling();
        setIsPollingLoading(false);
      }, 20_000);
    }

    if (isPollingStarted && !isAnyDocumentPending) {
      companyDocumentsReq.stopPolling();
      setIsPollingLoading(false);
      refetch();
    }
  }, [isPollingStarted, companyDocumentsReq, companyDocuments, refetch]);

  useEffect(() => {
    return () => {
      if (spvTimeoutId) {
        clearTimeout(spvTimeoutId);
      }
    };
  }, []);

  if (loading) {
    return <Skeleton h="65px" />;
  }

  if (error) {
    return <ErrorQueryCard h="65px" />;
  }

  if (!company) {
    return <ErrorQueryCard h="65px" />;
  }

  /* ** Track draft status; disabled accordion when draft status is editing ** */
  const isEditing = company.spvStatus === SpvStatus.EDITING;

  const spvDocuments: Record<string, CompanyDocumentInfosFragment> = (companyDocuments || [])
    .filter((doc) => SPV_DOCUMENT_TYPE_TO_SIGN.includes(doc.type))
    .reduce((acc, curr) => ({ ...acc, [curr.type]: curr }), {});

  const badge = getBadge(allDocumentsSigned);

  const handleDocumentClick = (type: CompanyDocumentType): void => {
    onDocumentClick(type);
  };

  const handleAccordionOpen = (): void => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <Accordion
      key={`documents${company.id}`}
      allowMultiple
      index={isAccordionOpen ? [0] : [-1]}
      onChange={handleAccordionOpen}
    >
      <AccordionItem isDisabled={isEditing}>
        <AccordionButton>
          <AccordionIcon boxSize="8" />
          <Box flex="1" p="2" textAlign="left">
            <Text fontSize="xl" fontWeight="semibold">
              {t('SignatureOfDocuments')}
            </Text>
          </Box>
          <Badge colorScheme={badge.color}>{badge.label}</Badge>
        </AccordionButton>
        <AccordionPanel>
          <Stack spacing="4">
            <Alert status="info">
              <AlertIcon />
              <AlertTitle>
                {t.rich('ReviewDocumentsBeforeSigning', {
                  bold: (chunks) => <b>{chunks}</b>,
                  underline: (chunks) => <u>{chunks}</u>,
                })}
              </AlertTitle>
            </Alert>
            <Stack spacing="2">
              <SignatureDocumentButton
                isBadgeLoading={isPollingLoading}
                status={spvDocuments[CompanyDocumentType.STATUTS]?.document?.status}
                title={i18nDocumentTitle('STATUTS')}
                onClick={(): void => handleDocumentClick(CompanyDocumentType.STATUTS)}
              />
              <SignatureDocumentButton
                isBadgeLoading={isPollingLoading}
                status={spvDocuments[CompanyDocumentType.DOMICILIATION]?.document?.status}
                title={i18nDocumentTitle('DOMICILIATION')}
                onClick={(): void => handleDocumentClick(CompanyDocumentType.DOMICILIATION)}
              />
              {isIndividual && (
                <SignatureDocumentButton
                  isBadgeLoading={isPollingLoading}
                  status={spvDocuments[CompanyDocumentType.DNC]?.document?.status}
                  title={i18nDocumentTitle('DNC')}
                  onClick={(): void => handleDocumentClick(CompanyDocumentType.DNC)}
                />
              )}
              <SignatureDocumentButton
                isBadgeLoading={isPollingLoading}
                status={spvDocuments[CompanyDocumentType.SOUSCRIPTEURS]?.document?.status}
                title={i18nDocumentTitle('SOUSCRIPTEURS')}
                onClick={(): void => handleDocumentClick(CompanyDocumentType.SOUSCRIPTEURS)}
              />
              <SignatureDocumentButton
                isBadgeLoading={isPollingLoading}
                status={spvDocuments[CompanyDocumentType.DBE]?.document?.status}
                title={i18nDocumentTitle('DBE')}
                onClick={(): void => handleDocumentClick(CompanyDocumentType.DBE)}
              />
              <SignatureDocumentButton
                isBadgeLoading={isPollingLoading}
                status={spvDocuments[CompanyDocumentType.POUVOIR_FORMALITES]?.document?.status}
                title={i18nDocumentTitle('POUVOIR_FORMALITES')}
                onClick={(): void => handleDocumentClick(CompanyDocumentType.POUVOIR_FORMALITES)}
              />
              <SignatureDocumentButton
                isBadgeLoading={isPollingLoading}
                status={spvDocuments[CompanyDocumentType.FORMULAIRE_M0]?.document?.status}
                title={i18nDocumentTitle('FORMULAIRE_M0')}
                onClick={(): void => handleDocumentClick(CompanyDocumentType.FORMULAIRE_M0)}
              />
            </Stack>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export type SPVMatriculationDocumentsProps = Props;
