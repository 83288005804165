import { Card, CardBody, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import {
  HoldingMethod,
  useCreateWalletMutation,
  useGetWalletsQuery,
  useUpdateWalletMutation,
} from '@blockpulse3/graphql/hooks';
import {
  HoldingMethodSelect,
  IWalletForm,
  WalletFormModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { useManagedIndividual } from '../../../providers';

export function WalletFormView(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { identityId } = useManagedIndividual();

  const [walletPartial, setWalletPartial] = useState<Partial<IWalletForm> | undefined>();

  const { refetch } = useGetWalletsQuery({
    variables: {
      identityId,
    },
    skip: !identityId,
  });
  const [createWallet] = useCreateWalletMutation();
  const [updateWallet] = useUpdateWalletMutation();

  const createFormModal = useDisclosure();
  const updateFormModal = useDisclosure();

  const handleWalletCreate = (data: IWalletForm): void => {
    if (!identityId) return;

    createWallet({
      variables: {
        createWalletInput: {
          identityId,
          bankAccountNumber: data.bankAccountNumber,
          holdingMethod: data.holdingMethod,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: () => {
        createFormModal.onClose();
        successToast({ title: t('HoldingModeAdded') });
        refetch();
      },
      onError: () => {
        createFormModal.onClose();
        errorToast({ title: t('ErrorAddingOwnershipMode') });
      },
    });
  };

  const handleWalletUpdate = async (data: IWalletForm): Promise<void> => {
    if (!identityId || !data.id) return;

    updateWallet({
      variables: {
        updateWalletInput: {
          walletId: data.id,
          identityId,
          bankAccountNumber: data.bankAccountNumber,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: () => {
        refetch();
        updateFormModal.onClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorRecordingOwnershipMode') });
      },
    });
  };

  const handleWalletCreateModalOpening = (holdingMethod: HoldingMethod): void => {
    setWalletPartial({ holdingMethod });
    createFormModal.onOpen();
  };

  const handleWalletUpdateModalOpening = (wallet: IWalletForm): void => {
    setWalletPartial(wallet);
    updateFormModal.onOpen();
  };

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }}>
      <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
        {t('MyHoldingModes')}
      </Text>
      <Card maxW={{ lg: '3xl' }} w="full">
        <CardBody>
          <Stack spacing="4">
            <HoldingMethodSelect
              identityId={identityId}
              isReading={true}
              onWalletCreate={handleWalletCreateModalOpening}
              onWalletUpdate={handleWalletUpdateModalOpening}
            />
          </Stack>
        </CardBody>
      </Card>
      {createFormModal.isOpen && (
        <WalletFormModal
          formPartialValues={walletPartial}
          isOpen={createFormModal.isOpen}
          onClose={createFormModal.onClose}
          onSubmit={handleWalletCreate}
        />
      )}
      {updateFormModal.isOpen && (
        <WalletFormModal
          formPartialValues={walletPartial}
          isOpen={updateFormModal.isOpen}
          onClose={updateFormModal.onClose}
          onSubmit={handleWalletUpdate}
        />
      )}
    </Stack>
  );
}
