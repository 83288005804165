import { Card, CardBody, CardHeader, Heading, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { TemplateIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { OperationStatus, useGetOperationsQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { ShareTransferRegister } from './ShareTransferRegister';

type Props = unknown;

/**
 * PartialShareTransferRegisters.
 * Display finalized Fundraisings RMT.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function PartialShareTransferRegisters(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetOperationsQuery({
    variables: { companyId, status: OperationStatus.FINALIZED },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  const operations = data?.operations || [];

  return (
    <Stack spacing="4">
      <Card key={companyId}>
        <CardHeader
          as={Stack}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading size="md">{t('ShareTransferRegister')}</Heading>
        </CardHeader>
        <CardBody>
          <Stack layerStyle="emptyState">
            <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
            <Stack alignItems="center" spacing="0">
              <Text>{t('PartiallyImportedCompany')}</Text>
              <Text>{t('InsufficientInfoForMovementRegister')}</Text>
            </Stack>
          </Stack>
        </CardBody>
      </Card>
      {operations.map((operation) => (
        <ShareTransferRegister key={operation.id} operation={operation} />
      ))}
    </Stack>
  );
}

export type PartialShareTransferRegistersProps = Props;
