import * as adeoJson from './adeo.json';
import * as auchanJson from './auchan.json';
import * as axerealJson from './axereal.json';
import * as blastJson from './blast.json';
import * as blockpulseJson from './blockpulse.json';
import * as cenitzJson from './cenitz.json';
import * as cineventureJson from './cineventure.json';
import * as decathlonJson from './decathlon.json';
import * as franceangelsJson from './franceangels.json';
import * as housebaseJson from './housebase.json';
import * as howhenJson from './howhen.json';
import * as investJson from './invest.json';
import * as lesaffreJson from './lesaffre.json';
import * as lineupJson from './lineup.json';
import * as mobilisJson from './mobilis.json';
import * as mobiviaJson from './mobivia.json';
import * as pbaJson from './pba.json';
import * as projexJson from './projex.json';
import * as stomeaJson from './stomea.json';
import * as tyliaJson from './tylia.json';
import * as vivaltoJson from './vivalto.json';
import * as yeastJson from './yeast.json';

export const adeo = adeoJson;
export const auchan = auchanJson;
export const axereal = axerealJson;
export const blast = blastJson;
export const blockpulse = blockpulseJson;
export const cenitz = cenitzJson;
export const cineventure = cineventureJson;
export const decathlon = decathlonJson;
export const franceangels = franceangelsJson;
export const housebase = housebaseJson;
export const howhen = howhenJson;
export const invest = investJson;
export const lesaffre = lesaffreJson;
export const lineup = lineupJson;
export const mobilis = mobilisJson;
export const mobivia = mobiviaJson;
export const pba = pbaJson;
export const projex = projexJson;
export const stomea = stomeaJson;
export const tylia = tyliaJson;
export const vivalto = vivaltoJson;
export const yeast = yeastJson;
