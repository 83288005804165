import { AddIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { CompanyDocumentType, IdentityVerificationStatus, noop } from '@blockpulse3/data/shared';
import {
  IdentityType,
  IndividualDocumentType,
  useDeleteCompanyDocumentMutation,
  useDeleteKycDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetIdentityDocumentsQuery,
  useGetIdentityQuery,
  useHandleIdentityApprovalMutation,
} from '@blockpulse3/graphql/hooks';
import { getDocumentIds } from '@blockpulse3/helpers';

import {
  AddIdentityDocuments,
  useGetKybDocuments,
  useGetKycDocuments,
} from '../AddIdentityDocuments';
import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { DocumentLink } from '../DocumentLink';
import { ResponsiveModal, ResponsiveModalProps } from '../ResponsiveModal';
import { ResponsiveModalFooter } from '../ResponsiveModalFooter';
import { WarningCard } from '../WarningCard';
import { UltimateBeneficialOwnerCard } from './UltimateBeneficialOwnerCard';

type Props = {
  identityId: string;
  isUserAuthorized: boolean;
  onCompleted?: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function IdentityValidationModal({
  identityId,
  isUserAuthorized,
  onClose,
  onCompleted = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const { companyId } = useParams();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isAddingDocuments, setIsAddingDocuments] = useState<boolean>(false);

  const confirmRef = useRef(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    CompanyDocumentType | IndividualDocumentType | undefined
  >(undefined);
  const [isIdentityApproved, setIsIdentityApproved] = useState<boolean>(false);
  const [isIdentityRefused, setIsIdentityRefused] = useState<boolean>(false);

  const { isOpen, onOpen, onClose: onCloseConfirmationModal } = useDisclosure();

  const {
    data: identityData,
    refetch: refetchIdentity,
    loading: identityLoading,
  } = useGetIdentityQuery({
    variables: {
      identityId,
    },
    skip: !identityId,
  });

  const {
    data: identityDocumentsData,
    refetch: refetchDocuments,
    loading: documentLoading,
  } = useGetIdentityDocumentsQuery({
    variables: {
      identityId,
    },
    fetchPolicy: 'no-cache',
  });

  const refetch = (): void => {
    refetchDocuments();
    refetchIdentity();
  };

  const isLoading = identityLoading || documentLoading;

  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [handleIdentityApproval, { loading: isIdentityApprovalLoading }] =
    useHandleIdentityApprovalMutation();
  const [deleteCompanyDocument, { loading: isDocumentDeletionLoading }] =
    useDeleteCompanyDocumentMutation();
  const [deleteDocument, { loading: isKycDeletionLoading }] = useDeleteKycDocumentMutation();
  const [getKybDocuments] = useGetKybDocuments();
  const [getKycDocuments] = useGetKycDocuments();

  const identity = identityData?.identity;
  const isCompany = identity?.type === IdentityType.COMPANY;
  const kycbVerificationStatus = isCompany
    ? identity?.companyIdentity?.kybVerificationStatus
    : identity?.individualIdentity?.kycVerificationStatus;

  const displayButton = [
    IdentityVerificationStatus.PENDING,
    IdentityVerificationStatus.REFUSED,
  ].includes(kycbVerificationStatus as IdentityVerificationStatus);
  const displayDeleteButton = [
    IdentityVerificationStatus.NONE,
    IdentityVerificationStatus.PENDING,
    IdentityVerificationStatus.REFUSED,
  ].includes(kycbVerificationStatus as IdentityVerificationStatus);

  const companyName = identity?.companyIdentity?.name || '';
  const individualIdentity = identity?.individualIdentity;
  const signerIndividualIdentity = identity?.companyIdentity?.signer?.individualIdentity;
  const individualIdentityId = individualIdentity?.id || signerIndividualIdentity?.id || '';

  const documents = identityDocumentsData?.getIdentityDocuments || [];
  const documentIds = getDocumentIds(documents);

  const ubos = identity?.companyIdentity?.ubos || [];

  const hasAllDocuments = isCompany
    ? Object.keys(documentIds).length >= getKybDocuments().length
    : Object.keys(documentIds).length >= getKycDocuments().length;

  useEffect(() => {
    if (!hasAllDocuments) {
      return;
    }

    setIsAddingDocuments(false);
  }, [hasAllDocuments]);

  const onDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const handleIdentityApproved = (): void => {
    setIsIdentityApproved(true);
    handleApproval(IdentityVerificationStatus.APPROVED);
  };
  const handleIdentityRefused = (): void => {
    setIsIdentityRefused(true);
    handleApproval(IdentityVerificationStatus.REFUSED);
  };
  const handleApproval = (verificationStatus: IdentityVerificationStatus): void => {
    if (!identity) return;

    const customMessage =
      verificationStatus === IdentityVerificationStatus.REFUSED
        ? inputRef.current?.value
        : undefined;

    handleIdentityApproval({
      variables: {
        handleIdentityApprovalInput: {
          identityId: identity.id,
          companyId,
          verificationStatus,
          customMessage,
        },
      },
      onCompleted: () => {
        refetchIdentity();
        onCompleted();
        onCloseModal();
      },
    });
  };

  const handleFileDelete = (documentType: CompanyDocumentType | IndividualDocumentType): void => {
    // For now only handle company documents
    setSelectedDocumentType(documentType);
    onOpen();
  };

  const handleDelete = (): void => {
    if (!selectedDocumentType) return;

    const companyId = identity?.companyIdentity?.id;

    if (selectedDocumentType in CompanyDocumentType && companyId) {
      deleteCompanyDocument({
        variables: {
          deleteCompanyDocumentInput: {
            companyId,
            documentType: selectedDocumentType,
          },
        },
        onCompleted: () => {
          refetch();
        },
      });
    } else if (individualIdentityId) {
      deleteDocument({
        variables: {
          deleteKycDocumentInput: {
            individualIdentityId,
            documentType: selectedDocumentType,
          },
        },
        onCompleted: () => {
          refetch();
        },
      });
    }

    onCloseConfirmationModal();
  };

  const onCloseModal = (): void => {
    setIsAddingDocuments(false);
    onClose();
  };

  return (
    <ResponsiveModal onClose={onCloseModal} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('IdentityVerification')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          {isLoading ? (
            <Stack h="100px" layerStyle="emptyState">
              <Spinner flexShrink="0" />
            </Stack>
          ) : (
            <>
              <HStack justifyContent="space-between">
                <Text color="gray.600" fontWeight="600">
                  {t('Document', { nb: documents.length })}
                </Text>
                {isUserAuthorized && !hasAllDocuments && (
                  <Button
                    leftIcon={<AddIcon />}
                    size="sm"
                    variant="secondary"
                    w="fit-content"
                    onClick={(): void => setIsAddingDocuments((crr) => !crr)}
                  >
                    {t('AddDocument')}
                  </Button>
                )}
              </HStack>
              {isUserAuthorized && isAddingDocuments && identity?.id && (
                <AddIdentityDocuments identityId={identity?.id} onUploadCompleted={refetch} />
              )}
              {!Object.keys(documentIds).length && !isAddingDocuments && (
                <Stack layerStyle="emptyState">
                  <Text>{t('NoIdentityDocumentDownloaded')}</Text>
                </Stack>
              )}
            </>
          )}
          <Stack spacing="2">
            {Object.keys(documentIds).map((documentType, index) => (
              <HStack key={index} justify="space-between">
                <DocumentLink
                  fileName={i18nDocumentTitle(
                    `${documentType}wName` as IntlDocumentValues,
                    documentType in CompanyDocumentType
                      ? { companyName }
                      : {
                          name:
                            individualIdentity?.name ||
                            signerIndividualIdentity?.name ||
                            '[représentant légal]',
                        },
                  )}
                  onClick={(): void => onDocumentClick(documentIds[documentType])}
                />
                {displayDeleteButton && isUserAuthorized && (
                  <IconButton
                    aria-label="delete-document"
                    icon={<Icon as={TrashIcon} boxSize="4" />}
                    isDisabled={isDocumentDeletionLoading || isLoading || isKycDeletionLoading}
                    size="xs"
                    variant="icon-delete"
                    onClick={(): void =>
                      handleFileDelete(documentType as CompanyDocumentType | IndividualDocumentType)
                    }
                  />
                )}
              </HStack>
            ))}
          </Stack>
          {ubos.length && (
            <>
              <Divider />
              <Text color="gray.600" fontWeight="600">
                {t('EffectiveRecipient', { nb: 3 })}
              </Text>
              {ubos.map((ubo, index) => {
                return <UltimateBeneficialOwnerCard key={ubo.id} index={index} ubo={ubo} />;
              })}
            </>
          )}
          {displayButton && !isUserAuthorized && (
            <WarningCard title={t('ComplianceManagersAuthorized')} />
          )}
        </ModalBody>
        {displayButton && (
          <>
            <Divider />
            <ResponsiveModalFooter>
              <Stack gap="4" w="full">
                <Accordion allowToggle variant="unstyled">
                  <AccordionItem>
                    <AccordionButton data-cy="expand-parameters">
                      <Text color="gray" fontSize="sm" textAlign="left">
                        {t('CustomInvalidIdentityMessageOptional')}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p="0">
                      <Textarea minH="200" ref={inputRef} size="sm" />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <HStack alignSelf="end">
                  <Button
                    isDisabled={!isUserAuthorized || isIdentityApprovalLoading || isLoading}
                    isLoading={(isIdentityApprovalLoading && isIdentityRefused) || isLoading}
                    variant="secondary"
                    onClick={handleIdentityRefused}
                  >
                    {t('InvalidateIdentityAction')}
                  </Button>
                  <Button
                    isDisabled={!isUserAuthorized || isIdentityApprovalLoading || isLoading}
                    isLoading={(isIdentityApprovalLoading && isIdentityApproved) || isLoading}
                    onClick={handleIdentityApproved}
                  >
                    {t('ApproveTheIdentity')}
                  </Button>
                </HStack>
              </Stack>
            </ResponsiveModalFooter>
          </>
        )}
        <DeleteConfirmModal
          isOpen={isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('DefinitiveAction')}
          title={t('DeleteDocumentQuestion')}
          onClose={onCloseConfirmationModal}
          onDelete={handleDelete}
        />
      </ModalContent>
    </ResponsiveModal>
  );
}
