import { Heading, Stack } from '@chakra-ui/react';
import {
  BeakerIcon,
  CashIcon,
  CurrencyEuroIcon,
  HomeIcon,
  MapIcon,
  OfficeBuildingIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { useLocation } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { SpaceType } from '@blockpulse3/graphql/hooks';
import { NavGroupItem } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SettingsNavSpace.
 * Render navitation items on space sub routes.
 *
 * @returns {JSX.Element}
 */
export function SettingsNavSpace(): JSX.Element {
  const t = useTranslations();

  const { user: authUser } = useAuthUser();
  const { pathname } = useLocation();

  const homeRoute = routes.space.href;

  const individualsRoute = routes.space.individuals.full;
  const companiesRoute = routes.space.companies.full;
  const registrationsRoute = routes.space.registrations.full;
  const spacesRoute = routes.space.spaces.full;
  const controlsRoute = routes.space.controls.full;
  const managementsRoute = routes.space.managements.full;

  const fundraisingsRoute = routes.space.operations.full;
  const bankTransferRoute = routes.space.bankTransfers.full;

  const graphqlRoute = routes.space.graphql.full;

  const membersRoute = routes.space.members.full;

  const spaceType = authUser?.members?.[0].space?.type;

  return (
    <Stack spacing="1">
      <NavGroupItem
        active={pathname === homeRoute}
        href={homeRoute}
        icon={<HomeIcon height="18" width="18" />}
        label={t('Dashboard')}
      />
      <Heading as="h4" color="whiteAlpha.700" fontSize="xs" fontWeight="bold" pb="1" pl="3" pt="4">
        {t('Identity', { nb: 2 })}
      </Heading>
      {spaceType === SpaceType.GLOBAL ? (
        <>
          {' '}
          <NavGroupItem
            active={pathname === individualsRoute}
            href={individualsRoute}
            icon={<UsersIcon height="18" width="18" />}
            label={t('Individual', { nb: 2 })}
          />
          <NavGroupItem
            active={pathname === companiesRoute}
            href={companiesRoute}
            icon={<OfficeBuildingIcon height="18" width="18" />}
            label={t('Company', { nb: 2 })}
          />
          <NavGroupItem
            active={pathname === registrationsRoute}
            href={registrationsRoute}
            icon={<BeakerIcon height="18" width="18" />}
            label={t('Registration', { nb: 2 })}
          />
          <NavGroupItem
            active={pathname === spacesRoute}
            href={spacesRoute}
            icon={<UserGroupIcon height="18" width="18" />}
            label={t('Space', { nb: 2 })}
          />
        </>
      ) : (
        <>
          <NavGroupItem
            active={pathname === controlsRoute}
            href={controlsRoute}
            icon={<OfficeBuildingIcon height="18" width="18" />}
            label={t('ControlledCompanies')}
          />
          <NavGroupItem
            active={pathname === managementsRoute}
            href={managementsRoute}
            icon={<ShieldCheckIcon height="18" width="18" />}
            label={t('ManagementIdentities')}
          />
        </>
      )}
      {spaceType === SpaceType.GLOBAL && (
        <>
          <Heading
            as="h4"
            color="whiteAlpha.700"
            fontSize="xs"
            fontWeight="bold"
            pb="1"
            pl="3"
            pt="4"
          >
            {t('Operation', { nb: 2 })}
          </Heading>
          <NavGroupItem
            active={pathname === fundraisingsRoute}
            href={fundraisingsRoute}
            icon={<CashIcon height="18" width="18" />}
            label={t('Operation', { nb: 2 })}
          />
          <NavGroupItem
            active={pathname === bankTransferRoute}
            href={bankTransferRoute}
            icon={<CurrencyEuroIcon height="18" width="18" />}
            label={t('BankTransfer', { nb: 2 })}
          />
          <Heading
            as="h4"
            color="whiteAlpha.700"
            fontSize="xs"
            fontWeight="bold"
            pb="1"
            pl="3"
            pt="4"
          >
            {t('Developer', { nb: 1 })}
          </Heading>
          <NavGroupItem
            active={pathname === graphqlRoute}
            href={graphqlRoute}
            icon={<MapIcon height="18" width="18" />}
            label={t('ExploreGraphQL')}
          />
        </>
      )}
      <Heading as="h4" color="whiteAlpha.700" fontSize="xs" fontWeight="bold" pb="1" pl="3" pt="4">
        {t('Space', { nb: 1 })}
      </Heading>
      <NavGroupItem
        active={pathname === membersRoute}
        href={membersRoute}
        icon={<UserGroupIcon height="18" width="18" />}
        label={t('Member', { nb: 2 })}
      />
    </Stack>
  );
}

export type SettingsNavSpaceProps = Props;
