import { GetSubscriptionQuery, WalletInfosFragment } from '@blockpulse3/graphql/hooks';
import { getSubscriptionShareCount } from '@blockpulse3/helpers';

import { IWalletForm, IWalletWithBalance } from './types';

export function getWalletValues(wallet: WalletInfosFragment): IWalletForm {
  return {
    id: wallet.id || '',
    holdingMethod: wallet.holdingMethod,
    bankAccountNumber: wallet.bankAccountNumber || '',
    bankCode: wallet.bankCode || '',
    bankName: wallet.bankName || '',
    bankEmail: wallet.bankEmail || '',
  };
}

export function mapWalletsByHoldingMethod(
  wallets: WalletInfosFragment[],
  subscriptionData?: GetSubscriptionQuery | undefined,
  assetId?: string,
): Record<string, IWalletWithBalance> {
  return wallets.reduce((acc, cur) => {
    let balance = undefined;
    if (assetId && subscriptionData) {
      const subscriptionShareCount = getSubscriptionShareCount(subscriptionData?.subscription);
      balance = cur.balances?.find((balance) => balance.asset.id === assetId);
      if (balance) {
        balance = { ...balance, locked: balance.locked - subscriptionShareCount };
      }
    }
    return { ...acc, [cur.holdingMethod]: { ...cur, balance } };
  }, {});
}
