import { Spinner, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useScheduleBankTransferMutation } from '@blockpulse3/graphql/hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

let isPaymentInitiated = false;

export function SchedulePaymentsView(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const navigate = useNavigate();

  const [scheduleBankTransfer] = useScheduleBankTransferMutation();

  useEffect(() => {
    if (identityId && !isPaymentInitiated) {
      isPaymentInitiated = true;
      scheduleBankTransfer({
        variables: {
          scheduleBankTransferInput: {
            identityId,
            subscriptionId,
          },
        },
        onCompleted: (data) => {
          setTimeout(() => {
            const initiatePaymentUrl = data.scheduleBankTransfer;
            window.location.href = initiatePaymentUrl;
          }, 2000);
        },
        onError: () => {
          navigate(routes.closePopup.href);
        },
      });
    }
  }, [identityId, subscriptionId, navigate, scheduleBankTransfer]);

  return (
    <Stack alignItems="center" h="full" justifyContent="center" p="8" spacing="4">
      <Text color="gray.600" fontSize="lg" fontWeight="600">
        {t('PreparingCreditTransfer', { nb: subscriptionId ? 1 : 2 })}
      </Text>
      <Spinner color="gray.600" />
    </Stack>
  );
}
