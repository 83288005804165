import { Box, Heading, Skeleton, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { Exceptions, locales } from '@blockpulse3/data/shared';
import {
  AddressInfosFragmentDoc,
  IndividualIdentityInfosFragmentDoc,
  useUpdateIndividualIdentityAddressMutation,
  useUpdateIndividualIdentityMutation,
  useUpdatePasswordMutation,
  useUpdateUserMutation,
} from '@blockpulse3/graphql/hooks';
import {
  LinkButton,
  NotAllowedCard,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { useAuthUser, useIntl, useManagedIndividual } from '../../providers';
import { IUpdateAddressForm, UpdateAddressForm } from './UpdateAddressForm';
import { IUpdateEmailForm, UpdateEmailForm } from './UpdateEmailForm';
import { IUpdateLanguageForm, UpdateLanguageForm } from './UpdateLanguageForm';
import { IUpdatePasswordForm, UpdatePasswordForm } from './UpdatePasswordForm';
import { UpdatePhoneForm } from './UpdatePhoneForm';
import { IUpdatePhoneForm } from './UpdatePhoneForm/types';
import { IUpdateProfileForm, UpdateProfileForm } from './UpdateProfileForm';
import { WalletFormView } from './Wallet';
import { fmtAddress } from './utils';

type Props = {
  backHref?: string;
};

/**
 * SettingsView.
 *
 * @returns {JSX.Element}
 */
export function UserSettingsView({ backHref }: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { locale, setLocale } = useIntl();

  const { user: authUser } = useAuthUser();
  const { individual, refetch } = useManagedIndividual();

  /* ** Setting mutations ** */
  const [updateUser] = useUpdateUserMutation();
  const [updatePassword] = useUpdatePasswordMutation();
  const [updateIndividualIdentity] = useUpdateIndividualIdentityMutation();
  const [updateIndividualIdentityAddress] = useUpdateIndividualIdentityAddressMutation();

  /* ** Build default values with util functions ** */
  const addressDefaults = individual ? fmtAddress(individual) : undefined;
  const emailDefaults = { email: individual?.email || '' };
  const phoneDefaults = { phone: individual?.phone || '' };
  const languageDefaults = { language: { label: locales[locale], value: locale } };

  if (!authUser) {
    return <NotAllowedCard h="400px" />;
  }

  if (!individual) {
    return <Skeleton h="400px" />;
  }

  const isUserIndividual = authUser.individualIdentity?.id === individual.id;

  const handleProfileSubmit = (profileData: IUpdateProfileForm): void => {
    updateIndividualIdentity({
      variables: {
        updateIndividualIdentityInput: {
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          birthdate: profileData.birthdate,
          birthplace: profileData.birthplace,
          birthCityPostalCode: profileData.birthCityPostalCode,
          birthCountry: profileData.birthCountry.value,
          nationality: profileData.nationality.value,
          individualIdentityId: individual.id,
          gender: profileData.gender,
        },
      },
      onCompleted: () => {
        refetch();
        successToast({ title: t('YourSettingsUpdated') });
      },
      onError: () => {
        errorToast({ title: t('SettingsUpdateError') });
      },
      update: (cache, { data }) => {
        /* ** Update IndividualIdentity cache entry for nested queries ** */
        if (individual && data?.updateIndividualIdentity) {
          const id = cache.identify(individual);
          cache.writeFragment({
            id,
            fragment: IndividualIdentityInfosFragmentDoc,
            fragmentName: 'IndividualIdentityInfos',
            data: data.updateIndividualIdentity,
          });
        }
      },
    });
  };

  const handleAddressSubmit = (addressData: IUpdateAddressForm): void => {
    if (individual?.address) {
      updateIndividualIdentityAddress({
        variables: {
          updateIndividualIdentityAddressInput: {
            individualIdentityId: individual.id,
            addressId: individual.address?.id || '',
            line: addressData.line,
            city: addressData.city,
            postalCode: addressData.postalCode,
            country: addressData.country.value,
          },
        },
        onCompleted() {
          refetch();
          successToast({ title: t('YourSettingsUpdated') });
        },
        onError() {
          errorToast({ title: t('SettingsUpdateError') });
        },
        update: (cache, { data }) => {
          /* ** Update Address cache entry for nested queries ** */
          if (individual?.address && data?.updateIndividualIdentityAddress) {
            const id = cache.identify(individual.address);
            cache.writeFragment({
              id,
              fragment: AddressInfosFragmentDoc,
              fragmentName: 'AddressInfos',
              data: data.updateIndividualIdentityAddress,
            });
          }
        },
      });
    }
  };

  const handlePasswordSubmit = (passwordData: IUpdatePasswordForm): void => {
    if (isUserIndividual) {
      updatePassword({
        variables: {
          updatePasswordInput: {
            userId: authUser.id,
            currentPassword: passwordData.currentPassword,
            newPassword: passwordData.newPassword,
          },
        },
        onCompleted: () => {
          successToast({ title: t('YourSettingsUpdated') });
        },
        onError: () => {
          errorToast({ title: t('SettingsUpdateError') });
        },
      });
    }
  };

  const handleEmailSubmit = (emailData: IUpdateEmailForm): void => {
    updateIndividualIdentity({
      variables: {
        updateIndividualIdentityInput: {
          individualIdentityId: individual.id,
          email: emailData.email,
        },
      },
      onCompleted: () => {
        successToast({ title: t('YourSettingsUpdated') });
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.AlreadyUsedEmail) {
          errorToast({ title: t('FieldErrorEmailAlreadyUsed') });
        } else {
          errorToast({ title: t('SettingsUpdateError') });
        }
      },
    });
  };

  const handlePhoneSubmit = (phoneData: IUpdatePhoneForm): void => {
    updateIndividualIdentity({
      variables: {
        updateIndividualIdentityInput: {
          individualIdentityId: individual.id,
          phone: phoneData.phone,
        },
      },
      onCompleted: () => {
        successToast({ title: t('YourSettingsUpdated') });
      },
      onError: () => {
        errorToast({ title: t('SettingsUpdateError') });
      },
    });
  };

  const handleLanguageSubmit = (data: IUpdateLanguageForm): void => {
    updateUser({
      variables: {
        updateUserInput: { userId: authUser.id, preferredLocale: data.language.value },
      },
      onCompleted: () => {
        setLocale(data.language.value);
        successToast({ title: t('YourSettingsUpdated') });
      },
      onError: () => {
        errorToast({ title: t('SettingsUpdateError') });
      },
    });
  };

  return (
    <Stack spacing="6">
      {backHref && <LinkButton label={t('Back')} route={backHref} />}
      <Heading color="gray.900">{t('MySettings')}</Heading>
      <UpdateProfileForm onSubmit={handleProfileSubmit} />
      <WalletFormView />
      <UpdateAddressForm defaultValues={addressDefaults} onSubmit={handleAddressSubmit} />
      {isUserIndividual && <UpdatePasswordForm onSubmit={handlePasswordSubmit} />}
      <UpdateEmailForm defaultValues={emailDefaults} onSubmit={handleEmailSubmit} />
      <UpdatePhoneForm defaultValues={phoneDefaults} onSubmit={handlePhoneSubmit} />
      {isUserIndividual && (
        <UpdateLanguageForm defaultValues={languageDefaults} onSubmit={handleLanguageSubmit} />
      )}
      <Box />
    </Stack>
  );
}

export type UserSettingsViewProps = Props;
