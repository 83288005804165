import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon, CashIcon, PlusIcon } from '@heroicons/react/outline';
import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, noop, routes } from '@blockpulse3/data/shared';
import {
  useGetBondAssetsQuery,
  useGetCompanyQuery,
  useGetOptionAssetsQuery,
  useGetStockAssetsQuery,
} from '@blockpulse3/graphql/hooks';
import { AssetBadge, ErrorQueryCard, TableContainer } from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

type Props = {
  showOptions?: boolean;
};

export function AssetAndOptionList({ showOptions = true }: Props): JSX.Element | null {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = '' } = useParams();
  const isFeatureOptionPool = isFeatureEnabled('optionPool');
  const isRepatriationAuthorized = useManagerRole({ companyId });

  const { data: companyData } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const hasCaptable =
    !!companyData?.company?.capTable?.stockTotalSupply &&
    !!companyData?.company?.capTable?.fullyDilutedTotalSupply;

  const {
    data: stockData,
    loading: stockLoading,
    error: stockError,
  } = useGetStockAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  const {
    data: optionData,
    loading: optionLoading,
    error: optionError,
  } = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !showOptions || !companyId || !isFeatureOptionPool || !hasCaptable,
  });

  const {
    data: bondData,
    loading: bondLoading,
    error: bondError,
  } = useGetBondAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  if (
    !isFeatureOptionPool ||
    (!stockData?.getStockAssets?.length &&
      showOptions &&
      !optionData?.getOptionAssets?.length &&
      !isRepatriationAuthorized)
  ) {
    return null;
  }

  if (stockError || (showOptions && optionError) || bondError) {
    return <ErrorQueryCard h="200px" />;
  }

  if (
    stockLoading ||
    (showOptions && optionLoading) ||
    bondLoading ||
    (hasCaptable && !stockData)
  ) {
    return <Skeleton h="200px" />;
  }

  const currentStocks = stockData?.getStockAssets || [];
  const currentOptions = optionData?.getOptionAssets || [];
  const currentBonds = bondData?.getBondAssets || [];
  const isEmpty =
    !hasCaptable && currentStocks.length === 0 && (!showOptions || currentOptions.length === 0);
  const isInCaptable = matchPath(
    routes.company.href + '/' + routes.company.captable.href + '/*',
    location.pathname,
  );

  const handleManageClick = (): void => {
    if (isInCaptable) {
      navigate('..', { relative: 'path' });
    } else {
      navigate(routes.company.captable.href);
    }
  };

  const handleRepatriationClick = (): void => {
    navigate(routes.company.repatriation.href);
  };

  const handleOptionClick = (assetId: string): void => {
    if (companyId) {
      navigate(generatePath(routes.company.option.href, { assetId }));
    }
  };

  const handleBondClick = (operationId: string): void => {
    if (companyId) {
      const pathPrefix = isInCaptable ? '../' : '';
      navigate(
        pathPrefix + generatePath(routes.company.fundraising.href, { companyId, operationId }),
      );
    }
  };

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        {showOptions && !isEmpty && (
          <Button size="sm" variant="secondary" onClick={handleManageClick}>
            {t('ManageShareholding')}
          </Button>
        )}
      </CardHeader>
      <CardBody>
        {isEmpty ? (
          <Stack h="150px" layerStyle="emptyState">
            <Icon as={CashIcon} boxSize="40px" color="gray.500" />
            <Text>{t('NoAsset')}</Text>
            {showOptions && (
              <Stack>
                <Button
                  leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                  size="sm"
                  variant="secondary"
                  w="fit-content"
                  onClick={handleRepatriationClick}
                >
                  {t('RepatriateShareholding')}
                </Button>
              </Stack>
            )}
          </Stack>
        ) : (
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>{t('Name')}</Th>
                  <Th>{t('Quantity')}</Th>
                  <Th w="200px" />
                </Tr>
              </Thead>
              <Tbody>
                {currentStocks.map((row) => (
                  <Tr key={row.id} role="button" onClick={handleManageClick}>
                    <Td>
                      <Text fontWeight="600">{row.name}</Text>
                      <Text color="gray.500" fontSize="xs" fontWeight="400">
                        {i18nAssetType(row.assetType, { nb: 1 })}
                      </Text>
                    </Td>
                    <Td fontWeight="600">
                      <AssetBadge type={row.assetType} value={row.supply} />
                    </Td>
                    <Td textAlign="right">
                      <Button
                        rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                        size="sm"
                        variant="secondary"
                      >
                        {t('ShowDetails')}
                      </Button>
                    </Td>
                  </Tr>
                ))}
                {showOptions &&
                  [...currentOptions]
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map(
                      (row) =>
                        row.token?.__typename === 'OptionTokenModel' && (
                          <Tr
                            key={row.id}
                            role="button"
                            onClick={(): void => handleOptionClick(row.id)}
                          >
                            <Td>
                              <Text fontWeight="600">{row.name}</Text>
                              <Text color="gray.500" fontSize="xs" fontWeight="400">
                                {i18nAssetType(row.assetType, { nb: 1 })}
                              </Text>
                            </Td>
                            <Td fontWeight="600">
                              <AssetBadge
                                assetCount={row.token?.grants?.length}
                                type={row.assetType}
                                value={row.supply}
                              />
                            </Td>
                            <Td textAlign="right">
                              <Button
                                rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                                size="sm"
                                variant="secondary"
                              >
                                {t('ShowDetails')}
                              </Button>
                            </Td>
                          </Tr>
                        ),
                    )}
                {[...currentBonds]
                  .sort((a, b) => (a.name < b.name ? -1 : 1))
                  .map((row) => (
                    <Tr
                      key={row.id}
                      role={row.operation?.id && 'button'}
                      onClick={(): void =>
                        row.operation?.id ? handleBondClick(row.operation.id) : noop()
                      }
                    >
                      <Td>
                        <Text fontWeight="600">{row.name}</Text>
                        <Text color="gray.500" fontSize="xs" fontWeight="400">
                          {i18nAssetType(row.assetType, { nb: 1 })}
                        </Text>
                      </Td>
                      <Td fontWeight="600">
                        <AssetBadge type={row.assetType} value={row.supply} />
                      </Td>
                      <Td textAlign="right">
                        {row.operation?.id && (
                          <Button
                            rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                            size="sm"
                            variant="secondary"
                          >
                            {t('ShowDetails')}
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </CardBody>
    </Card>
  );
}

export type AssetAndOptionListProps = Props;
