import {
  Button,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { HoldingMethod } from '@blockpulse3/data/shared';
import {
  BalanceInfosFragment,
  ContractType,
  WalletInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { AssetBadge, CompanyIdentityAvatar, TableContainer } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { BondAssetSidePanel } from './BondAssetSidePanel';
import { OptionAssetSidePanel } from './OptionAssetSidePanel';
import { StockAssetSidePanel } from './StockAssetSidePanel';
import { WalletEmptyTable } from './WalletEmptyTable';

type Props = {
  balances: WalletInfosFragment['balances'];
};

/**
 * Wallet Table.
 *
 * @returns {JSX.Element}
 */
export function WalletTable({ balances }: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  /* ** Side panel infos ** */
  const [sidePanelBalance, setSidePanelBalance] = useState<BalanceInfosFragment | null>(null);

  /* ** Side panel disclosures ** */
  const stockAssetSidePanel = useDisclosure();
  const optionAssetSidePanel = useDisclosure();
  const bondAssetSidePanel = useDisclosure();

  const { identityId } = useIdentity();

  const handleRowClick = (row: BalanceInfosFragment): void => {
    if (!identityId) {
      return;
    }
    setSidePanelBalance(row);

    switch (row.asset.contractType) {
      /* ** Option Token ** */
      case ContractType.OPTION_TOKEN: {
        optionAssetSidePanel.onOpen();
        break;
      }
      case ContractType.BOND_TOKEN: {
        bondAssetSidePanel.onOpen();
        break;
      }
      /* ** Stock Token (or old version Basic Token) ** */
      default: {
        stockAssetSidePanel.onOpen();
        break;
      }
    }
  };

  if (!balances?.length) {
    return <WalletEmptyTable />;
  }

  // Prevent displaying account type if balances are not migrated yet
  const hasAlternativeBalances = balances.some((balance) =>
    [HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(balance.wallet.holdingMethod),
  );

  return (
    <Stack>
      {sidePanelBalance && stockAssetSidePanel.isOpen && (
        <StockAssetSidePanel
          balance={sidePanelBalance}
          identityId={identityId}
          isOpen={stockAssetSidePanel.isOpen}
          onClose={stockAssetSidePanel.onClose}
        />
      )}
      {sidePanelBalance && optionAssetSidePanel.isOpen && (
        <OptionAssetSidePanel
          balance={sidePanelBalance}
          identityId={identityId}
          isOpen={optionAssetSidePanel.isOpen}
          onClose={optionAssetSidePanel.onClose}
        />
      )}
      {sidePanelBalance && bondAssetSidePanel.isOpen && (
        <BondAssetSidePanel
          balance={sidePanelBalance}
          identityId={identityId}
          isOpen={bondAssetSidePanel.isOpen}
          onClose={bondAssetSidePanel.onClose}
        />
      )}
      <TableContainer maxH="290px">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('CompanyName')}</Th>
              <Th>{t('HoldingAssets')}</Th>
              <Th isNumeric>{t('ValueOfShares')}</Th>
              {hasAlternativeBalances && <Th>{t('Account')}</Th>}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {balances?.map((balance, index) => {
              return (
                <Tr key={index} role="button" onClick={(): void => handleRowClick(balance)}>
                  <Td fontWeight="600">
                    <HStack>
                      <CompanyIdentityAvatar src={balance.asset.company.profilePicture} />
                      <Text>{balance.asset.company.name}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <Stack spacing="2">
                      {balance.subscribedAmount > 0 && (
                        <AssetBadge
                          assetCount={balance.grantsCount}
                          type={balance.asset.assetType}
                          value={balance.total}
                        />
                      )}
                    </Stack>
                  </Td>
                  <Td isNumeric fontWeight="600">
                    <Text>
                      {balance.valueOfShares ? formatNumberCurrency(balance.valueOfShares) : '-'}
                    </Text>
                  </Td>
                  {hasAlternativeBalances && (
                    <Td fontWeight="600">
                      <Text>{i18nHoldingMethodType(balance.wallet.holdingMethod)}</Text>
                    </Td>
                  )}
                  <Td align="right" textAlign="right">
                    <Button size="sm" variant="secondary">
                      {t('ShowDetails')}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export type WalletTableProps = Props;
