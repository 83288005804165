import { Skeleton } from '@chakra-ui/react';
import { useMemo } from 'react';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  FundraisingType,
  FundraisingWorkflowType,
  useCreateFundraisingMutation,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';

import { crowdfundingFundraisingParametersDefaults } from '../../utils';
import { ICrowdfundingFundraisingParametersForm } from '../types';
import { NewCrowdfundingFundraisingInformations } from './NewCrowdfundingFundraisingInformations';

type Props = unknown;

/**
 * NewCrowdfundingFundraisingCreate.
 * First step of private fundraising funnel.
 *
 * @returns {JSX.Element}
 */
export function NewCrowdfundingFundraisingCreate(): JSX.Element {
  const navigate = useNavigate();

  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const isWorkspaceLoaded = !!company;

  const [createFundraising, { loading }] = useCreateFundraisingMutation();

  const defaultValues = useMemo(() => {
    return company && crowdfundingFundraisingParametersDefaults(company);
  }, [company]);

  const handleFormSubmit = (data: ICrowdfundingFundraisingParametersForm): void => {
    if (company) {
      const isAllInFundraising = data.workflowType === FundraisingWorkflowType.ALL_IN;
      const assetId = data.asset.value !== data.asset.type ? data.asset.value : undefined;
      const assetType = data.asset.type;

      const selectedFiscalAdvantages =
        assetType === AssetType.BOND
          ? []
          : data.allowedFiscalAdvantages.map((fiscalAdvantage) => fiscalAdvantage.value);

      createFundraising({
        variables: {
          createFundraisingInput: {
            name: data.name,
            companyId,
            sharePrice: data.sharePrice,
            subscriptionPeriod: data.subscriptionPeriod.value,
            assetId,
            assetType,
            createBondAsset: data.createBondAsset,
            allowedFiscalAdvantages: selectedFiscalAdvantages,
            type: FundraisingType.CROWDFUNDING,
            hardCap: data.hardCap,
            workflowType: data.workflowType,
            minimalAmount: data.minimalAmount,
            maximalAmount: data.maximalAmount,
            hasMifid: data.hasMifid,
            softCap: data.softCap,
            closingDate: isAllInFundraising ? data.closingDate : null,
          },
        },
        onCompleted: (data) => {
          if (company) {
            /* ** Remove /create from history when we go back ** */
            window.history.replaceState(
              null,
              '',
              generatePath(routes.company.newFundraising.crowdfunding.edit.full, {
                companyId,
                operationId: data.createFundraising.id,
              }),
            );
            navigate(
              resolvePath(
                routes.company.newFundraising.crowdfunding.edit.documentation.href,
                generatePath(routes.company.newFundraising.crowdfunding.edit.href, {
                  operationId: data.createFundraising.id,
                }),
              ).pathname,
            );
          }
        },
      });
    }
  };

  return (
    <Skeleton isLoaded={isWorkspaceLoaded} w="full">
      <NewCrowdfundingFundraisingInformations
        defaultValues={defaultValues}
        isLoading={loading}
        onSubmit={handleFormSubmit}
      />
    </Skeleton>
  );
}

export type NewCrowdfundingFundraisingCreateProps = Props;
