import * as yup from 'yup';

import {
  numberField,
  requiredBooleanField,
  requiredNumberField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { AssetType, TokenDurationUnit } from '@blockpulse3/graphql/hooks';

import { IBondAssetDraft } from './types';

export const durationUnitOptions = [
  { label: TokenDurationUnit.day, value: TokenDurationUnit.day },
  { label: TokenDurationUnit.month, value: TokenDurationUnit.month },
];

export const schema = yup.object({
  name: requiredStringField,
  assetType: yup.object({
    label: requiredStringField,
    value: requiredStringField,
  }),
  durationUnit: yup.object({
    label: requiredStringField,
    value: requiredStringField,
  }),
  period: requiredNumberField,
  nominalValue: requiredNumberField,
  issuancePremium: numberField,
  redemptionPremium: numberField,
  interestRate: requiredNumberField,
  interestPeriod: requiredNumberField,
  interestPaymentInterval: numberField,
  reimbursementInterval: numberField,
  accelerable: requiredBooleanField,
  extendable: requiredBooleanField,
  transferable: requiredBooleanField,
}) as yup.ObjectSchema<IBondAssetDraft>;

export const bondAssetDraftDefaultValues: IBondAssetDraft = {
  assetId: null,
  name: '',
  assetType: { label: AssetType.BOND, value: AssetType.BOND },
  durationUnit: durationUnitOptions[0],
  period: 0,
  nominalValue: 0,
  issuancePremium: 0,
  redemptionPremium: 0,
  interestRate: 0,
  interestPeriod: 0,
  interestPaymentInterval: 0,
  reimbursementInterval: 0,
  accelerable: false,
  extendable: false,
  transferable: false,
};
