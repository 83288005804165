import { FiscalAdvantage } from '@blockpulse3/graphql/hooks';

import { AllowedFiscalAdvantageOption } from './types';

export const defaultFiscalAdvantageOptions: AllowedFiscalAdvantageOption[] = Object.values(
  FiscalAdvantage,
).map((fiscalAdvantage) => {
  return {
    label: fiscalAdvantage.toString(),
    value: fiscalAdvantage,
  };
});
