import { GetSubscriptionInfosFragment } from '@blockpulse3/graphql/hooks';

export function getSubscriptionShareCount(
  subscription: GetSubscriptionInfosFragment | undefined,
): number {
  const investAmount = subscription?.investAmount || 0;
  const sharePrice = subscription?.operation.sharePrice || 1;

  let shareCount = 0;
  if (investAmount && sharePrice) {
    shareCount = Math.round(investAmount / sharePrice);
  }

  return shareCount;
}
