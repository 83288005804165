import { IntlRevocationMajorityValues } from '@blockpulse3/data/locales/types';
import { CompanyRevocationMajority } from '@blockpulse3/graphql/hooks';

export const revocationMajorityOptions: {
  label: IntlRevocationMajorityValues;
  value: CompanyRevocationMajority;
}[] = [
  { label: 'UNANIMITY', value: CompanyRevocationMajority.UNANIMITY },
  { label: 'MAJORITY_2_THIRD', value: CompanyRevocationMajority.MAJORITY_2_THIRD },
  { label: 'MAJORITY_VOICE', value: CompanyRevocationMajority.MAJORITY_VOICE },
];
