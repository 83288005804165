import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors, noop } from '@blockpulse3/data/shared';
import { AssetType, IdentityType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, getNearestMultiple } from '@blockpulse3/helpers';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { schema } from './schema';
import { INewSecondaryOperationNaturalBuyer } from './types';

type Props = {
  onSubmit?: (data: INewSecondaryOperationNaturalBuyer) => void;
  amountRequired?: boolean;
};

/**
 * NewSecondaryOperationNaturalBuyerForm.
 * Natural version of the buyer creation form.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationNaturalBuyerForm({
  onSubmit = noop,
  amountRequired = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const [isAmountTouched, setIsAmountTouched] = useState<boolean>(false);

  const { data } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });

  const { register, formState, handleSubmit, watch, setError } =
    useForm<INewSecondaryOperationNaturalBuyer>({
      resolver: yupResolver(schema),
      context: { amountRequired },
    });

  const handleFormSubmit: SubmitHandler<INewSecondaryOperationNaturalBuyer> = (data) => {
    /* ** Check for a new condition on submit. Forbid a 0 value rounded amount ** */
    const nbAmount = fixedAmount.toNumber();
    if (amountRequired && nbAmount === 0) {
      setError('investAmount', { type: 'custom', message: FormErrors.PositiveFormat });
    } else {
      onSubmit({ ...data, investAmount: nbAmount });
    }
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLFormElement>): void => {
    /* ** Avoid onBlur formState.touchedFields update ** */
    if (e.target.id === 'investAmount') {
      setIsAmountTouched(true);
    }
  };

  const operation = data?.operation;

  /* ** Compute amount to a multiple of the sharePrice ** */
  const amount = new BigNumber(watch('investAmount') || 0);
  const fixedAmount = getNearestMultiple(amount, operation?.sharePrice || 1);
  const isFixed = !amount.isEqualTo(fixedAmount) && !fixedAmount.isNaN();
  const isNull = fixedAmount.isZero() || fixedAmount.isNaN();
  const showAlert = (isFixed || isNull) && isAmountTouched;

  const fixedAmountLabel =
    operation?.assetType === AssetType.BOND
      ? t('SubscriptionAmountRoundedBond', { fixedAmount: formatNumberCurrency(fixedAmount) })
      : t('SubscriptionAmountRounded', { fixedAmount: formatNumberCurrency(fixedAmount) });

  return (
    <form
      id={IdentityType.INDIVIDUAL}
      onChange={handleFormChange}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Stack spacing="4">
        <FormControl isInvalid={!!formState.errors.email}>
          <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
          <Input id="email" type="string" {...register('email')} />
          <ErrorMessage error={formState.errors.email} />
        </FormControl>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
          <FormControl isInvalid={!!formState.errors.firstName}>
            <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
            <Input id="firstName" type="string" {...register('firstName')} />
            <ErrorMessage error={formState.errors.firstName} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors.lastName}>
            <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
            <Input id="lastName" type="string" {...register('lastName')} />
            <ErrorMessage error={formState.errors.lastName} />
          </FormControl>
        </Stack>
        <Stack>
          {amountRequired && (
            <FormControl isInvalid={!!formState.errors.investAmount}>
              <FormLabel htmlFor="investAmount">{t('PurchaseAmountInEuros')}</FormLabel>
              <InputGroup>
                <Input id="investAmount" step="0.01" type="number" {...register('investAmount')} />
                <InputRightAddon>€</InputRightAddon>
              </InputGroup>
              <ErrorMessage error={formState.errors.investAmount} />
              {showAlert && (
                <Alert mt="3" status="warning">
                  <AlertIcon as={ExclamationIcon} />
                  <Stack spacing="1">
                    <AlertTitle>{t('CorrectedAmount')}</AlertTitle>
                    <AlertDescription>
                      {isNull ? t('SubscriptionAmountGreaterThanZero') : fixedAmountLabel}
                    </AlertDescription>
                  </Stack>
                </Alert>
              )}
            </FormControl>
          )}
        </Stack>
      </Stack>
    </form>
  );
}

export type NewSecondaryOperationNaturalBuyerFormProps = Props;
