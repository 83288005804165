import {
  Badge,
  Card,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  GetMembersDocument,
  GetMembersQuery,
  GetMembersQueryVariables,
  IdentityType,
  Member,
  MemberEntityInfosFragment,
  MemberRole,
  MemberStatus,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PaginationButtons,
  SpaceMemberBadge,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { useBadge, usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../utils';

type Props = {
  /* ** Callback on update button click ** */
  onRowUpdateClick?: (memberId: Member['id']) => void;
  /* ** Callback on delete button click ** */
  onRowDeleteClick?: (memberId: Member['id']) => void;
};

export function SpaceMembersTable({
  onRowUpdateClick = noop,
  onRowDeleteClick = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const { user: authUser, spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const [tooltipId, setTooltipId] = useState<Member['id'] | null>(null);

  const { getBadge } = useBadge(
    undefined,
    [
      {
        color: 'yellow',
        label: t('MemberStatus.INVITATION_SENT'),
        value: MemberStatus.INVITATION_SENT,
      },
      {
        color: 'green',
        label: t('MemberStatus.VALIDATED'),
        value: MemberStatus.VALIDATED,
      },
    ],
    {
      color: 'green',
      label: t('MemberStatus.VALIDATED'),
    },
  );

  const paginationProps = usePagination<
    GetMembersQuery,
    GetMembersQueryVariables,
    MemberEntityInfosFragment
  >({
    queryDocument: GetMembersDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: PAGE_SIZE,
      },
      skip: !spaceId,
      fetchPolicy: 'network-only',
    },
    dataName: 'members',
    pageSize: PAGE_SIZE,
  });

  if (paginationProps.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (!paginationProps.results) {
    return <ErrorQueryCard h="200px" />;
  }

  const handleRowUpdateClick = (id: Member['id']): void => {
    onRowUpdateClick(id);
  };

  const handleRowDeleteClick = (id: Member['id']): void => {
    onRowDeleteClick(id);
  };

  const handleIdCopyClick = (id: Member['id']): void => {
    navigator.clipboard.writeText(id);
    setTooltipId(id);
    setTimeout(() => {
      setTooltipId(null);
    }, 3000);
  };

  const { results } = paginationProps;

  if (results.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Text>{t('NoMembers')}</Text>
      </Stack>
    );
  }

  const memberRole = authUser?.members?.[0].role;
  const showAdminActions = memberRole && [MemberRole.ADMIN, MemberRole.OWNER].includes(memberRole);

  return (
    <Stack>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Name')}</Th>
              <Th>id</Th>
              <Th>{t('Role', { nb: 1 })}</Th>
              <Th>{t('Status', { nb: 1 })}</Th>
              {showAdminActions && <Th>{t('Action', { nb: 2 })}</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {results.map((member) => {
              const badge = getBadge(member.status);

              return (
                <Tr key={member.id}>
                  <Td>
                    {member.user ? (
                      <IdentityCard>
                        <IdentityAvatar
                          src={member.user.profilePicture}
                          type={IdentityType.INDIVIDUAL}
                        />
                        <Stack alignItems="flex-start" spacing="1">
                          <IdentityCardTitle
                            fontSize="md"
                            fontWeight="500"
                            isChecked={member.user.individualIdentity?.identity?.isVerified}
                          >
                            <Text>{member.user.individualIdentity?.name}</Text>
                          </IdentityCardTitle>
                          <IdentityCardDescription fontSize="md" fontWeight="400">
                            <Text>{member.user.individualIdentity?.email}</Text>
                          </IdentityCardDescription>
                        </Stack>
                      </IdentityCard>
                    ) : (
                      <IdentityCard>
                        <IdentityAvatar type={IdentityType.INDIVIDUAL} />
                        <Stack alignItems="flex-start" spacing="1">
                          <IdentityCardTitle fontSize="md" fontWeight="500">
                            <Text>{`${member.data?.firstName} ${member.data?.lastName}`}</Text>
                          </IdentityCardTitle>
                          <IdentityCardDescription fontSize="md" fontWeight="400">
                            <Text>{member.data?.email}</Text>
                          </IdentityCardDescription>
                        </Stack>
                      </IdentityCard>
                    )}
                  </Td>
                  <Td>
                    <HStack maxWidth="250px" spacing="1">
                      <Text isTruncated color="gray.500" maxW="100px">
                        {member.id}
                      </Text>
                      <Tooltip
                        hasArrow
                        isOpen={tooltipId === member.id}
                        label={t('Copied')}
                        placement="top"
                      >
                        <Icon
                          as={DocumentDuplicateIcon}
                          boxSize="20px"
                          cursor="pointer"
                          onClick={(): void => handleIdCopyClick(member.id)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td>
                    <SpaceMemberBadge role={member.role} />
                  </Td>
                  <Td>
                    <Badge colorScheme={badge.color}>{badge.label}</Badge>
                  </Td>
                  {showAdminActions && (
                    <Td>
                      <HStack spacing="1">
                        {member.status === MemberStatus.VALIDATED && (
                          <IconButton
                            aria-label="edit"
                            icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                            variant="secondary"
                            onClick={(): void => handleRowUpdateClick(member.id)}
                          />
                        )}
                        <IconButton
                          aria-label="delete"
                          icon={<Icon as={TrashIcon} boxSize="18px" color="gray.700" />}
                          variant="secondary"
                          onClick={(): void => handleRowDeleteClick(member.id)}
                        />
                      </HStack>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={paginationProps.currentPage}
        hasNextPage={paginationProps.pageInfo.hasNextPage}
        hasPreviousPage={paginationProps.pageInfo.hasPreviousPage}
        loading={paginationProps.isLoadingMore}
        pageCount={paginationProps.pageCount}
        onNextPage={paginationProps.handleNextPage}
        onPreviousPage={paginationProps.handlePreviousPage}
      />
    </Stack>
  );
}

export type SpaceMembersTableProps = Props;
