import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  OperationStatus,
  useGetCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';

import { FundraisingSubscribersPanel } from './FundraisingSubscribersPanel';
import { checkSubscriberDefaultValues, checkSubscriberSchema } from './schema';
import { IFundraisingSubscriberCheck } from './types';

const MAX_SUBSCRIBERS = 149;

type Props = unknown;

/**
 * NewPrivateFundraisingSubscribers.
 * Second step of fundraising funnel, subscribers. Splitted into modal and table components.
 *
 * @returns {JSX.Element}
 */
export function NewPrivateFundraisingSubscribers(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();
  const { operationId = '', companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const { data, loading } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
    onCompleted: (data) => {
      if (data.operation.status !== OperationStatus.DRAFT) {
        navigate(generatePath(routes.company.href, { companyId }));
      }
    },
    onError: () => {
      navigate(generatePath(routes.company.href, { companyId }));
    },
  });
  const operation = data?.operation;
  const fundraisingRoutes = routes.company.newFundraising.private;

  const subscribersCount = operation?.subscriptionCount || 0;
  const isMaxSubsribers = subscribersCount > MAX_SUBSCRIBERS;
  const isSubscribersListEmpty = subscribersCount === 0;

  /* ** Max subscriber check form ** */
  const { register, formState, handleSubmit, watch, setValue } =
    useForm<IFundraisingSubscriberCheck>({
      defaultValues: checkSubscriberDefaultValues,
      resolver: yupResolver(checkSubscriberSchema),
    });
  const isChecked = watch('maxSubscribers');

  const handleFormCancel = (): void => {
    if (!company) return;
    if (company.capitalType === CompanyCapitalType.FIXED) {
      navigate('../' + fundraisingRoutes.edit.iban.href);
    } else {
      navigate('../' + fundraisingRoutes.edit.documentation.href);
    }
  };

  const handleFormSubmit: SubmitHandler<IFundraisingSubscriberCheck> = (): void => {
    navigate('../' + fundraisingRoutes.edit.summary.href);
  };

  useEffect(() => {
    setValue('maxSubscribers', !isMaxSubsribers);
  }, [setValue, isMaxSubsribers]);

  return (
    <Skeleton isLoaded={!loading} w="full">
      <Card variant="divider-top">
        <CardHeader as={Stack} spacing="2">
          <Heading size="lg">{t('Subscriber', { nb: 1 })}</Heading>
          <Text color="gray.600" fontSize="lg">
            {t('CapitalIncreaseSubscriberInfo')}
          </Text>
        </CardHeader>
        <Divider />
        <CardBody>
          <Stack spacing={isMaxSubsribers ? '8' : '0'}>
            <FundraisingSubscribersPanel />
            <form id="fundraising-subscribers" onSubmit={handleSubmit(handleFormSubmit)}>
              <Stack spacing="8">
                {isMaxSubsribers && (
                  <FormControl isInvalid={!!formState.errors.maxSubscribers}>
                    <Checkbox
                      alignItems="flex-start"
                      isChecked={isChecked}
                      {...register('maxSubscribers')}
                    >
                      <Stack spacing="1">
                        <Text color="gray.700" fontWeight="500" lineHeight="1.2">
                          {t('ByCheckingThisBox', {
                            max: MAX_SUBSCRIBERS,
                          })}
                        </Text>
                        <Text color="gray.500" fontWeight="400" lineHeight="1.2">
                          {t('DueToCountOfInvitedSubscribers')}
                        </Text>
                      </Stack>
                    </Checkbox>
                  </FormControl>
                )}
              </Stack>
            </form>
          </Stack>
        </CardBody>
        <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
          <Button variant="secondary" w="full" onClick={handleFormCancel}>
            {t('Back')}
          </Button>
          <Button
            data-cy="next"
            form="fundraising-subscribers"
            isDisabled={isSubscribersListEmpty}
            type="submit"
            w="full"
          >
            {t('Next')}
          </Button>
        </CardFooter>
      </Card>
    </Skeleton>
  );
}

export type NewPrivateFundraisingSubscribersProps = Props;
