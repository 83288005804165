import { Heading, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { LinkButton } from '@blockpulse3/ui/commons';

import { AssetsAndPoolsView } from './AssetsAndOptions';
import { CapTableView } from './CapTable';
import { ShareTransferRegisterView } from './ShareTransferRegister';

type Props = {
  backHref?: string;
};

/**
 * ShareholdingView.
 * Main shareholding view, rendering CaptableView and ShareTransferRegisterView (RMT).
 *
 * @returns {JSX.Element}
 */
export function ShareholdingView({ backHref }: Props): JSX.Element {
  const t = useTranslations();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('/rmt') && tabIndex !== 1) {
      setTabIndex(1);
      return;
    }
    if (pathname.includes('/assets') && tabIndex !== 2) {
      setTabIndex(2);
      return;
    }
    if (!pathname.includes('/rmt') && !pathname.includes('/assets') && tabIndex !== 0) {
      setTabIndex(0);
      return;
    }
  }, [tabIndex, pathname]);

  const captableRoutes = routes.company.captable;

  const handleTabsChange = (index: number): void => {
    if (index === 1 && !pathname.includes('/rmt')) {
      navigate(captableRoutes.rmt.href);
      setTabIndex(index);
      return;
    }
    if (index === 2 && !pathname.includes('/assets')) {
      navigate(captableRoutes.assets.href);
      setTabIndex(index);
      return;
    }
    navigate('..', { relative: 'path' });
    setTabIndex(index);
  };

  return (
    <Stack spacing="6">
      {backHref && <LinkButton label={t('Back')} route={backHref} />}
      <Heading>{t('Shareholding')}</Heading>
      <Tabs isLazy index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>{t('CapitalizationTable')}</Tab>
          <Tab>{t('ShareTransferRegister')}</Tab>
          <Tab>{t('AssetsAndPools')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px="0">
            <CapTableView />
          </TabPanel>
          <TabPanel px="0">
            <ShareTransferRegisterView />
          </TabPanel>
          <TabPanel px="0">
            <AssetsAndPoolsView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export type ShareholdingViewProps = Props;
