import { Stack } from '@chakra-ui/react';
import { CogIcon, CurrencyEuroIcon, HomeIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { generatePath, resolvePath, useLocation, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { SpvStatus, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import { NavGroupItem } from '@blockpulse3/ui/commons';

export function SettingsNavSPV(): JSX.Element {
  const t = useTranslations();

  const { pathname } = useLocation();

  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const homeRoute = generatePath(routes.company.href, { companyId });
  const editRoute = resolvePath(
    generatePath(routes.onboardings.spv.edit.href, { companyId }),
    routes.onboardings.spv.href,
  ).pathname;
  const matriculationRoute = resolvePath(
    routes.onboardings.spv.edit.matriculation.href,
    editRoute,
  ).pathname;

  const isEditing = company?.spvStatus === SpvStatus.EDITING;

  return (
    <Stack spacing="1">
      <NavGroupItem
        active={pathname === homeRoute}
        href={homeRoute}
        icon={<HomeIcon height="18" width="18" />}
        label={t('Dashboard')}
      />
      {isEditing ? (
        <NavGroupItem
          active={pathname === editRoute || pathname === matriculationRoute}
          href={editRoute}
          icon={<CogIcon height="18" width="18" />}
          label={t('SPVEdition')}
        />
      ) : (
        <NavGroupItem
          active={pathname === matriculationRoute}
          href={matriculationRoute}
          icon={<OfficeBuildingIcon height="18" width="18" />}
          label={t('SPVMatriculation')}
        />
      )}
      {company?.identity?.bankAccount?.bankDashboardUrl && (
        <NavGroupItem
          href={company.identity.bankAccount.bankDashboardUrl}
          icon={<CurrencyEuroIcon height="18" width="18" />}
          label={t('PaymentAccount')}
        />
      )}
    </Stack>
  );
}
