import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  HStack,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import { useTranslations } from 'use-intl';

import {
  AssetType,
  BalanceInfosFragment,
  Identity,
  IdentityType,
  MovementType,
  OperationDocumentType,
  TokenDurationUnit,
  useGetAssetQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetMovementsByAssetAndIdentityQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import {
  AssetBadge,
  DocumentLink,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';
import { SubscriptionPanelDocuments } from '@blockpulse3/web-client/operation/commons';

import { CompanyValuationsCard } from '../CompanyValuationsCard';

type Props = {
  balance: BalanceInfosFragment;
  identityId: Identity['id'] | null;
} & Omit<DrawerProps, 'children'>;

/**
 * BondAssetSidePanel.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function BondAssetSidePanel({ balance, identityId, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValue = useTranslations('DocumentValues');
  const i18nDurationsUnit = useTranslations('DurationUnits');

  const walletId = balance.walletId;
  const { signer, contact } = balance.asset.company;

  const assetReq = useGetAssetQuery({
    variables: {
      companyId: balance.asset.company.id,
      assetId: balance.asset.id,
    },
    skip: !balance.asset.id,
  });

  const movementsReq = useGetMovementsByAssetAndIdentityQuery({
    variables: { assetId: balance.asset.id, identityId: identityId || '' },
    skip: !identityId || !balance.asset.id,
  });

  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  if (assetReq.loading || movementsReq.loading) {
    return (
      <Drawer size="sm" {...props}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pb="16" px="0">
            <Stack alignItems="center" h="full" justifyContent="center" w="full">
              <Spinner />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  if (
    !assetReq.data ||
    assetReq.error ||
    assetReq.data.asset?.token?.__typename !== 'BondTokenModel'
  ) {
    return (
      <Drawer size="sm" {...props}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pb="16" px="0">
            <ErrorQueryCard height="full" width="full" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  const bondToken = assetReq.data.asset?.token;
  const assetDocuments = assetReq.data.asset?.documents || [];
  const movements = movementsReq.data?.getMovementsByAssetAndIdentity || [];

  const { durationUnit, interestRate, interestPeriod, maturityDate } = bondToken;

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const getPeriodicity = (): string => {
    let isMonth, isYear;

    const defaultLabel = `${interestPeriod} ${i18nDurationsUnit(
      durationUnit as keyof IntlMessages['DurationUnits'],
    )}`;

    switch (durationUnit) {
      case TokenDurationUnit.day: {
        isMonth = interestPeriod === 30 || interestPeriod === 31;
        isYear = interestPeriod === 365;
        break;
      }
      case TokenDurationUnit.month: {
        isMonth = interestPeriod === 1;
        isYear = interestPeriod === 12;
        break;
      }
      default: {
        return defaultLabel;
      }
    }
    if (isYear) return t('YearShort', { nb: 1 });
    if (isMonth) return t('DurationUnits.month');
    return defaultLabel;
  };

  const repIndividualIdentity = contact?.individualIdentity || signer.individualIdentity;

  return (
    <Drawer {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody p="0">
          <Stack py="4" spacing="4">
            <Card m="4" my="0" p="0" variant="rounded-gray">
              <CompanyValuationsCard balance={balance} m="0" underlyingAssetType={AssetType.BOND} />
              <CardBody fontSize="sm" px="4" py="1">
                <Accordion allowToggle variant="unstyled">
                  <AccordionItem>
                    <AccordionButton data-cy="expand-asset-details" justifyContent="flex-end">
                      <Text color="gray" fontSize="xs">
                        {t('ShowDetails')}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel as={Stack} px="0" py="3" spacing="1">
                      <HStack alignItems="start" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('Asset', { nb: balance.total })}
                        </Text>
                        <HStack>
                          {balance.subscribedAmount > 0 && (
                            <HStack>
                              <AssetBadge
                                type={balance.asset.assetType}
                                value={balance.subscribedAmount}
                              />
                              <Text fontSize="sm">- {balance.asset.name}</Text>
                            </HStack>
                          )}
                        </HStack>
                      </HStack>
                      <HStack justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('InterestRate')}
                        </Text>
                        <Text fontWeight="500">
                          {formatNumberPercentage(interestRate || 0)}
                          {` / ${getPeriodicity().toLowerCase()}`}
                        </Text>
                      </HStack>
                      <HStack justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('MaturityDate')}
                        </Text>
                        <Text fontWeight="500">{dayjs(maturityDate).format('D MMMM YYYY')}</Text>
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
            <Divider />
            {movements.map((movement) => {
              return (
                <Fragment key={movement.id}>
                  <Stack px="4" spacing="4">
                    <Stack spacing="2">
                      <HStack>
                        <Text fontSize="md" fontWeight="600">
                          {movement.type === MovementType.BURN ||
                          (movement.type === MovementType.TRANSFER &&
                            movement.fromWallet?.id === walletId)
                            ? t('TransferOfShareDate', {
                                date: formatDate(movement.createdAt, 'D MMMM YYYY'),
                              })
                            : t('AcquisitionDateNb', {
                                date: formatDate(movement.createdAt, 'D MMMM YYYY'),
                              })}
                        </Text>
                      </HStack>
                      <HStack fontSize="sm" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('BondCount')}
                        </Text>
                        <Stack alignItems="flex-end" flexShrink="0" spacing="0">
                          <AssetBadge type={movement.asset.assetType} value={movement.amount} />
                        </Stack>
                      </HStack>
                      <HStack fontSize="sm" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('AcquisitionPrice')}
                        </Text>
                        <Text fontWeight="500">
                          {t('NbPricePerBond', {
                            price: movement.subscription?.operation?.sharePrice
                              ? formatNumberCurrency(movement.subscription?.operation?.sharePrice)
                              : '-',
                          })}
                        </Text>
                      </HStack>
                    </Stack>
                    {movement.subscription && (
                      <Stack layerStyle="border" py="4" rounded="md">
                        <SubscriptionPanelDocuments subscriptionId={movement.subscription.id} />
                      </Stack>
                    )}
                  </Stack>
                  <Divider />
                </Fragment>
              );
            })}
            {assetDocuments.length && (
              <>
                <Stack px="4" spacing="2">
                  <Text color="gray.600" fontWeight="600">
                    {t('CompanyDocuments')}
                  </Text>
                  <Stack spacing="2">
                    {assetDocuments.map((document) => {
                      if (!document) return null;
                      return (
                        <DocumentLink
                          key={document.id}
                          fileName={
                            document.type === OperationDocumentType.CUSTOM_FILE
                              ? document.title
                              : i18nDocumentValue(
                                  document.type as keyof IntlMessages['DocumentValues'],
                                )
                          }
                          onClick={(): void => handleDocumentClick(document.id)}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
            <Stack px="4" spacing="2">
              <Text color="gray.600" fontWeight="600">
                {t('Contact', { nb: 1 })}
              </Text>
              <IdentityCard bg="gray.50" p="3">
                <IdentityAvatar type={IdentityType.INDIVIDUAL} />
                <Stack spacing="0">
                  <IdentityCardTitle isChecked>
                    <Text>{repIndividualIdentity?.name}</Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription>
                    <Text>{repIndividualIdentity?.email}</Text>
                  </IdentityCardDescription>
                </Stack>
              </IdentityCard>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type BondAssetSidePanelProps = Props;
