import { Button, Circle, HStack, Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { CheckIcon, DocumentTextIcon } from '@heroicons/react/outline';
import { DotsHorizontalIcon, EyeIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentSignatureValues, IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { Exceptions } from '@blockpulse3/data/shared';
import {
  MergedDocumentSignatureStep,
  SubscriptionStepStatus,
  SubscriptionStepType,
  useGetSubscriptionQuery,
  usePreviewSubscriptionDocumentLazyQuery,
  useValidateSubscriptionDocumentMutation,
} from '@blockpulse3/graphql/hooks';
import { getURLObject } from '@blockpulse3/helpers';
import {
  CONTENT_ERROR,
  CONTENT_LOADING,
  PreviewDocumentModal,
  SignDocumentModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';
import { getIsUserSigner, getSubscriptionDocument } from './utils';

type Props = {
  /* ** Is Step disabled ** */
  isDisabled: boolean;
  /* ** Subscription data step ** */
  subscriptionStep: MergedDocumentSignatureStep;
};

export function DocumentSignatureStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');
  const i18nDocumentSignatureValues = useTranslations('DocumentSignatureValues');

  const { type, documentType, signedBy } = subscriptionStep;

  const { subscriptionId = '' } = useParams();

  const { user: authUser } = useAuthUser();
  const { identityId } = useIdentity();

  const previewModal = useDisclosure();
  const signatureModal = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  const [loadPreview] = usePreviewSubscriptionDocumentLazyQuery();
  const [validateDocument, { loading: isDocumentValidating }] =
    useValidateSubscriptionDocumentMutation();

  const [errorPreview, setErrorPreview] = useState<string | undefined>(undefined);
  /* ** Use URL naming because there is always one document to sign, no need of "record caching" ** */
  const [signDocumentURL, setSignDocumentURL] = useState<string | symbol>(CONTENT_LOADING);
  /* ** Previews and signature modal states ** */
  const [isSignatureModalDisabled, setIsSignatureModalDisabled] = useState<boolean>(false);
  /* ** Document polling ** */
  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);

  const stepStatus = subscriptionStep.status;
  const subscription = subscriptionReq.data?.subscription;
  const documents = subscription?.subscriptionDocuments;

  /* ** Is current user a signer and able to be redirected to SignatureIt ** */
  const { isUserSigner, isUserBuyer, isUserSeller } = getIsUserSigner(subscription, authUser);

  /* ** Get document to sign ** */
  const subscriptionDocument = getSubscriptionDocument(documentType, documents);
  const signDocumentStatus = subscriptionDocument?.status || null;

  // Display preview button only when subscription details step is in progress
  const subscriptionSteps = subscription?.data.mergedSteps;
  const subscriptionDetailsStepStatus = subscriptionSteps?.find(
    (s) => s.type === SubscriptionStepType.DETAILS,
  )?.status;
  const canPreviewDocument =
    isDisabled && subscriptionDetailsStepStatus === SubscriptionStepStatus.IN_PROGRESS;

  const handleDocumentModalOpen = (onOpenModal: () => void): void => {
    // Refetch document if step is not started to handle changes with subscription amount step
    const isStepStarted = stepStatus !== SubscriptionStepStatus.NONE;

    if (!isStepStarted) {
      setSignDocumentURL(CONTENT_LOADING);
    }

    if (subscription && identityId) {
      loadPreview({
        variables: {
          subscriptionId: subscription.id,
          documentType: subscriptionStep.documentType,
        },
        fetchPolicy: isStepStarted ? 'cache-first' : 'no-cache',
        onCompleted: ({ pdfURL }: { pdfURL: string }) => {
          const objURL = getURLObject(pdfURL, 'application/pdf');
          setSignDocumentURL(objURL);
        },
        onError(err) {
          setSignDocumentURL(CONTENT_ERROR);
          let errorMessage;
          if (err.message === Exceptions.ErrorBuyerIdentityNotApproved) {
            errorMessage = isUserBuyer
              ? t('ErrorUserSignerNotApproved')
              : t('ErrorBuyerIdentityNotApproved');
          }
          if (err.message === Exceptions.ErrorSellerIdentityNotApproved) {
            errorMessage = isUserSeller
              ? t('ErrorUserSignerNotApproved')
              : t('ErrorSellerIdentityNotApproved');
          }

          setErrorPreview(errorMessage);
        },
      });
      setIsSignatureModalDisabled(false);

      onOpenModal();
    }
  };

  const handleDocumentModalClose = (): void => {
    setErrorPreview(undefined);
    signatureModal.onClose();
    previewModal.onClose();
  };

  const handleDocumentSign = (): void => {
    if (!identityId) return;

    validateDocument({
      variables: {
        validateSubscriptionDocumentInput: {
          subscriptionId: subscription?.id || '',
          documentType: subscriptionStep.documentType,
        },
      },
      onCompleted: (data) => {
        if (data.validateSubscriptionDocument?.signingUrl) {
          window.open(data.validateSubscriptionDocument.signingUrl, '_self');
        } else {
          successToast({ title: t('DocumentSentToSignatory') });
          setIsSignatureModalDisabled(true);
        }
      },
      onError: (err) => {
        let toastTitle;
        if (err.message === Exceptions.ErrorBuyerIdentityNotApproved) {
          toastTitle = isUserBuyer
            ? t('ErrorUserSignerNotApproved')
            : t('ErrorBuyerIdentityNotApproved');
        } else if (err.message === Exceptions.ErrorSellerIdentityNotApproved) {
          toastTitle = isUserSeller
            ? t('ErrorUserSignerNotApproved')
            : t('ErrorSellerIdentityNotApproved');
        } else {
          toastTitle = t('SignatureSendingError');
        }

        errorToast({ title: toastTitle });
      },
    });
  };

  const SignedByIcon = ({ isSigned }: { isSigned: boolean }): JSX.Element =>
    isSigned ? (
      <Circle bg="green.400" borderColor="green.400" borderWidth="0" size="3">
        <CheckIcon color="white" height="10" width="10" />
      </Circle>
    ) : (
      <Circle bg="yellow.300" borderColor="yellow.300" borderWidth="0" size="3">
        <DotsHorizontalIcon color="white" height="10" width="10" />
      </Circle>
    );

  useEffect(() => {
    if (!isPollingStarted && stepStatus === SubscriptionStepStatus.IN_PROGRESS) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      subscriptionReq.startPolling(2000);
      /* ** Stop polling after 10s ** */
      setTimeout(() => {
        subscriptionReq.stopPolling();
        setIsPollingLoading(false);
      }, 20_000);
    }
    /* ** Stop polling when step status is VALID ** */
    if (isPollingStarted && stepStatus === SubscriptionStepStatus.VALID) {
      subscriptionReq.stopPolling();
      setIsPollingStarted(false);
      setIsPollingLoading(false);
    }
  }, [subscriptionReq, isPollingStarted, stepStatus]);

  return (
    <>
      <SubscriptionStep
        icon={DocumentTextIcon}
        isBadgeLoading={isPollingLoading}
        isDisabled={isDisabled}
        status={stepStatus}
        title={i18nDocumentSignatureValues(documentType as IntlDocumentSignatureValues)}
        type={type}
        onClick={(): void => handleDocumentModalOpen(signatureModal.onOpen)}
      >
        {(canPreviewDocument || signedBy) && (
          <>
            {canPreviewDocument && (
              <Button
                color="gray.900x"
                fontWeight="400"
                sx={{ textDecoration: 'underline' }}
                textAlign="left"
                variant="link"
                whiteSpace="normal"
                onClick={(): void => handleDocumentModalOpen(() => previewModal.onOpen())}
              >
                <HStack spacing="2">
                  <Icon as={EyeIcon} boxSize="16px" color="gray.500" />
                  <Text fontSize="sm">{t('PreviewSubscriptionBulletin')}</Text>
                </HStack>
              </Button>
            )}
            {signedBy && (
              <Stack>
                <HStack>
                  <SignedByIcon isSigned={signedBy.seller} />
                  <Text fontSize="sm">
                    {signedBy.seller
                      ? t('DocumentSignedBySeller')
                      : t('DocumentSellerSignaturePending')}
                  </Text>
                </HStack>
                <HStack>
                  <SignedByIcon isSigned={signedBy.buyer} />
                  <Text fontSize="sm">
                    {signedBy.buyer
                      ? t('DocumentSignedByBuyer')
                      : t('DocumentBuyerSignaturePending')}
                  </Text>
                </HStack>
              </Stack>
            )}
          </>
        )}
      </SubscriptionStep>
      <SignDocumentModal
        errorTitle={errorPreview}
        isDisabled={isSignatureModalDisabled}
        isOpen={signatureModal.isOpen}
        isSignatureLoading={isDocumentValidating}
        isUserSigner={isUserSigner}
        src={signDocumentURL}
        status={signDocumentStatus}
        title={i18nDocumentValues(documentType as IntlDocumentValues)}
        onCancel={handleDocumentModalClose}
        onClose={handleDocumentModalClose}
        onSign={handleDocumentSign}
      />
      <PreviewDocumentModal
        isOpen={previewModal.isOpen}
        src={signDocumentURL}
        title={i18nDocumentValues(documentType as IntlDocumentValues)}
        onClose={handleDocumentModalClose}
      />
    </>
  );
}
