import {
  SecondaryMarketType,
  SubscriptionTab,
  SubscriptionsTabsStatus,
} from '@blockpulse3/data/shared';
import {
  IdentityType,
  OperationDocumentType,
  OperationInfosFragment,
  SubscriptionFilterType,
  SubscriptionSide,
  SubscriptionStepStatus,
  SubscriptionStepType,
  SubscriptionsCountInfosFragment,
  SubscriptionsFilterInput,
} from '@blockpulse3/graphql/hooks';

/* ** STEP Filters ** */
export const subscriptionFilterStep: SubscriptionsFilterInput[] = [
  {
    type: SubscriptionFilterType.STEP,
    name: 'TermsOfSaleNotCompleted',
    value: 'TermsOfSaleNotCompleted',
    stepType: SubscriptionStepType.SALE,
    stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.IN_PROGRESS],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'KycKybMissing',
    value: 'KycKybMissing',
    stepType: SubscriptionStepType.SWAN_ONBOARDING,
    stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.IN_PROGRESS],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'KycKybPending',
    value: 'KycKybPending',
    stepType: SubscriptionStepType.SWAN_ONBOARDING,
    stepStatuses: [SubscriptionStepStatus.PENDING],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'KycKybRefused',
    value: 'KycKybRefused',
    stepType: SubscriptionStepType.SWAN_ONBOARDING,
    stepStatuses: [SubscriptionStepStatus.ERROR],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SaleAmountUndefined',
    value: 'SaleAmountUndefined',
    stepType: SubscriptionStepType.SALE,
    stepStatuses: [SubscriptionStepStatus.NONE],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SignatureODMNotSigned',
    value: 'SignatureODMNotSigned',
    stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
    documentType: OperationDocumentType.ODM,
    stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SignatureODMInProgress',
    value: 'SignatureODMInProgress',
    stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
    documentType: OperationDocumentType.ODM,
    stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SignatureWithdrawalNotSigned',
    value: 'SignatureWithdrawalNotSigned',
    stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
    documentType: OperationDocumentType.WITHDRAWAL,
    stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SignatureWithdrawalInProgress',
    value: 'SignatureWithdrawalInProgress',
    stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
    documentType: OperationDocumentType.WITHDRAWAL,
    stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SignatureCerfaNotSigned',
    value: 'SignatureCerfaNotSigned',
    stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
    documentType: OperationDocumentType.TRANSFER_DECLARATION,
    stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'SignatureCerfaInProgress',
    value: 'SignatureCerfaInProgress',
    stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
    documentType: OperationDocumentType.TRANSFER_DECLARATION,
    stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'PaymentInProgress',
    value: 'PaymentInProgress',
    stepType: SubscriptionStepType.PAYMENT,
    stepStatuses: [
      SubscriptionStepStatus.NONE,
      SubscriptionStepStatus.PENDING,
      SubscriptionStepStatus.IN_PROGRESS,
      SubscriptionStepStatus.ERROR,
    ],
  },
];

export function getSubscriptionFilterStep(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation) return subscriptionFilterStep;

  const isWithdrawalMarket =
    operation?.settings?.secondaryMarketType === SecondaryMarketType.WITHDRAWAL_MARKET;

  const odmFilterStep = [
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureODMNotSigned',
      value: 'SignatureODMNotSigned',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: OperationDocumentType.ODM,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureODMInProgress',
      value: 'SignatureODMInProgress',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: OperationDocumentType.ODM,
      stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
    },
  ];

  const cerfaFilterStep = [
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureCerfaInProgress',
      value: 'SignatureCerfaInProgress',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: OperationDocumentType.TRANSFER_DECLARATION,
      stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureCerfaNotSigned',
      value: 'SignatureCerfaNotSigned',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: OperationDocumentType.TRANSFER_DECLARATION,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
    },
  ];

  const withdrawalFilterStep = [
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureWithdrawalNotSigned',
      value: 'SignatureWithdrawalNotSigned',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: OperationDocumentType.WITHDRAWAL,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'SignatureWithdrawalInProgress',
      value: 'SignatureWithdrawalInProgress',
      stepType: SubscriptionStepType.DOCUMENT_SIGNATURE,
      documentType: OperationDocumentType.WITHDRAWAL,
      stepStatuses: [SubscriptionStepStatus.PENDING, SubscriptionStepStatus.IN_PROGRESS],
    },
  ];

  return [
    {
      type: SubscriptionFilterType.STEP,
      name: 'TermsOfSaleNotCompleted',
      value: 'TermsOfSaleNotCompleted',
      stepType: SubscriptionStepType.SALE,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.IN_PROGRESS],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'KycKybMissing',
      value: 'KycKybMissing',
      stepType: SubscriptionStepType.SWAN_ONBOARDING,
      stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.IN_PROGRESS],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'KycKybPending',
      value: 'KycKybPending',
      stepType: SubscriptionStepType.SWAN_ONBOARDING,
      stepStatuses: [SubscriptionStepStatus.PENDING],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'KycKybRefused',
      value: 'KycKybRefused',
      stepType: SubscriptionStepType.SWAN_ONBOARDING,
      stepStatuses: [SubscriptionStepStatus.ERROR],
    },
    {
      type: SubscriptionFilterType.STEP,
      name: 'SaleAmountUndefined',
      value: 'SaleAmountUndefined',
      stepType: SubscriptionStepType.SALE,
      stepStatuses: [SubscriptionStepStatus.NONE],
    },
    ...(isWithdrawalMarket ? [...withdrawalFilterStep] : [...odmFilterStep, ...cerfaFilterStep]),
    {
      type: SubscriptionFilterType.STEP,
      name: 'PaymentInProgress',
      value: 'PaymentInProgress',
      stepType: SubscriptionStepType.PAYMENT,
      stepStatuses: [
        SubscriptionStepStatus.NONE,
        SubscriptionStepStatus.PENDING,
        SubscriptionStepStatus.IN_PROGRESS,
        SubscriptionStepStatus.ERROR,
      ],
    },
  ];
}

export function getTabsWithCount(count?: SubscriptionsCountInfosFragment): SubscriptionTab[] {
  return [
    {
      name: SubscriptionsTabsStatus.ALL_STATUS,
      count: count?.total || 0,
    },
    {
      name: SubscriptionsTabsStatus.NOT_SIGNED_UP,
      count: count?.invitationSent || 0,
    },
    {
      name: SubscriptionsTabsStatus.SIGNED_UP,
      count: count?.signedUp || 0,
    },
    {
      name: SubscriptionsTabsStatus.PENDING,
      count: count?.pending || 0,
    },
    {
      name: SubscriptionsTabsStatus.COMPLETED,
      count: count?.completed || 0,
    },
    {
      name: SubscriptionsTabsStatus.APPROVED,
      count: count?.approved || 0,
    },
  ].filter((tab) => tab) as SubscriptionTab[];
}

export function getSubscriptionFilterTags(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation || !operation?.company?.tags) {
    return [];
  }

  return operation.company.tags.map((tag) => ({
    type: SubscriptionFilterType.TAGS,
    name: tag.name,
    value: tag.id,
  }));
}

/* ** IDENTITY_TYPE Filters ** */
export const sellerSubscriptionFilterIdentity: SubscriptionsFilterInput[] = [
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'IndividualIdentity',
    value: `${IdentityType.INDIVIDUAL}_${SubscriptionSide.BUYER}`,
  },
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'CompanyIdentity',
    value: `${IdentityType.COMPANY}_${SubscriptionSide.BUYER}`,
  },
];

export const buyerSubscriptionFilterIdentity: SubscriptionsFilterInput[] = [
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'IndividualIdentity',
    value: `${IdentityType.INDIVIDUAL}_${SubscriptionSide.SELLER}`,
  },
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'CompanyIdentity',
    value: `${IdentityType.COMPANY}_${SubscriptionSide.SELLER}`,
  },
];
