import { ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { BankDistributionType } from '@blockpulse3/graphql/hooks';
import { ResponsiveModal, ResponsiveModalProps } from '@blockpulse3/ui/commons';

import { CreateDistributionForm } from './CreateDistributionForm';

type Props = {
  bankDistributionType: BankDistributionType;
  onClose: () => void;
  onComplete: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * CreateDistributionModal.
 * Form to create a distribution.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CreateDistributionModal({
  bankDistributionType,
  onClose,
  onComplete,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('CreateNewDistribution')}</ModalHeader>
        <ModalCloseButton />
        <CreateDistributionForm
          bankDistributionType={bankDistributionType}
          onClose={onClose}
          onComplete={onComplete}
        />
      </ModalContent>
    </ResponsiveModal>
  );
}
