import {
  FiscalAdvantage,
  GetBalancesByAssetAndIdentityQuery,
  GetSubscriptionInfosFragment,
  GetSubscriptionQuery,
  HoldingMethod,
} from '@blockpulse3/graphql/hooks';
import { getSubscriptionShareCount } from '@blockpulse3/helpers';

import { SaleStepForm } from './types';

export function getSaleStepValues(
  subscription: GetSubscriptionInfosFragment | undefined,
): SaleStepForm {
  const shareCount = getSubscriptionShareCount(subscription);
  const investAmount = subscription?.investAmount || 0;
  const buyerIdentity = subscription?.buyerIdentity;

  return {
    shareCount,
    investAmount,
    buyerIdentity: {
      label: buyerIdentity?.name || '',
      value: buyerIdentity?.id || '',
      identity: buyerIdentity,
    },
  };
}

function parseAvailableShares(
  balances: GetBalancesByAssetAndIdentityQuery['getBalancesByAssetAndIdentity'],
): Record<string, number> {
  return (balances || []).reduce((acc, balance) => {
    const holdingMethod = balance?.wallet?.holdingMethod ?? HoldingMethod.DIRECT;
    const availableShares = balance?.availableShares || 0;
    return {
      ...acc,
      [holdingMethod]: availableShares,
    };
  }, {});
}

export function getAvailableSharesByHoldingMethod(
  subscriptionData: GetSubscriptionQuery | undefined,
  balances: GetBalancesByAssetAndIdentityQuery['getBalancesByAssetAndIdentity'],
): number {
  const availableSharesByHoldingMethod = parseAvailableShares(balances);

  const subscription = subscriptionData?.subscription;
  const availableShares =
    availableSharesByHoldingMethod[subscription?.holdingMethod ?? HoldingMethod.DIRECT] || 0;

  // Compute validated share amount to add it back to total available shares
  const subscriptionShareCount = getSubscriptionShareCount(subscription);

  return availableShares + subscriptionShareCount;
}

/**
 * getAllowedHoldingMethods.
 * Retrieves the allowed holding methods based on identity's balances.
 *
 * @param {GetBalancesByAssetAndIdentityQuery['getBalancesByAssetAndIdentity']} balances
 * @returns {FiscalAdvantage[]}
 */
export function getAllowedHoldingMethods(
  balances: GetBalancesByAssetAndIdentityQuery['getBalancesByAssetAndIdentity'],
): HoldingMethod[] {
  return (balances || []).map((balance) => balance?.wallet?.holdingMethod ?? HoldingMethod.DIRECT);
}

export function getAllowedFiscalAdvantages(
  allowedHoldingMethods: HoldingMethod[],
): FiscalAdvantage[] {
  const allowedFiscalAdvantages = [];
  if (allowedHoldingMethods?.includes(HoldingMethod.PEA)) {
    allowedFiscalAdvantages.push(FiscalAdvantage.PEA);
  }
  if (allowedHoldingMethods?.includes(HoldingMethod.PEA_PME)) {
    allowedFiscalAdvantages.push(FiscalAdvantage.PEA_PME);
  }
  return allowedFiscalAdvantages;
}
