import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import {
  OperationDocumentType,
  useDeleteSubscriptionCustomDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { getDocumentIds } from '@blockpulse3/helpers';
import {
  DeleteConfirmModal,
  DocumentLink,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

/**
 * TransactionPanelDocuments.
 * Document logic section of the transaction side panel.
 *
 * @returns {JSX.Element}
 */
export function TransactionPanelDocuments(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const inputRef = useRef<HTMLInputElement>(null);
  const deleteRef = useRef(null);

  const deleteModal = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { subscriptionId = '' } = useParams();
  const isUserAuthorized = useManagerRole({ subscriptionId });

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const [selectedFileId, setSelectedFileId] = useState<string>('');
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);

  /* ** Query to get pdf url of a document ** */
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [deleteCustomDocument, { loading: isCustomDocumentDeletionLoading }] =
    useDeleteSubscriptionCustomDocumentMutation();

  const subscription = data?.subscription;
  const operation = subscription?.operation;
  const subscriptionDocuments = subscription?.subscriptionDocuments || [];
  const documents = getDocumentIds(subscriptionDocuments);

  const customFiles = subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.CUSTOM_FILE,
  );

  if (loading || !data || !operation || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: 2 })}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const handleAddDocumentClick = (): void => {
    inputRef?.current?.click();
  };

  const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = e.target.files;

    if (!files?.[0]) return;

    const formData = new FormData();
    formData.append('subscriptionDocument', files[0]);
    formData.append('subscriptionId', subscriptionId);
    formData.append('documentType', OperationDocumentType.CUSTOM_FILE);

    setIsUploadLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/subscriptions/uploadSubscriptionDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
      })
      .catch(() => {
        errorToast({ title: t('FileUploadError') });
      });

    await refetch();
    setIsUploadLoading(false);
  };

  const handleFileDelete = (fileId: string): void => {
    setSelectedFileId(fileId);
    deleteModal.onOpen();
  };

  const handleDelete = (): void => {
    if (!selectedFileId) return;

    deleteCustomDocument({
      variables: {
        subscriptionId,
        documentId: selectedFileId,
      },
      onCompleted: () => {
        refetch();
      },
    });

    deleteModal.onClose();
  };

  return (
    <Stack px="4" spacing="2">
      <HStack justifyContent="space-between">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: subscriptionDocuments.length })}
        </Text>
        <form id="add-document-form">
          <Input
            accept=".pdf"
            display="none"
            ref={inputRef}
            type="file"
            onChange={handleFileInputChange}
          />
          <Button
            alignSelf="flex-start"
            disabled={isUploadLoading}
            isLoading={isUploadLoading}
            leftIcon={<Icon as={PlusIcon} />}
            loadingText={t('Loading')}
            size="sm"
            variant="secondary"
            onClick={handleAddDocumentClick}
          >
            {t('AddAnnexDocument')}
          </Button>
        </form>
      </HStack>
      <Stack spacing="2">
        {!documents[operation?.transactionDocumentType] &&
          !documents[OperationDocumentType.TRANSFER_DECLARATION] &&
          !documents[OperationDocumentType.IBAN] &&
          !customFiles.length && (
            <Text color="gray.500" fontSize="sm">
              {t('NoDocumentsLinked')}
            </Text>
          )}
        {documents[operation.transactionDocumentType] && (
          <DocumentLink
            fileName={i18nDocumentTitle(operation.transactionDocumentType as IntlDocumentValues)}
            onClick={(): void => handleDocumentClick(documents[operation.transactionDocumentType])}
          />
        )}
        {documents[OperationDocumentType.TRANSFER_DECLARATION] && (
          <DocumentLink
            fileName={i18nDocumentTitle('TRANSFER_DECLARATION')}
            onClick={(): void =>
              handleDocumentClick(documents[OperationDocumentType.TRANSFER_DECLARATION])
            }
          />
        )}
        {documents[OperationDocumentType.IBAN] && (
          <DocumentLink
            fileName={t('TransactionIBAN')}
            onClick={(): void => handleDocumentClick(documents[OperationDocumentType.IBAN])}
          />
        )}
        {customFiles.map((file, index) => {
          const fileName =
            file.title === 'CUSTOM_FILE' ? `${t('Annex', { nb: 1 })} ${++index}` : file.title;
          return (
            <HStack key={file.id} justify="space-between">
              <DocumentLink
                fileName={fileName}
                onClick={(): void => handleDocumentClick(file.id)}
              />
              {file.parentDocumentType === 'SubscriptionDocument' && isUserAuthorized && (
                <IconButton
                  aria-label="delete-document"
                  icon={<Icon as={TrashIcon} boxSize="4" />}
                  isDisabled={isCustomDocumentDeletionLoading}
                  size="xs"
                  variant="icon-delete"
                  onClick={(): void => handleFileDelete(file.id)}
                />
              )}
            </HStack>
          );
        })}
      </Stack>
      <DeleteConfirmModal
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={deleteRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteDocumentQuestion')}
        onClose={deleteModal.onClose}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
