import * as yup from 'yup';

import { requiredNumberField, requiredStringField } from '@blockpulse3/data/shared';
import { AssetType } from '@blockpulse3/graphql/hooks';

import { IStockAsset } from './types';

export const stockAssetTypeOptions = [
  { label: AssetType.ORDINARY_SHARE, value: AssetType.ORDINARY_SHARE },
  { label: AssetType.PREFERRED_SHARE, value: AssetType.PREFERRED_SHARE },
];

export const schema = yup.object({
  name: requiredStringField,
  assetType: yup.object({
    label: requiredStringField,
    value: requiredStringField,
  }),
  nominalValue: requiredNumberField,
}) as yup.ObjectSchema<IStockAsset>;

export const defaultValues: IStockAsset = {
  name: '',
  assetType: stockAssetTypeOptions[0],
  nominalValue: 0,
};
