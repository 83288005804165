import dayjs from 'dayjs';
import * as yup from 'yup';

import {
  FormErrors,
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { AssetType } from '@blockpulse3/graphql/hooks';
import { defaultFiscalAdvantageOptions } from '@blockpulse3/web-client/operation/commons';

import { IPrivateFundraisingParametersForm } from './types';

export const privateSubscriptionPeriodOptions = [
  { label: '2 semaines', value: 14 },
  { label: '3 semaines', value: 21 },
  { label: '4 semaines', value: 28 },
  { label: '5 semaines', value: 35 },
  { label: '6 semaines', value: 42 },
];

export const privateFundraisingParametersSchema = yup.object({
  name: requiredStringField,
  subscriptionPeriod: yup.object({
    label: requiredStringField,
    value: requiredNumberField,
  }),
  asset: requiredSelectField,
  valuation: yup.number().when('asset', {
    is: (asset: { type: AssetType }) => asset.type === AssetType.ORDINARY_SHARE,
    then: () => requiredNumberField,
  }),
  sharePrice: yup.number().when('asset', {
    is: (asset: { type: AssetType }) => asset.type === AssetType.ORDINARY_SHARE,
    then: () => requiredNumberField,
  }),
  transactionDebitDate: yup
    .string()
    .test('valid-format', FormErrors.ValidDateFormat, (value, context) => {
      if (!context.parent.isRelatedToSecondary) return true;
      return !!value;
    })
    .test('date-passed', FormErrors.FutureDate, (value, context) => {
      if (!context.parent.isRelatedToSecondary) return true;
      const fmtDate = dayjs(value);
      return fmtDate.isAfter(dayjs());
    })
    .test('date-after-end-of-operation', FormErrors.DateAfterEndOfOperation, (value, context) => {
      if (!context.parent.isRelatedToSecondary) return true;
      const fmtDate = dayjs(value);
      // Theoretical end of operation date
      const endOfOperationDate = dayjs().add(context.parent.subscriptionPeriod.value, 'days');
      return fmtDate.isBefore(endOfOperationDate);
    }),
}) as yup.ObjectSchema<IPrivateFundraisingParametersForm>;

export const privateParametersDefaultValues: IPrivateFundraisingParametersForm = {
  name: '',
  subscriptionPeriod: privateSubscriptionPeriodOptions[0],
  valuation: 0,
  sharePrice: 0,
  asset: {
    label: 'default',
    value: AssetType.ORDINARY_SHARE,
    type: AssetType.ORDINARY_SHARE,
  },
  allowedFiscalAdvantages: defaultFiscalAdvantageOptions,
  isRelatedToSecondary: false,
  transactionDebitDate: '',
};
