import {
  Button,
  Card,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  HoldingMethod,
  OperationStatus,
  useCorrectSubscriptionHoldingMethodMutation,
  useCreateWalletByManagerMutation,
  useGetSubscriptionQuery,
  useGetWalletsQuery,
  useUpdateWalletByManagerMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  HoldingMethodSelect,
  IWalletForm,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  WalletFormModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  subscriptionId?: string;
  isSeller?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

export function ChangeHoldingMethodModal({
  subscriptionId: subscriptionIdProp = '',
  isSeller = false,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { onClose } = props;

  const { subscriptionId = subscriptionIdProp } = useParams();

  const [selectedHoldingMethod, setSelectedHoldingMethod] = useState<HoldingMethod>();
  const [currentHoldingMethod, setCurrentHoldingMethod] = useState<HoldingMethod>();
  const [walletPartial, setWalletPartial] = useState<Partial<IWalletForm> | undefined>();

  const createFormModal = useDisclosure();
  const updateFormModal = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [correctSubscriptionHoldingMethod] = useCorrectSubscriptionHoldingMethodMutation();
  const [createWalletByManager] = useCreateWalletByManagerMutation();
  const [updateWalletByManager] = useUpdateWalletByManagerMutation();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const { subscription } = subscriptionReq.data || {};

  const allowedFiscalAdvantages = subscription?.operation?.allowedFiscalAdvantages || [];
  const subscriptionIdentity = isSeller
    ? subscription?.sellerIdentity
    : subscription?.buyerIdentity;
  const identityId = subscriptionIdentity?.id || '';

  const walletsReq = useGetWalletsQuery({
    variables: { identityId },
    skip: !identityId,
  });

  if (subscriptionReq.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (subscriptionReq.error) {
    return <ErrorQueryCard />;
  }

  if (!subscriptionReq.data || !subscription) {
    return <ErrorQueryCard />;
  }

  const isFundraisingFinalized = subscription?.operation.status === OperationStatus.FINALIZED;

  const handleWalletCreateModalOpening = (holdingMethod: HoldingMethod): void => {
    setWalletPartial({ holdingMethod });
    createFormModal.onOpen();
  };

  const handleWalletUpdateModalOpening = (wallet: IWalletForm): void => {
    setWalletPartial(wallet);
    updateFormModal.onOpen();
  };

  const handleHoldingMethodChange = (holdingMethod: HoldingMethod): void => {
    if (!holdingMethod) return;
    setSelectedHoldingMethod(holdingMethod);
  };

  const handleSubmit = (): void => {
    const hasMethodChanged =
      JSON.stringify(selectedHoldingMethod) !== JSON.stringify(currentHoldingMethod);

    if (hasMethodChanged && selectedHoldingMethod) {
      correctSubscriptionHoldingMethod({
        variables: {
          updateSubscriptionHoldingMethodInput: {
            subscriptionId,
            holdingMethod: selectedHoldingMethod,
            companyId: subscription.operation.company.id,
          },
        },
        onCompleted: () => {
          successToast({ title: t('HoldingMethodUpdated') });
          setCurrentHoldingMethod(selectedHoldingMethod);
          subscriptionReq.refetch();
          walletsReq.refetch();
          onClose();
        },
        onError: () => {
          errorToast({ title: t('ErrorRecordingOwnershipMode') });
        },
      });
    } else {
      onClose();
    }
  };

  const handleWalletCreate = async (data: IWalletForm): Promise<void> => {
    if (identityId) {
      createWalletByManager({
        variables: {
          createWalletInput: {
            identityId,
            bankAccountNumber: data.bankAccountNumber,
            holdingMethod: data.holdingMethod,
            bankName: data.bankName,
            bankCode: data.bankCode,
            bankEmail: data.bankEmail || null,
            companyId: subscription.operation.company.id,
            operationId: subscription.operation.id,
          },
        },
        onCompleted: (data) => {
          const wallet = data.createWalletByManager;
          walletsReq.refetch();
          setSelectedHoldingMethod(wallet.holdingMethod);
          createFormModal.onClose();
        },
        onError: () => {
          createFormModal.onClose();
          errorToast({ title: t('ErrorAddingOwnershipMode') });
          onClose();
        },
      });
    }
    createFormModal.onClose();
  };

  const handleWalletUpdate = async (data: IWalletForm): Promise<void> => {
    if (identityId && data.id) {
      updateWalletByManager({
        variables: {
          updateWalletInput: {
            identityId,
            walletId: data.id,
            bankAccountNumber: data.bankAccountNumber,
            bankName: data.bankName,
            bankCode: data.bankCode,
            bankEmail: data.bankEmail || null,
            companyId: subscription.operation.company.id,
            operationId: subscription.operation.id,
          },
        },
        onCompleted: () => {
          successToast({ title: t('HoldingMethodRecorded') });
          walletsReq.refetch();
          updateFormModal.onClose();
        },
        onError: () => {
          errorToast({ title: t('ErrorRecordingOwnershipMode') });
        },
      });
    }
  };

  return (
    <>
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('UpdateHoldingMethod')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="500">
            <HoldingMethodSelect
              allowedFiscalAdvantages={allowedFiscalAdvantages}
              defaultValue={subscription?.holdingMethod}
              identityId={identityId}
              isDisabled={isFundraisingFinalized}
              onHoldingMethodChange={handleHoldingMethodChange}
              onWalletCreate={handleWalletCreateModalOpening}
              onWalletUpdate={handleWalletUpdateModalOpening}
            />
          </ModalBody>
          <Divider />
          <ResponsiveModalFooter>
            <Button onClick={handleSubmit}>{t('Validate')}</Button>
          </ResponsiveModalFooter>
        </ModalContent>
      </ResponsiveModal>
      {createFormModal.isOpen && (
        <WalletFormModal
          formPartialValues={walletPartial}
          isOpen={createFormModal.isOpen}
          onClose={createFormModal.onClose}
          onSubmit={handleWalletCreate}
        />
      )}
      {updateFormModal.isOpen && (
        <WalletFormModal
          formPartialValues={walletPartial}
          isOpen={updateFormModal.isOpen}
          onClose={updateFormModal.onClose}
          onSubmit={handleWalletUpdate}
        />
      )}
    </>
  );
}
