import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  Divider,
  HStack,
} from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

type Props = {
  title: string;
  subtitle?: string | JSX.Element;
  onConfirm: () => void;
  onClose: () => void;
  labelConfirmButton?: string;
  leastDestructiveRef: AlertDialogProps['leastDestructiveRef'];
  isLoading?: boolean;
} & Omit<AlertDialogProps, 'onClose' | 'children' | 'leastDestructiveRef'>;

export function ConfirmModal({
  title,
  subtitle,
  onConfirm,
  onClose,
  labelConfirmButton,
  isLoading,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  return (
    <AlertDialog onClose={onClose} {...props}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" fontWeight="500">
            {title}
          </AlertDialogHeader>
          {subtitle && <AlertDialogBody>{subtitle}</AlertDialogBody>}
          <Divider />
          <AlertDialogFooter as={HStack} spacing="4">
            <Button variant="secondary" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button isLoading={isLoading} onClick={onConfirm}>
              {labelConfirmButton ? labelConfirmButton : t('Validate')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export type ConfirmModal = Props;
