import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { ClockIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  GetSubscriptionsDocument,
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
  SubscriptionShortInfosFragment,
  useStartSecondaryOperationMutation,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { PAGE_SIZE } from '../SecondaryOperationTransactions';

export function SecondaryOperationStartAlert(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const paginationProps = usePagination<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables,
    SubscriptionShortInfosFragment
  >({
    queryDocument: GetSubscriptionsDocument,
    queryOptions: {
      variables: {
        operationId,
        first: PAGE_SIZE,
        filterBy: [],
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'subscriptions',
    pageSize: PAGE_SIZE,
  });
  const { refetch } = paginationProps;

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [startSecondary, { loading: starting }] = useStartSecondaryOperationMutation();

  const handleStartSecondaryOperation = (): void => {
    if (!operationId) return;

    startSecondary({
      variables: { operationId },
      onCompleted: () => {
        successToast({ title: t('StartSecondaryOperationSuccessToast') });
        refetch();
      },
      onError: () => {
        errorToast({ title: t('StartOperationErrorToast') });
      },
    });
  };

  return (
    <Alert boxShadow="md" status="warning">
      <Stack
        alignItems="stretch"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
        width="full"
      >
        <HStack alignItems="flex-start" spacing="0">
          <AlertIcon as={ClockIcon} />
          <Stack spacing="0">
            <AlertTitle fontWeight="bold">{t('MarketReadyForOpening')}</AlertTitle>
            <AlertDescription>{t('OperationToStartDescription')}</AlertDescription>
          </Stack>
        </HStack>
        <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
          <Button
            className="confirm"
            fontSize="sm"
            isDisabled={starting}
            isLoading={starting}
            onClick={handleStartSecondaryOperation}
          >
            {t('StartOperation')}
          </Button>
        </HStack>
      </Stack>
    </Alert>
  );
}
