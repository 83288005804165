import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  Heading,
  Icon,
  Link,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlCSVParseError } from '@blockpulse3/data/locales/types';
import { CsvCellError, CsvError, Exceptions, FormErrors, routes } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  IdentityType,
  useDeleteCompanyDocumentMutation,
  useGetCompanyDocumentCsvDataQuery,
  useGetCompanyDocumentQuery,
} from '@blockpulse3/graphql/hooks';
import { CsvDataTable, DropzoneInput, ErrorMessage, ErrorQueryCard } from '@blockpulse3/ui/commons';

import { shareTransferRegisterIdentitiesSchema } from './schema';
import { RepatriationShareTransferRegisterIdentitiesForm } from './types';

type Props = unknown;

export function RepatriationShareTransferRegisterIdentities(): JSX.Element {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');

  const [uploading, setUploading] = useState<IdentityType | null>(null);

  const { control, formState, setValue, setError, clearErrors, handleSubmit, getValues } =
    useForm<RepatriationShareTransferRegisterIdentitiesForm>({
      defaultValues: { individualIdentitiesFile: '', companyIdentitiesFile: '' },
      resolver: yupResolver(shareTransferRegisterIdentitiesSchema),
    });

  const [csvIdentityData, setCsvIdentityData] = useState<Record<string, string>[]>([]);
  const [csvIdentityErrors, setCsvIdentityErrors] = useState<CsvCellError[]>([]);

  const [csvCompanyData, setCsvCompanyData] = useState<Record<string, string>[]>([]);
  const [csvCompanyErrors, setCsvCompanyErrors] = useState<CsvCellError[]>([]);

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const { data: csvIdentitiesData, refetch: refetchCsvIdentities } =
    useGetCompanyDocumentCsvDataQuery({
      variables: {
        companyId,
        documentType: CompanyDocumentType.INDIVIDUAL_IDENTITIES_IMPORT,
      },
      skip: !companyId,
      fetchPolicy: 'network-only',
    });

  const { data: csvCompanyIdentitiesData, refetch: refetchCompanyCsvIdentities } =
    useGetCompanyDocumentCsvDataQuery({
      variables: {
        companyId,
        documentType: CompanyDocumentType.COMPANY_IDENTITIES_IMPORT,
      },
      skip: !companyId,
      fetchPolicy: 'network-only',
    });

  const {
    data: indivData,
    loading: indivLoading,
    error: IndivError,
    refetch: refetchIndiv,
  } = useGetCompanyDocumentQuery({
    variables: {
      companyId,
      documentType: CompanyDocumentType.INDIVIDUAL_IDENTITIES_IMPORT,
    },
    onCompleted: (data) => {
      setValue('individualIdentitiesFile', data?.getCompanyDocument?.document.title);
    },
  });

  const {
    data: compData,
    loading: compLoading,
    error: compError,
    refetch: refetchComp,
  } = useGetCompanyDocumentQuery({
    variables: {
      companyId,
      documentType: CompanyDocumentType.COMPANY_IDENTITIES_IMPORT,
    },
    onCompleted: (data) => {
      setValue('companyIdentitiesFile', data?.getCompanyDocument?.document.title);
    },
  });
  const [deleteCompanyDocument] = useDeleteCompanyDocumentMutation();

  useEffect(() => {
    if (getValues('individualIdentitiesFile')) {
      setCsvIdentityData(csvIdentitiesData?.getCompanyDocumentCsvData || []);
    }

    if (getValues('companyIdentitiesFile')) {
      setCsvCompanyData(csvCompanyIdentitiesData?.getCompanyDocumentCsvData || []);
    }
  }, [csvIdentitiesData, csvCompanyIdentitiesData, getValues]);

  if (indivLoading || compLoading) {
    return (
      <Skeleton width="full">
        <Card height="400px" width="full" />
      </Skeleton>
    );
  }

  if (IndivError || compError) {
    return <ErrorQueryCard height="400px" width="full" />;
  }

  const indivDocument = indivData?.getCompanyDocument?.document;
  const indivFileName = indivDocument
    ? { [indivDocument.id]: new File([indivDocument.title], indivDocument.title) }
    : {};

  const compDocument = compData?.getCompanyDocument?.document;
  const compFileName = compDocument
    ? { [compDocument.id]: new File([compDocument.title], compDocument.title) }
    : {};

  const handleFileDrop = async (
    identityType: IdentityType,
    acceptedFiles: File[],
  ): Promise<void> => {
    clearErrors(
      identityType === IdentityType.COMPANY ? 'companyIdentitiesFile' : 'individualIdentitiesFile',
    );

    const formData = new FormData();
    formData.append('identities', acceptedFiles[0]);
    formData.append('companyId', companyId);
    formData.append('identityType', identityType);
    const isCompany = identityType === IdentityType.COMPANY;

    setUploading(identityType);
    setValue(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', undefined);
    isCompany ? setCsvCompanyData([]) : setCsvIdentityData([]);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/identities/uploadIdentitiesDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        setUploading(null);
        setValue(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', 'file.csv');
        isCompany ? setCsvCompanyErrors([]) : setCsvIdentityErrors([]);
        isCompany ? refetchComp() : refetchIndiv();
        isCompany ? refetchCompanyCsvIdentities() : refetchCsvIdentities();
      })
      .catch((err: unknown) => {
        if (axios.isAxiosError(err)) {
          const error = err?.response?.data as CsvError;
          const errorMessage = error?.message ?? error.message;
          const errorCsvData = error?.data;
          const errorCsvCells = error?.cellErrors;

          isCompany ? setCsvCompanyData(errorCsvData) : setCsvIdentityData(errorCsvData);
          isCompany ? setCsvCompanyErrors(errorCsvCells) : setCsvIdentityErrors(errorCsvCells);

          if (!error.code && errorMessage === Exceptions.MissingRequiredProperties) {
            setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
              type: 'custom',
              message: t('SomeRequiredInfoMissing'),
            });
          } else if (!error.code && errorMessage === Exceptions.IndividualIdentitiesCSVNotFound) {
            setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
              type: 'custom',
              message: t('IndividualIdentitiesCSVNotFound'),
            });
          } else {
            const code = error.code || 'DefaultError';
            setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
              type: 'custom',
              message: i18nCSVParseError(code as IntlCSVParseError),
            });
          }
        } else {
          setError(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', {
            type: 'custom',
            message: FormErrors.DropzoneInvalidTemplate,
          });
        }
        setUploading(null);
      });
  };

  const handleFileDelete = (identityType: IdentityType): void => {
    const isCompany = identityType === IdentityType.COMPANY;
    const documentType = isCompany
      ? CompanyDocumentType.COMPANY_IDENTITIES_IMPORT
      : CompanyDocumentType.INDIVIDUAL_IDENTITIES_IMPORT;
    deleteCompanyDocument({
      variables: {
        deleteCompanyDocumentInput: {
          companyId,
          documentType,
        },
      },
      onCompleted: () => {
        setValue(isCompany ? 'companyIdentitiesFile' : 'individualIdentitiesFile', undefined);
        isCompany ? setCsvCompanyData([]) : setCsvIdentityData([]);
        isCompany ? refetchComp() : refetchIndiv();
        isCompany ? refetchCompanyCsvIdentities() : refetchCsvIdentities();
      },
    });
  };

  const handleStepCancel = (): void => {
    navigate('../' + routes.company.repatriation.assets.href);
  };

  const handleFormSubmit: SubmitHandler<RepatriationShareTransferRegisterIdentitiesForm> = (
    data,
  ): void => {
    navigate('../' + routes.company.repatriation.import.href);
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('IdentitiesImport')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <form id="upload-identities-files" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            <Stack>
              <Text fontWeight={500}>{t('IndividualIdentitiesLabel')}</Text>
              <Alert status="info">
                <AlertIcon />
                <AlertTitle>
                  <Link href="/assets/individual_identities_template.csv">
                    {t('DownloadExampleArrow')}
                  </Link>
                </AlertTitle>
              </Alert>
              <Controller
                control={control}
                name="individualIdentitiesFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <DropzoneInput
                      accept={{ 'text/csv': [] }}
                      files={indivFileName}
                      isLoading={indivLoading || uploading === IdentityType.INDIVIDUAL}
                      maxFiles={1}
                      subTitle=".CSV"
                      icon={
                        <Icon
                          as={DocumentTextIcon}
                          boxSize="42px"
                          color="gray.400"
                          strokeWidth="1px"
                        />
                      }
                      onDelete={(): void => handleFileDelete(IdentityType.INDIVIDUAL)}
                      onDrop={(files: File[]): Promise<void> =>
                        handleFileDrop(IdentityType.INDIVIDUAL, files)
                      }
                    />
                    <ErrorMessage error={formState.errors?.individualIdentitiesFile} />
                  </FormControl>
                )}
              />
              <CsvDataTable cellErrors={csvIdentityErrors} csvData={csvIdentityData} />
            </Stack>
            <Stack>
              <Text fontWeight={500}>{t('CompanyIdentitiesLabel')}</Text>
              <Alert status="info">
                <AlertIcon />
                <AlertTitle>
                  <Link href="/assets/company_identities_template.csv">
                    {t('DownloadExampleArrow')}
                  </Link>
                </AlertTitle>
              </Alert>
              <Controller
                control={control}
                name="companyIdentitiesFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <DropzoneInput
                      accept={{ 'text/csv': [] }}
                      files={compFileName}
                      isLoading={compLoading || uploading === IdentityType.COMPANY}
                      maxFiles={1}
                      subTitle=".CSV"
                      icon={
                        <Icon
                          as={DocumentTextIcon}
                          boxSize="42px"
                          color="gray.400"
                          strokeWidth="1px"
                        />
                      }
                      onDelete={(): void => handleFileDelete(IdentityType.COMPANY)}
                      onDrop={(files: File[]): Promise<void> =>
                        handleFileDrop(IdentityType.COMPANY, files)
                      }
                    />
                    <ErrorMessage error={formState.errors?.companyIdentitiesFile} />
                  </FormControl>
                )}
              />
              <CsvDataTable cellErrors={csvCompanyErrors} csvData={csvCompanyData} />
            </Stack>
          </Stack>
        </form>
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button form="upload-identities-files" type="submit" w="full">
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type RepatriationShareTransferRegisterIdentitiesProps = Props;
