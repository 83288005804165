import { Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { ReceiptTaxIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { useGetOptionAssetsQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { OptionTable } from './OptionTable';

type Props = {
  /* ** Callback on row click ** */
  onClick?: (assetId: string) => void;
};

export function OptionTableCurrent({ onClick = noop }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId,
  });

  if (loading) {
    return (
      <Stack h="300px" layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (error) {
    return <ErrorQueryCard h="300px" />;
  }

  const handleTableClick = (assetId: string): void => {
    onClick(assetId);
  };

  const currentOptions = data?.getOptionAssets || [];
  const isEmpty = currentOptions.length === 0;

  return isEmpty ? (
    <Stack layerStyle="emptyState">
      <Icon as={ReceiptTaxIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoPlan')}</Text>
    </Stack>
  ) : (
    <OptionTable options={currentOptions} onClick={handleTableClick} />
  );
}

export type OptionTableCurrentProps = Props;
