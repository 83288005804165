import {
  Badge,
  Button,
  Card,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  ArrowNarrowRightIcon,
  CashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import {
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlBankDistributionType } from '@blockpulse3/data/locales/types';
import { routes } from '@blockpulse3/data/shared';
import { BankDistributionInfosFragment, BankDistributionStatus } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { PaginationButtons, TableContainer } from '@blockpulse3/ui/commons';
import { PaginationHandlers, useBadge } from '@blockpulse3/ui/ui-hooks';

dayjs.extend(LocalizedFormat);

type Props = PaginationHandlers<BankDistributionInfosFragment> & {
  onRowClick?: (id: string) => void;
  sorting?: SortingState;
  onSortingChange: (sortFn: Updater<SortingState>) => void;
};

/**
 * DistributionTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function DistributionTable({
  results,
  pageInfo,
  pageCount,
  currentPage,
  isLoadingMore,
  loading,
  handleNextPage,
  handlePreviousPage,
  onSortingChange,
  sorting,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nBankDistributionType = useTranslations('BankDistributionType');

  const navigate = useNavigate();

  const { getBadge } = useBadge(
    null,
    [
      { value: BankDistributionStatus.WAITING, label: t('Pending'), color: 'yellow' },
      { value: BankDistributionStatus.PROCESSING, label: t('InProgress'), color: 'blue' },
      { value: BankDistributionStatus.COMPLETED, label: t('ExecutedF'), color: 'green' },
    ],
    { label: t('Pending'), color: 'yellow' },
  );

  const columnHelper = useMemo(() => createColumnHelper<BankDistributionInfosFragment>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('Name'),
        cell: ({ row }) => {
          return (
            <Td fontWeight="600">
              <VStack alignItems="start" spacing="1">
                <Text fontWeight="600">{row.original.name}</Text>
                <Text color="gray.500" fontWeight="400">
                  {i18nBankDistributionType(row.original.type as IntlBankDistributionType)}
                </Text>
              </VStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('totalTransferedAmount', {
        header: t('Amount', { nb: 1 }),
        cell: (info) => {
          const amount = info.getValue();
          return (
            <Td isNumeric fontWeight="600">
              {amount === 0 ? '-' : formatNumberCurrency(amount)}
            </Td>
          );
        },
      }),
      columnHelper.accessor('totalTransferCount', {
        header: t('CountOfTransfers'),
        cell: (info) => (
          <Td fontWeight="600">
            <HStack spacing="2.5">
              <Icon as={CashIcon} boxSize="24px" />
              <Text>{info.getValue()}</Text>
            </HStack>
          </Td>
        ),
      }),
      columnHelper.accessor('status', {
        header: t('Status'),
        cell: (info) => {
          const statusBadge = getBadge(info.getValue());
          return (
            <Td>
              <Badge colorScheme={statusBadge.color}>{statusBadge.label}</Badge>
            </Td>
          );
        },
      }),
      columnHelper.accessor('id', {
        header: '',
        enableSorting: false,
        cell: (info) => (
          <Td key={info.cell.id} align="right" textAlign="right">
            <Button
              rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
              size="sm"
              variant="secondary"
            >
              {t('ShowDetails')}
            </Button>
          </Td>
        ),
      }),
    ],
    [t, columnHelper],
  );
  const numericColumns = ['totalTransferedAmount', 'admissionFees'];

  const table = useReactTable({
    data: results,
    columns,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (sortFn) => {
      onSortingChange(sortFn);
    },
    enableSortingRemoval: false,
  });

  const handleRowClick = (distributionId: string): void => {
    navigate('../' + generatePath(routes.distribution.href, { distributionId }));
  };

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  return (
    <Stack spacing="4">
      <TableContainer maxHeight="none">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <Tr key={headerGroup.id + index}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                    isNumeric={numericColumns.includes(header.id)}
                    w={header.id === 'name' ? '40%' : '15%'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.column.getIsSorted() ? (
                      <HStack spacing="0">
                        <Text>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Text>
                        <Icon
                          boxSize="16px"
                          as={
                            header.column.getIsSorted() === 'asc' ? ChevronUpIcon : ChevronDownIcon
                          }
                        />
                      </HStack>
                    ) : (
                      <Text>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                    )}
                  </Th>
                ))}
                {/* <Th /> DETAILS */}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoadingMore ? (
              <Tr key="0">
                <Td colSpan={6}>
                  <Stack alignItems="center" h="738px" justifyContent="center">
                    <Spinner />
                  </Stack>
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row, index) => (
                <Tr
                  key={row.original.id + index}
                  role="button"
                  onClick={(): void => handleRowClick(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) =>
                    flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      key: cell.id,
                    }),
                  )}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <PaginationButtons
          currentPage={currentPage}
          hasNextPage={pageInfo?.hasNextPage}
          hasPreviousPage={pageInfo?.hasPreviousPage}
          loading={isLoadingMore}
          pageCount={pageCount}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
        />
      )}
    </Stack>
  );
}

export type DistributionTableProps = Props;
