import { AssetEntityInfosFragment } from '@blockpulse3/graphql/hooks';

import { IStockAssetRow } from './types';

/**
 * getStockAssetRows.
 *
 * @param {AssetEntityInfosFragment[]} data
 * @returns {IStockAsset[]}
 */
export function getStockAssetRows(data?: AssetEntityInfosFragment[]): IStockAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.id,
    name: value.name,
    assetType: {
      label: value.assetType,
      value: value.assetType,
    },
    nominalValue: value.nominalValue || 0,
    data: value,
  }));
}

/**
 * getOptionAssetRows.
 *
 * @param {AssetEntityInfosFragment[]} data
 * @returns {IStockAssetRow[]}
 */
export function getOptionAssetRows(data?: AssetEntityInfosFragment[]): IStockAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.id,
    name: value.name,
    assetType: {
      label: value.assetType,
      value: value.assetType,
    },
    nominalValue: value.data?.__typename === 'OptionAssetData' ? value.data.exercisePrice : 0,
    data: value,
  }));
}
