import * as yup from 'yup';

import { HoldingMethod, requiredStringField } from '@blockpulse3/data/shared';

import { IWalletForm } from './types';

export const walletSchema = yup.object({
  holdingMethod: requiredStringField,
  bankAccountNumber: requiredStringField,
  bankName: requiredStringField,
  bankCode: requiredStringField,
  bankEmail: yup.string().email().notRequired(),
}) as yup.ObjectSchema<IWalletForm>;

export const walletDefaults: IWalletForm = {
  holdingMethod: HoldingMethod.PEA,
  bankAccountNumber: '',
  bankName: '',
  bankCode: '',
  bankEmail: '',
};
