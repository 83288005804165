import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  IdentityVerificationStatus,
  OperationStatus,
  useGetBankAccountStatusByCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';

import { SecondaryOperationBankAccountAlert } from './SecondaryOperationBankAccountAlert';
import { SecondaryOperationStartAlert } from './SecondaryOperationStartAlert';

type Props = unknown;

/**
 * SecondaryOperationStatusAlert.
 *
 * @returns {JSX.Element}
 */
export function SecondaryOperationStatusAlert(): JSX.Element | null {
  const t = useTranslations();

  const { operationId = '', companyId = '' } = useParams();

  const operationReq = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = operationReq.data?.operation;

  const bankAccountStatusReq = useGetBankAccountStatusByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || !operation.usePivotFiscalAccount,
  });
  const bankAccountStatus =
    bankAccountStatusReq.data?.getBankAccountStatusByCompany || IdentityVerificationStatus.NONE;

  if (!operation || operationReq.loading || bankAccountStatusReq.loading) return null;

  const isStartDatePassed = dayjs().isAfter(operation.startSubscriptionDate);
  const cannotBeStarted = !isStartDatePassed && operation.status === OperationStatus.DRAFT;
  const shouldCreateBankAccount =
    operation.usePivotFiscalAccount && bankAccountStatus !== IdentityVerificationStatus.APPROVED;
  const canBeStarted = isStartDatePassed && operation.status === OperationStatus.DRAFT;

  if (cannotBeStarted) {
    return (
      <Alert alignItems="center" status="warning">
        <AlertIcon boxSize="16px" />
        <AlertTitle>{t('MarketOpeningDateIsNotReached')}</AlertTitle>
      </Alert>
    );
  }

  if (shouldCreateBankAccount) {
    return <SecondaryOperationBankAccountAlert />;
  }

  if (canBeStarted) {
    return <SecondaryOperationStartAlert />;
  }

  return null;
}

export type SecondaryOperationStatusAlertProps = Props;
