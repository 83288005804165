import * as yup from 'yup';

import {
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
} from '@blockpulse3/data/shared';

import { ICompleteOperationForm } from './type';

export const completeOperationFormDefaults: ICompleteOperationForm = {
  name: '',
  type: { label: '', value: '' },
  asset: { label: '', value: '' },
  sharePrice: 0,
  closingDate: '',
};

export const completeOperationFormSchema = yup.object({
  name: requiredStringField,
  type: requiredSelectField,
  asset: requiredSelectField,
  sharePrice: requiredNumberField,
  closingDate: requiredStringField,
}) as yup.ObjectSchema<ICompleteOperationForm>;
