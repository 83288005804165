export enum OperationDocumentType {
  PV_EXERCICE = 'PV_EXERCICE',
  PV_EXERCICE_BOND = 'PV_EXERCICE_BOND',
  PV_CONSTATATION = 'PV_CONSTATATION',
  PV_CONSTATATION_BOND = 'PV_CONSTATATION_BOND',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUBSCRIPTION_BOND = 'SUBSCRIPTION_BOND',
  IBAN = 'IBAN',
  SHAREHOLDER_AGREEMENT = 'SHAREHOLDER_AGREEMENT',
  OWNERSHIP_CERTIFICATE = 'OWNERSHIP_CERTIFICATE',
  PEA_PME_CERTIFICATE = 'PEA_PME_CERTIFICATE',
  PEA_CERTIFICATE = 'PEA_CERTIFICATE',
  IR_PME_CERTIFICATE = 'IR_PME_CERTIFICATE',
  CUSTOM_FILE = 'CUSTOM_FILE',
  FUNDS_SOURCE_CERTIFICATE = 'FUNDS_SOURCE_CERTIFICATE',
  MIFID_QUESTIONS = 'MIFID_QUESTIONS',
  RMT_EXTRACT = 'RMT_EXTRACT',
  DEPOSIT_CERTIFICATE = 'DEPOSIT_CERTIFICATE',
  TRANSFER_DECLARATION = 'TRANSFER_DECLARATION',
  ODM = 'ODM',
  ODM_REGISTRATION = 'ODM_REGISTRATION',
  MIFID_REPORT = 'MIFID_REPORT',
  TERM_SHEET = 'TERM_SHEET',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_REGISTRATION = 'WITHDRAWAL_REGISTRATION',
}
