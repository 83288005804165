import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetAssetQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { OptionSummarySubscribersTable } from './OptionSummarySubscribersTable';
import { OptionSummarySubscribersTableEmpty } from './OptionSummarySusbcribersTableEmpty';

/* ** Temp ** */
type Props = unknown;

export function OptionSummarySubscribers(): JSX.Element | null {
  const t = useTranslations();

  const { assetId = '', companyId = '' } = useParams();

  const { data, loading, error } = useGetAssetQuery({
    variables: {
      assetId,
      companyId,
    },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="200px" />;
  }

  const option = data.asset;
  if (!option || option.token?.__typename !== 'OptionTokenModel') return null;

  const { grants } = option.token;
  const isEmpty = grants?.length === 0;

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('AttributionList')}</Heading>
        <Button isDisabled leftIcon={<Icon as={PlusIcon} boxSize="18px" />}>
          {t('Assign')}
        </Button>
      </CardHeader>
      <CardBody>
        {isEmpty ? (
          <OptionSummarySubscribersTableEmpty />
        ) : (
          <OptionSummarySubscribersTable values={grants || []} />
        )}
      </CardBody>
    </Card>
  );
}

export type OptionSummarySubscribersProps = Props;
