import { Alert, AlertIcon, AlertTitle, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyStatus,
  CompanyType,
  OperationStatus,
  useGetOperationByOpportunityIdQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { SPVMatriculationStatusAlert } from '@blockpulse3/ui/commons';

import { OpportunityOperationCloseAlert } from './OpportunityOperationCloseAlert';
import { OpportunityOperationFinalizeAlert } from './OpportunityOperationFinalizeAlert';
import { OpportunityOperationStartAlert } from './OpportunityOperationStartAlert';

/**
 * OpportunityOperationStatusAlert.
 *
 * @returns {JSX.Element}
 */
export function OpportunityOperationStatusAlert(): JSX.Element | null {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });

  const { loading: childOperationLoading } = useGetOperationByOpportunityIdQuery({
    variables: { opportunityId: operationId },
    skip: !operationId,
  });

  if (!data || loading || childOperationLoading) return null;

  const { operation } = data;
  if (operation.data?.__typename !== 'OpportunityData') {
    return null;
  }
  const { totalApprovedSubscriptions } = operation.data;

  const isStartDatePassed = dayjs().isAfter(operation.startSubscriptionDate);
  const isClosedOrFinalized = [OperationStatus.CLOSED, OperationStatus.FINALIZED].includes(
    operation.status,
  );

  const isCompanyRunning = operation.company.status === CompanyStatus.RUNNING;
  const isSpvDraft = operation.company.type === CompanyType.SPV && !isCompanyRunning;
  const isCompanyTokenized = operation.company.type !== CompanyType.COMPANY;

  const cannotBeStarted = !isStartDatePassed && operation.status === OperationStatus.DRAFT;
  const canBeStarted = isStartDatePassed && operation.status === OperationStatus.DRAFT;
  const canBeClosed = operation.status === OperationStatus.STARTED;
  const canBeFinalized =
    isCompanyRunning &&
    isCompanyTokenized &&
    operation.status === OperationStatus.CLOSED &&
    totalApprovedSubscriptions > 0;

  if (cannotBeStarted) {
    return (
      <Alert alignItems="center" status="warning">
        <AlertIcon boxSize="16px" />
        <AlertTitle>{t('IntentOpeningDateIsNotReached')}</AlertTitle>
      </Alert>
    );
  }

  if (canBeStarted) {
    return <OpportunityOperationStartAlert />;
  }

  if (canBeClosed) {
    return <OpportunityOperationCloseAlert />;
  }

  if (canBeFinalized) {
    return <OpportunityOperationFinalizeAlert />;
  }

  if (isClosedOrFinalized && isCompanyRunning && !isCompanyTokenized) {
    return (
      <Alert status="info">
        <AlertIcon />
        <Stack spacing="0">
          <AlertTitle>{t('MustRepatriateCompany')}</AlertTitle>
        </Stack>
      </Alert>
    );
  }

  if (isClosedOrFinalized && isSpvDraft) {
    return (
      <SPVMatriculationStatusAlert
        companyId={operation.company.id}
        spvStatus={operation.company.spvStatus}
      />
    );
  }

  return null;
}
