import { Skeleton } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  SecondaryMarketType,
  useGetOperationQuery,
  useUpdateSecondaryOperationMutation,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';

import { NewSecondaryOperationInformations } from './NewSecondaryOperationInformations';
import { ISecondaryOperationInformationsForm } from './types';
import { getOperationParametersDefaults } from './utils';

type Props = unknown;

/**
 * NewSecondaryOperationParameters.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationParameters(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { operationId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });

  const [updateSecondaryOperation, { loading: isUpdateLoading }] =
    useUpdateSecondaryOperationMutation();

  if (loading) {
    return <Skeleton h="400px" width="full" />;
  }

  if (!data || error) {
    return <ErrorQueryCard h="400px" width="full" />;
  }

  const handleFormSubmit = (data: ISecondaryOperationInformationsForm): void => {
    updateSecondaryOperation({
      variables: {
        updateSecondaryOperationInput: {
          operationId,
          startSubscriptionDate: data.startSubscriptionDate,
          endSubscriptionDate: data.endSubscriptionDate,
          name: data.name,
          sharePrice: data.sharePrice,
          isPromiseToSell: data.isPromiseToSell,
          assetId: data.asset.value,
          usePivotFiscalAccount: data.usePivotFiscalAccount,
          secondaryMarketType: data.secondaryMarketType as SecondaryMarketType,
        },
      },
      onCompleted: () => {
        navigate(routes.company.newSecondary.edit.sellers.href);
        refetch();
      },
      onError: () => {
        errorToast({ title: t('OperationUpdateError') });
      },
    });
  };

  const { operation } = data;
  const defaultValues = getOperationParametersDefaults(operation);
  const isRelatedToOpportunity = !!operation.opportunityId;

  return (
    <NewSecondaryOperationInformations
      defaultValues={defaultValues}
      isLoading={isUpdateLoading}
      isRelatedToOpportunity={isRelatedToOpportunity}
      onSubmit={handleFormSubmit}
    />
  );
}

export type NewSecondaryOperationParametersProps = Props;
