import { Badge, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  AssetType,
  MergedPurchaseIntentStep,
  SubscriptionStepType,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { AssetBadge } from '@blockpulse3/ui/commons';
import { useInvestStatusBadge } from '@blockpulse3/ui/ui-hooks';

/**
 * IntentPanelOperation.
 * Operation details section of the intent side panel.
 *
 * @returns {JSX.Element}
 */
export function IntentPanelOperation(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const investStatus = useInvestStatusBadge();

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('PurchaseIntent')}
        </Text>
        <Skeleton h="40px" />
      </Stack>
    );
  }

  const subscription = data.subscription;
  const operation = subscription.operation;

  const amount = subscription.investAmount ? formatNumberCurrency(subscription.investAmount) : '-';
  const investStatusBadge = investStatus(subscription, operation.status);
  const assetType = operation.asset?.assetType;
  const shareCount = subscription.investAmount / (operation.sharePrice || 1);

  const subscriptionStep = subscription.data.mergedSteps.find(
    (step) => step.type === SubscriptionStepType.PURCHASE_INTENT,
  ) as MergedPurchaseIntentStep;
  const min = subscription?.minimalAmount || subscriptionStep?.minimalAmount;
  const max = subscription?.maximalAmount || subscriptionStep?.maximalAmount;

  const pricePerAsset = operation?.sharePrice || 1;
  const pricePerAssetLabel =
    assetType === AssetType.BOND
      ? t('NbPricePerBond', { price: pricePerAsset ? formatNumberCurrency(pricePerAsset) : '-' })
      : t('NbPricePerShare', { price: pricePerAsset ? formatNumberCurrency(pricePerAsset) : '-' });

  return (
    <Stack px="4" spacing="2">
      <HStack spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('PurchaseIntent')}
        </Text>
        <Badge colorScheme={investStatusBadge.color} fontSize="xs">
          {investStatusBadge.label}
        </Badge>
      </HStack>
      <Text fontSize="xl" fontWeight="700">
        {amount}
      </Text>
      {(min || max) && (
        <HStack
          alignSelf="flex-start"
          bgColor="gray.100"
          color="gray.500"
          fontSize="xs"
          px="2"
          py="1"
          rounded="md"
        >
          {min && (
            <>
              <Text fontWeight="600">{t('Minimum')}</Text>
              <Text>{formatNumberCurrency(min)}</Text>
              {max && <Text>-</Text>}
            </>
          )}
          {max && (
            <>
              <Text fontWeight="600">{t('Maximum')}</Text>
              <Text>{formatNumberCurrency(max)}</Text>
            </>
          )}
        </HStack>
      )}
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {t('AssetsCount')}
        </Text>
        <Stack alignItems="flex-end" flexShrink="0" spacing="0">
          <AssetBadge size="sm" type={assetType || AssetType.ORDINARY_SHARE} value={shareCount} />
        </Stack>
      </HStack>
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {t('UnitPrice')}
        </Text>
        <Text fontWeight="500">{pricePerAssetLabel}</Text>
      </HStack>
    </Stack>
  );
}
