import { Skeleton } from '@chakra-ui/react';
import { SubmitHandler } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  OperationStatus,
  useGetOperationQuery,
  useUpdateFundraisingMutation,
} from '@blockpulse3/graphql/hooks';

import { getPrivateFundraisingParameters } from '../../utils';
import { IPrivateFundraisingParametersForm } from '../types';
import { NewPrivateFundraisingInformations } from './NewPrivateFundraisingInformations';

type Props = unknown;

/**
 * NewPrivateFundraisingParameters.
 * First step of fundraising funnel, parameters.
 *
 * @returns {JSX.Element}
 */
export function NewPrivateFundraisingParameters(): JSX.Element {
  const t = useTranslations();

  const { operationId = '', companyId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
    onCompleted: (data) => {
      if (data.operation.status !== OperationStatus.DRAFT) {
        navigate(generatePath(routes.company.href, { companyId }));
      }
    },
    onError: () => {
      navigate(generatePath(routes.company.href, { companyId }));
    },
  });

  const [updateFundraising, { loading: updateLoading }] = useUpdateFundraisingMutation();

  const defaultValues = getPrivateFundraisingParameters(data?.operation);

  const handleFormSubmit: SubmitHandler<IPrivateFundraisingParametersForm> = (data): void => {
    const selectedFiscalAdvantages = data.allowedFiscalAdvantages.map(
      (fiscalAdvantage) => fiscalAdvantage.value,
    );
    const assetId = data.asset.value !== data.asset.type ? data.asset.value : undefined;
    const assetType = data.asset.type;

    updateFundraising({
      variables: {
        updateFundraisingInput: {
          operationId,
          name: data.name,
          sharePrice: data.sharePrice,
          subscriptionPeriod: data.subscriptionPeriod.value,
          assetId,
          assetType,
          allowedFiscalAdvantages: selectedFiscalAdvantages,
          transactionDebitDate: data?.isRelatedToSecondary ? data.transactionDebitDate : null,
        },
      },
      onCompleted: () => {
        if (companyId) {
          navigate('../' + routes.company.newFundraising.private.edit.documentation.href);
          refetch();
        }
      },
    });
  };

  return (
    <Skeleton isLoaded={!loading} w="full">
      <NewPrivateFundraisingInformations
        defaultValues={defaultValues}
        isLoading={loading || updateLoading}
        loadingText={t('Update')}
        onSubmit={handleFormSubmit}
      />
    </Skeleton>
  );
}

export type NewPrivateFundraisingParametersProps = Props;
