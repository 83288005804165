import { Icon, IconProps } from '@chakra-ui/react';

export const TransferIcon = (props: IconProps): JSX.Element => (
  <Icon width="46" height="46" viewBox="0 0 46 46" fill="none" {...props}>
    <path
      d="M7.66675 7.66669V17.25H8.78132M38.2148 21.0834C37.2716 13.5196 30.8193 7.66669 23.0001 7.66669C16.5651 7.66669 11.056 11.6307 8.78132 17.25M8.78132 17.25H17.2501M38.3334 38.3334V28.75H37.2188M37.2188 28.75C34.9442 34.3694 29.4351 38.3334 23.0001 38.3334C15.1808 38.3334 8.72857 32.4805 7.78538 24.9167M37.2188 28.75H28.7501"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 33V34.5H4H14H15.5V33C15.5 30.2678 13.8687 27.8869 11.5129 26.8791C12.213 26.1992 12.6429 25.2417 12.6429 24.2C12.6429 22.194 11.0489 20.5 9 20.5C6.95114 20.5 5.35714 22.194 5.35714 24.2C5.35714 25.2417 5.78695 26.1992 6.48707 26.8791C4.13128 27.8869 2.5 30.2678 2.5 33Z"
      fill="currentColor"
      stroke="#F9FAFB"
    />
    <path
      d="M30.5 24V25.5H32H42H43.5V24C43.5 21.2678 41.8687 18.8869 39.5129 17.8791C40.213 17.1992 40.6429 16.2417 40.6429 15.2C40.6429 13.194 39.0489 11.5 37 11.5C34.9511 11.5 33.3571 13.194 33.3571 15.2C33.3571 16.2417 33.787 17.1992 34.4871 17.8791C32.1313 18.8869 30.5 21.2678 30.5 24Z"
      fill="currentColor"
      stroke="#F9FAFB"
    />
  </Icon>
);
