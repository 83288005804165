import { Button, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { AssetInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { AssetBadge, TableContainer } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Option list array ** */
  options: AssetInfosFragment[];
  /* ** Callback on table row click ** */
  onClick?: (assetId: string) => void;
};

export function OptionTable({ options, onClick = noop }: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const handleOptionClick = (assetId: string): void => {
    onClick(assetId);
  };

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Name')}</Th>
            <Th>{t('Quantity')}</Th>
            <Th>{t('EndDate')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {[...options]
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map(
              (row) =>
                row.token?.__typename === 'OptionTokenModel' && (
                  <Tr key={row.id} role="button" onClick={(): void => handleOptionClick(row.id)}>
                    <Td>
                      <Text fontWeight="600">{row.name}</Text>
                      <Text color="gray.500" fontSize="xs" fontWeight="400">
                        {i18nAssetType(row.assetType, { nb: 1 })}
                      </Text>
                    </Td>
                    <Td fontWeight="600">
                      <AssetBadge type={row.assetType} value={row.supply} />
                    </Td>
                    <Td fontWeight="600">{formatDate(row.token.expirationDate, 'DD MMMM YYYY')}</Td>
                    <Td textAlign="right">
                      <Button
                        rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                        size="sm"
                        variant="secondary"
                      >
                        {t('ShowDetails')}
                      </Button>
                    </Td>
                  </Tr>
                ),
            )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type OptionTableProps = Props;
