import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  IdentityVerificationStatus,
  useGetBankAccountByCompanyQuery,
  useGetBankAccountStatusByCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { IdentityVerificationModal } from '@blockpulse3/web-client/auth';

let timeoutId: NodeJS.Timeout | null = null;

export function SecondaryOperationBankAccountAlert(): JSX.Element {
  const t = useTranslations();

  const { operationId = '', companyId = '' } = useParams();

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);

  const bankAccountModal = useDisclosure();

  const { data, refetch } = useGetOperationQuery({
    variables: { operationId },
  });
  const operation = data?.operation;

  const bankAccountReq = useGetBankAccountByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || operation?.isExternalIBAN,
  });
  const bankAccount = bankAccountReq.data?.getBankAccountByCompany;

  const bankAccountStatusReq = useGetBankAccountStatusByCompanyQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !operation || operation?.isExternalIBAN || bankAccountReq.loading || !!bankAccount,
  });
  const bankAccountStatus =
    bankAccountStatusReq.data?.getBankAccountStatusByCompany || IdentityVerificationStatus.NONE;
  const isOnboardingPending = [IdentityVerificationStatus.PENDING].includes(bankAccountStatus);

  useEffect(() => {
    const { startPolling, stopPolling } = bankAccountStatusReq;

    if (!isPollingStarted && isOnboardingPending) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      startPolling(2000);

      if (timeoutId) clearTimeout(timeoutId);

      /* ** Stop polling after 2min ** */
      timeoutId = setTimeout(() => {
        stopPolling();
        setIsPollingLoading(false);
      }, 120_000);
    }

    /* ** Stop polling when step status is VALID ** */
    if (isPollingStarted && !isOnboardingPending) {
      stopPolling();
      setIsPollingStarted(false);
      setIsPollingLoading(false);
      refetch();
      bankAccountReq.refetch();
      bankAccountStatusReq.refetch();
    }
  }, [isPollingStarted, isOnboardingPending, bankAccountReq, bankAccountStatusReq, refetch]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const refetchAllData = (): void => {
    refetch();
    bankAccountReq.refetch();
    bankAccountStatusReq.refetch();
  };

  return (
    <>
      <Alert boxShadow="md" status="info">
        <Stack
          alignItems="stretch"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing="4"
          width="full"
        >
          <HStack alignItems="flex-start" spacing="0">
            <AlertIcon as={CurrencyEuroIcon} />
            <Stack spacing="0">
              <AlertTitle fontWeight="bold">{t('BankAccountRequired')}</AlertTitle>
              <AlertDescription>{t('PivotAccountRequiredDescription')}</AlertDescription>
            </Stack>
          </HStack>
          <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
            <Button
              className="confirm"
              fontSize="sm"
              isDisabled={bankAccountModal.isOpen || isPollingLoading || isOnboardingPending}
              isLoading={bankAccountModal.isOpen || isPollingLoading}
              loadingText={isOnboardingPending && t('InProgress')}
              onClick={bankAccountModal.onOpen}
            >
              {t(isOnboardingPending ? 'InProgress' : 'CreateBankAccount')}
            </Button>
          </HStack>
        </Stack>
      </Alert>
      {bankAccountModal.isOpen && (
        <IdentityVerificationModal
          isIssuer={true}
          isOpen={bankAccountModal.isOpen}
          isSwanOnboarding={true}
          refetchData={refetchAllData}
          onClose={bankAccountModal.onClose}
        />
      )}
    </>
  );
}
