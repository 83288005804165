import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  BondAssetInput,
  CompanyStatus,
  CompanyType,
  useGetAssetDraftsByOperationQuery,
  useGetAssetLazyQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorMessage } from '@blockpulse3/ui/commons';
import {
  AllowedFiscalAdvantageSelect,
  CreateBondAssetDraftModal,
  IBondAssetDraft,
  OperationAssetOption,
  OperationAssetSelect,
  bondAssetDraftDefaultValues,
  defaultFiscalAdvantageOptions,
  getBondTokenDraftInfos,
  getBondTokenDraftsOptions,
} from '@blockpulse3/web-client/operation/commons';

import {
  opportunityOperationInformationsDefaults,
  opportunityOperationInformationsSchema,
} from './schema';
import { IOpportunityOperationInformationsForm } from './types';

type Props = {
  defaultValues?: IOpportunityOperationInformationsForm;
  isLoading?: boolean;
  onSubmit?: (data: IOpportunityOperationInformationsForm) => void;
};

/**
 * NewOpportunityOperationInformations.
 *
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationInformations({
  defaultValues = opportunityOperationInformationsDefaults,
  isLoading = false,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const { companyId = '', operationId = '' } = useParams();

  const companyReq = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const company = companyReq?.data?.company;

  const assetDraftsReq = useGetAssetDraftsByOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const draftsBonds = assetDraftsReq.data?.getAssetDraftsByOperation;

  /* ** Fetch asset on modify click, avoid truth state gap between events ** */
  const [getAsset] = useGetAssetLazyQuery();

  const bondAssetModal = useDisclosure();

  const [tmpDraftOptions, setTmpDraftOptions] = useState<OperationAssetOption[]>([]);
  const [assetDraftInput, setTmpAssetDraftInput] = useState<BondAssetInput | null>(null);
  /* ** Fetched default values ** */
  const [bondAssetDraftDefaults, setBondAssetDraftDefaults] = useState<IBondAssetDraft>(
    bondAssetDraftDefaultValues,
  );

  /* ** Previously created bond asset draft ** */
  const draftOptions = getBondTokenDraftsOptions(draftsBonds);

  const { register, control, formState, handleSubmit, setValue, watch } =
    useForm<IOpportunityOperationInformationsForm>({
      defaultValues,
      resolver: yupResolver(opportunityOperationInformationsSchema),
    });
  const asset = watch('asset');

  const handleFormSubmit: SubmitHandler<IOpportunityOperationInformationsForm> = (data) => {
    if (company) {
      onSubmit({ ...data, createBondAsset: assetDraftInput });
    }
  };

  const handleFormCancel = (): void => {
    if (company?.type === CompanyType.SPV && company.status !== CompanyStatus.RUNNING) {
      navigate(generatePath(routes.onboardings.spv.href, { companyId }));
    } else {
      navigate(generatePath(routes.company.href, { companyId }));
    }
  };

  const handleBondAssetDraftSubmit = (assetDraftInput: BondAssetInput): void => {
    setTmpAssetDraftInput(assetDraftInput);
    setTmpDraftOptions([
      {
        label: assetDraftInput.name,
        value: assetDraftInput.assetType,
        type: assetDraftInput.assetType,
      },
    ]);
    setValue('asset', {
      label: assetDraftInput.name,
      value: assetDraftInput.assetType,
      type: assetDraftInput.assetType,
    });
    bondAssetModal.onClose();
  };

  const handleBondAssetDraftSubmitSuccess = (bondAssetDraft: IBondAssetDraft): void => {
    if (bondAssetDraft.assetId) {
      setValue('asset', {
        label: bondAssetDraft.name,
        value: bondAssetDraft.assetId,
        type: bondAssetDraft.assetType.value,
      });
    }
    bondAssetModal.onClose();
    assetDraftsReq.refetch();
  };

  const handleBondAssetDraftOpen = (): void => {
    bondAssetModal.onOpen();
  };

  const handleBondAssetDraftModify = async (option: OperationAssetOption): Promise<void> => {
    if (option.value !== option.type) {
      const assetReq = await getAsset({
        variables: {
          companyId,
          assetId: option.value,
        },
      });
      setBondAssetDraftDefaults(getBondTokenDraftInfos(assetReq.data?.asset));
    }
    bondAssetModal.onOpen();
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('Settings')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <form id="new-opportunity-operation" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            <FormControl isInvalid={!!formState.errors.name}>
              <FormLabel htmlFor="name">{t('OperationName')}</FormLabel>
              <Input id="name" type="string" {...register('name')} />
              <ErrorMessage error={formState.errors.name} />
            </FormControl>
            <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.startSubscriptionDate}>
                <FormLabel htmlFor="startSubscriptionDate">
                  {t('IntentCollectionOpening')}
                </FormLabel>
                <Input
                  id="startSubscriptionDate"
                  type="date"
                  {...register('startSubscriptionDate')}
                />
                <ErrorMessage error={formState.errors.startSubscriptionDate} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.endSubscriptionDate}>
                <FormLabel htmlFor="endSubscriptionDate">{t('IntentCollectionClosing')}</FormLabel>
                <Input id="endSubscriptionDate" type="date" {...register('endSubscriptionDate')} />
                <ErrorMessage error={formState.errors.endSubscriptionDate} />
              </FormControl>
            </Stack>
            <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
              <Controller
                control={control}
                name="asset"
                render={({ field }): JSX.Element => (
                  <FormControl
                    isInvalid={!!formState.errors?.asset}
                    w={{ base: 'full', md: '49%' }}
                  >
                    <FormLabel htmlFor="asset">{t('AssetType')}</FormLabel>
                    <OperationAssetSelect
                      id="asset"
                      isAddButtonDisabled={draftOptions.length > 0 || tmpDraftOptions.length > 0}
                      menuPlacement="auto"
                      value={field.value}
                      options={[
                        {
                          label: t('OrdinaryShare', { nb: 2 }),
                          type: AssetType.ORDINARY_SHARE,
                          value: AssetType.ORDINARY_SHARE,
                        },
                        ...draftOptions,
                        ...tmpDraftOptions,
                      ]}
                      onBondAssetDraftAdd={handleBondAssetDraftOpen}
                      onBondAssetDraftModify={handleBondAssetDraftModify}
                      onChange={field.onChange}
                    />
                    <ErrorMessage error={formState.errors?.asset?.value} />
                  </FormControl>
                )}
              />
              {asset &&
                [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.type) && (
                  <FormControl
                    isInvalid={!!formState.errors.sharePrice}
                    w={{ base: 'full', md: '49%' }}
                  >
                    <FormLabel htmlFor="price-per-share">{t('PricePerShare')}</FormLabel>
                    <InputGroup>
                      <Input
                        id="price-per-share"
                        step="0.01"
                        type="number"
                        {...register('sharePrice')}
                      />
                      <InputRightElement color="gray.500">€</InputRightElement>
                    </InputGroup>
                    <ErrorMessage error={formState.errors.sharePrice} />
                  </FormControl>
                )}
            </Stack>
            {asset &&
              [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.type) && (
                <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                  <Controller
                    control={control}
                    name="allowedFiscalAdvantages"
                    render={({ field }): JSX.Element => (
                      <FormControl isInvalid={!!formState.errors?.allowedFiscalAdvantages}>
                        <HStack alignItems="flex-start" spacing="0">
                          <FormLabel htmlFor="allowedFiscalAdvantages">
                            {t('EligibleTaxDevicesForOperation')}
                          </FormLabel>
                        </HStack>
                        <AllowedFiscalAdvantageSelect
                          options={defaultFiscalAdvantageOptions}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    )}
                  />
                </Stack>
              )}
            <Accordion
              allowToggle
              defaultIndex={defaultValues?.minimalAmount || defaultValues?.maximalAmount ? [0] : []}
              variant="unstyled"
            >
              <AccordionItem>
                <AccordionButton data-cy="expand-parameters">
                  <Text fontWeight="semibold">{t('AdvancedSettings')}</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="24px" p="0">
                  <Stack spacing="4">
                    <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                      <FormControl isInvalid={!!formState.errors?.minimalAmount}>
                        <FormLabel htmlFor="minimalAmount">
                          {t('SubscriptionAmountMinimum')}
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="minimalAmount"
                            step="0.01"
                            type="number"
                            {...register('minimalAmount', { valueAsNumber: true })}
                          />
                          <InputRightElement color="gray.500">€</InputRightElement>
                        </InputGroup>
                        <ErrorMessage error={formState.errors.minimalAmount} />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="maximalAmount">
                          {t('SubscriptionAmountMaximum')}
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="maximalAmount"
                            step="0.01"
                            type="number"
                            {...register('maximalAmount', { valueAsNumber: true })}
                          />
                          <InputRightElement color="gray.500">€</InputRightElement>
                        </InputGroup>
                        <ErrorMessage error={formState.errors.maximalAmount} />
                      </FormControl>
                    </Stack>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </form>
        <CreateBondAssetDraftModal
          defaultValues={bondAssetDraftDefaults}
          isOpen={bondAssetModal.isOpen}
          onClose={bondAssetModal.onClose}
          onSubmit={handleBondAssetDraftSubmit}
          onSubmitSuccess={handleBondAssetDraftSubmitSuccess}
        />
      </CardBody>
      <CardFooter as={HStack} spacing="4">
        <Button
          isDisabled={isLoading}
          type="button"
          variant="secondary"
          w="full"
          onClick={handleFormCancel}
        >
          {t('Back')}
        </Button>
        <Button form="new-opportunity-operation" isLoading={isLoading} type="submit" w="full">
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type NewOpportunityOperationInformationsProps = Props;
