import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import { DownloadIcon } from '@heroicons/react/solid';
import { SortingState, Updater } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyType,
  GetPaginatedShareTransferRegisterDocument,
  GetPaginatedShareTransferRegisterQuery,
  GetPaginatedShareTransferRegisterQueryVariables,
  MovementFilterInput,
  MovementFilterType,
  MovementInfosFragment,
  OperationEntityInfosFragment,
  OrderBy,
  OrderByDirection,
  useGenerateRmtExtractUrlMutation,
  useGenerateRmtUrlMutation,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { ShareTransferRegisterPaginatedTable } from './ShareTransferRegisterPaginatedTable';

const PAGE_SIZE = 10;

type Props = {
  operation?: OperationEntityInfosFragment | undefined;
};

/**
 * ShareTransferRegister.
 * RMT view.
 *
 * @returns {JSX.Element}
 */

export function ShareTransferRegister({ operation }: Props): JSX.Element | null {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filters, setFilters] = useState<MovementFilterInput[]>([]);

  const [sorting, setSorting] = useState<SortingState>([{ id: 'number', desc: false }]);

  const t = useTranslations();

  const { companyId = '' } = useParams();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const [generateRmtUrl, { loading: isRmtLoading }] = useGenerateRmtUrlMutation();
  const [generateRmtExtractUrl, { loading: isRmtExtractLoading }] =
    useGenerateRmtExtractUrlMutation();

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    resetSpecificFilter('searchValue', MovementFilterType.CUSTOM, '');
  };

  // For now only handle unique column sorting
  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetPaginatedShareTransferRegisterQuery,
    GetPaginatedShareTransferRegisterQueryVariables,
    MovementInfosFragment
  >({
    queryDocument: GetPaginatedShareTransferRegisterDocument,
    queryOptions: {
      variables: {
        companyId,
        operationId: operation?.id,
        filterBy: filters,
        first: PAGE_SIZE,
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'movements',
    pageSize: PAGE_SIZE,
  });

  const { totalCount, refetch, reset } = paginationProps;

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', MovementFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: MovementFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: MovementFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleDownloadRmt = (): void => {
    if (companyId) {
      generateRmtUrl({
        variables: { companyId },
        onCompleted: (data) => {
          window.open(data?.generateRmtUrl, '_blank');
        },
      });
    }
    if (operation?.id) {
      generateRmtExtractUrl({
        variables: { operationId: operation?.id },
        onCompleted: (data) => {
          window.open(data?.generateRmtExtractUrl, '_blank');
        },
      });
    }
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  if (companyReq.loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  const company = companyReq?.data?.company;

  return (
    <Card>
      <CardHeader
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">
          {operation ? operation.name + ' - ' : undefined}
          {t(
            operation || company?.type === CompanyType.PARTIAL
              ? 'ShareTransferRegisterExtract'
              : 'ShareTransferRegister',
          )}
        </Heading>
      </CardHeader>
      <CardBody as={Stack} spacing="4">
        <HStack justifyContent="space-between">
          <HStack>
            <InputGroup width={{ base: 'full', md: '300px' }}>
              <Input
                paddingRight="80px"
                placeholder={t('Search')}
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyDown={(e): void => {
                  if (e.key === 'Enter') {
                    handleSearchInputSubmit(searchInput);
                  }
                }}
              />
              <InputRightElement
                flexDirection="row-reverse"
                justifyContent="space-between"
                paddingX="1"
                width="80px"
              >
                <IconButton
                  aria-label="search-database"
                  icon={<Icon as={SearchIcon} />}
                  size="sm"
                  variant="secondary"
                  onClick={(): void => handleSearchInputSubmit(searchInput)}
                />
                {!!searchInput && (
                  <IconButton
                    aria-label="reset"
                    icon={<Icon as={XCircleIcon} />}
                    size="sm"
                    variant="ghost"
                    onClick={handleSearchInputReset}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <Text
              color="gray.800"
              fontSize="sm"
              px={{ base: '0', md: '3' }}
              rounded="md"
              textAlign="center"
            >
              {t('ResultsCount', { nb: totalCount })}
            </Text>
          </HStack>
          {(companyId || operation?.id) && (
            <Button
              isLoading={isRmtLoading || isRmtExtractLoading}
              loadingText={t('DocumentGenerationInProgress')}
              variant="secondary"
              width={{ base: 'fit-content' }}
              onClick={(): void => handleDownloadRmt()}
            >
              <HStack>
                <Text>{t('ExportAction')}</Text>
                <Icon as={DownloadIcon} boxSize="5" color="secondary" />
              </HStack>
            </Button>
          )}
        </HStack>
        <ShareTransferRegisterPaginatedTable
          sorting={sorting}
          onSortingChange={handleSortingChange}
          {...paginationProps}
        />
      </CardBody>
    </Card>
  );
}
