import { Box, Card, CardProps, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslations } from 'use-intl';

import {
  AssetType,
  BalanceInfosFragment,
  useGetAssetPricesByCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, ILineChartData, SimpleLineChart } from '@blockpulse3/ui/commons';

type Props = {
  balance: BalanceInfosFragment;
  subscriptionSharePrice?: number | null;
  underlyingAssetType: AssetType;
} & CardProps;

export function CompanyValuationsCard({
  balance,
  subscriptionSharePrice,
  underlyingAssetType,
  ...props
}: Props): JSX.Element | null {
  const t = useTranslations();

  const { data, loading } = useGetAssetPricesByCompanyQuery({
    variables: {
      companyId: balance.asset.company.id,
    },
    skip: !balance.asset.company.id,
  });

  function calculatePriceChange(initialPrice: number, currentPrice: number): string {
    const difference = currentPrice - initialPrice;
    const percentageChange = (difference / initialPrice) * 100;

    if (percentageChange >= 0) {
      return `+${formatNumberPercentage(percentageChange)}`;
    } else {
      return `${formatNumberPercentage(percentageChange)}`;
    }
  }

  const priceChange =
    balance.valueOfShare && subscriptionSharePrice
      ? calculatePriceChange(subscriptionSharePrice, balance.valueOfShare)
      : '';

  const priceChangeColor = priceChange[0] === '-' ? 'red.300' : 'green.300';

  const formattedDatas: ILineChartData[] =
    data?.getAssetPricesByCompany?.map((assetPrice) => ({
      x: dayjs(assetPrice.date).valueOf(),
      y: assetPrice.price,
    })) || [];

  return (
    <Card
      alignItems="center"
      flexDirection="row"
      gap="3"
      h="fit-content"
      px="4"
      py="3"
      variant="rounded-primary"
      {...props}
    >
      <CompanyIdentityAvatar
        backgroundColor="gray.50"
        boxSize="12"
        src={balance.asset.company.profilePicture}
      />
      <Stack spacing="0.5">
        <Text fontWeight="700" lineHeight="1.1">
          {balance.asset.company.name}
        </Text>
        {balance?.valueOfShares && (
          <Tooltip hasArrow label={t('CurrentValuation')} placement="top">
            <Text cursor="default" fontSize="sm" fontWeight="500" whiteSpace="nowrap">
              {formatNumberCurrency(balance.valueOfShares)}
            </Text>
          </Tooltip>
        )}
      </Stack>
      {underlyingAssetType === AssetType.ORDINARY_SHARE &&
        balance?.valueOfShare &&
        formattedDatas.length > 0 &&
        !loading && (
          <HStack
            alignItems="center"
            bgColor="whiteAlpha.200"
            borderRadius="md"
            justifyContent="space-between"
            minH="52px"
            ml="auto"
            spacing="0"
          >
            {formattedDatas.length > 1 && (
              <Box h="50px" w="85px">
                <SimpleLineChart
                  data={formattedDatas}
                  displayAxis={false}
                  displayLabel={false}
                  displayTooltip={false}
                  lineColor="white"
                />
              </Box>
            )}
            <Stack alignItems="flex-end" lineHeight="1.3" mx="3" spacing="0">
              <Text color="whiteAlpha.800" fontSize="2xs">
                {t('Share', { nb: 1 })}
              </Text>
              <Tooltip
                hasArrow
                placement="top"
                label={t('NbPricePerShare', {
                  price: formatNumberCurrency(balance.valueOfShare),
                })}
              >
                <Text cursor="default" fontWeight="700" whiteSpace="nowrap">
                  {formatNumberCurrency(balance.valueOfShare)}
                </Text>
              </Tooltip>
              {priceChange && (
                <Text color={priceChangeColor} fontSize="2xs" whiteSpace="nowrap">
                  {priceChange}
                </Text>
              )}
            </Stack>
          </HStack>
        )}
    </Card>
  );
}
