import { InfoIcon } from '@chakra-ui/icons';
import {
  Badge,
  Card,
  HStack,
  Heading,
  Hide,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationStatus, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

/**
 * OpportunityOperationHeader.
 * Header details section of an opportunity operation.
 *
 * @returns {JSX.Element}
 */
export function OpportunityOperationHeader(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();
  const { operationId = '', companyId = '' } = useParams();

  const errorToast = useErrorToast();

  const { data, loading, error } = useGetOperationQuery({
    variables: {
      operationId,
    },
    onError: () => {
      navigate(companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href);
      errorToast({ title: t('FundraisingRetrievalError') });
    },
  });

  /* ** Operation status badge ** */
  const { badge } = useBadge(
    data?.operation.status,
    [
      {
        value: OperationStatus.CLOSED,
        label: t('ClosedF'),
        color: 'yellow',
      },
      {
        value: OperationStatus.STARTED,
        label: t('InProgress'),
        color: 'yellow',
      },
      {
        value: OperationStatus.REVIEWED,
        label: t('RevisedF'),
        color: 'blue',
      },
      {
        value: OperationStatus.FINALIZED,
        label: t('FinalizedF'),
        color: 'green',
      },
    ],
    { label: t('Draft', { nb: 1 }), color: 'gray' },
  );

  if (loading || !operationId) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  if (!data) {
    return <ErrorQueryCard h="200px" />;
  }

  const operation = data.operation;

  const isDraft = operation.status === OperationStatus.DRAFT;
  const isClosed = [
    OperationStatus.CLOSED,
    OperationStatus.REVIEWED,
    OperationStatus.FINALIZED,
  ].includes(operation.status);

  const endDate = operation.closingDate || operation.endSubscriptionDate;
  const remainingDays = operation.endSubscriptionDate
    ? dayjs(operation.endSubscriptionDate).diff(dayjs(), 'days')
    : 0;
  const isExpired = remainingDays < 0;

  const endLabel = isDraft ? t('IntentCollectionPeriod') : t('FinishOn');

  const tooltipLabel = isDraft
    ? t('CountStartsAfterOperationLaunch')
    : t('AllStepsToBeCompletedByDate', {
        date: formatDate(operation?.endSubscriptionDate, 'DD MMM YYYY à HH:mm z'),
      });

  const dateLabel = isDraft
    ? `${formatDate(operation.startSubscriptionDate, 'D MMM YYYY')} - ${formatDate(
        endDate,
        'D MMM YYYY',
      )}`
    : endDate && formatDate(endDate, 'D MMM YYYY');

  const isEditable = operation.status === OperationStatus.DRAFT;

  return (
    <Card overflow="hidden">
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
        <HStack>
          <Hide below="md">
            <CompanyIdentityAvatar boxSize="32" src={operation.company.identity?.profilePicture} />
          </Hide>
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            pb="2"
            pt={{ base: '5', md: '2' }}
            px="5"
            spacing="1"
          >
            <Text color="gray.500" fontSize="xs" fontWeight="semibold" textTransform="uppercase">
              {t('PurchaseOpportunity')}
            </Text>
            <Heading fontWeight="bold" whiteSpace={{ base: 'normal', md: 'nowrap' }}>
              {operation.name}
            </Heading>
            <HStack spacing="2">
              <Badge colorScheme={badge.color}>{badge.label}</Badge>
              {isEditable && (
                <Link
                  to={generatePath(routes.company.newOpportunity.edit.full, {
                    companyId,
                    operationId: operation?.id,
                  })}
                >
                  <Text fontSize="xs" textDecoration="underline">
                    {t('Modify')}
                  </Text>
                </Link>
              )}
            </HStack>
          </Stack>
        </HStack>
        <Stack
          alignItems={{ base: 'flex-start', md: 'flex-end' }}
          bg="gray.50"
          justifyContent="center"
          p="5"
          spacing="1"
        >
          <HStack>
            <Text color="gray.600" fontWeight="500">
              {endLabel}
            </Text>
            {!isClosed && (
              <Tooltip hasArrow label={tooltipLabel} placement="top">
                <Icon as={InfoIcon} color="gray.600" />
              </Tooltip>
            )}
          </HStack>
          <Text color="gray.600" fontSize="2xl" fontWeight="600">
            {dateLabel}
          </Text>
          {!isDraft && !isClosed && (
            <Text color="gray.600" fontSize="sm" fontWeight="600">
              {isExpired ? t('ClosedMarket') : t('RemainingDays', { nb: remainingDays })}
            </Text>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
