import { Flex, HStack, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { CheckIcon, DocumentTextIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityType, RepresentativeRole, useGetSpvQuery } from '@blockpulse3/graphql/hooks';

/**
 * SummaryDocuments.
 * Summary documents for the SPV funnel.
 *
 * @returns {JSX.Element}
 */
export function SummaryDocuments(): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const { companyId = '' } = useParams();

  const { data } = useGetSpvQuery({ variables: { companyId } });

  const president = data?.company.companyRepresentative.find((rep) => {
    return rep.role === RepresentativeRole.PRESIDENT;
  });
  const isIndividual = president?.representativeIdentity.type === IdentityType.INDIVIDUAL;

  return (
    <Stack spacing="4">
      <Stack spacing="4">
        <Text fontSize="xl" fontWeight="bold">
          {t('DocumentsToBeSignedByPresident')}
        </Text>
        <SimpleGrid gap="4" minChildWidth="300px">
          <HStack>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderRadius="full"
              boxSize="32px"
              justifyContent="center"
            >
              <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
            </Flex>
            <Text color="gray.600" fontWeight="medium">
              {i18nDocumentTitle('STATUTS')}
            </Text>
          </HStack>
          <HStack>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderRadius="full"
              boxSize="32px"
              justifyContent="center"
            >
              <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
            </Flex>
            <Text color="gray.600" fontWeight="medium">
              {i18nDocumentTitle('DOMICILIATION')}
            </Text>
          </HStack>
          {isIndividual && (
            <HStack>
              <Flex
                alignItems="center"
                bg="gray.50"
                borderRadius="full"
                boxSize="32px"
                justifyContent="center"
              >
                <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
              </Flex>
              <Text color="gray.600" fontWeight="medium">
                {i18nDocumentTitle('DNC')}
              </Text>
            </HStack>
          )}
          <HStack>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderRadius="full"
              boxSize="32px"
              justifyContent="center"
            >
              <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
            </Flex>
            <Text color="gray.600" fontWeight="medium">
              {i18nDocumentTitle('SOUSCRIPTEURS')}
            </Text>
          </HStack>
          <HStack>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderRadius="full"
              boxSize="32px"
              justifyContent="center"
            >
              <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
            </Flex>
            <Text color="gray.600" fontWeight="medium">
              {i18nDocumentTitle('DBE')}
            </Text>
          </HStack>
          <HStack>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderRadius="full"
              boxSize="32px"
              justifyContent="center"
            >
              <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
            </Flex>
            <Text color="gray.600" fontWeight="medium">
              {i18nDocumentTitle('POUVOIR_FORMALITES')}
            </Text>
          </HStack>
          <HStack>
            <Flex
              alignItems="center"
              bg="gray.50"
              borderRadius="full"
              boxSize="32px"
              justifyContent="center"
            >
              <Icon as={DocumentTextIcon} boxSize="24px" color="gray.500" />
            </Flex>
            <Text color="gray.600" fontWeight="medium">
              {i18nDocumentTitle('FORMULAIRE_M0')}
            </Text>
          </HStack>
        </SimpleGrid>
      </Stack>
      <Stack spacing="4">
        <Text fontSize="xl" fontWeight="bold">
          {t('SupportedServices')}
        </Text>
        <SimpleGrid gap="4" minChildWidth="300px">
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('ElecSignatureManagement')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('FinancialFlowsManagementAssociated')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('AutomaticUpdateOfYourCapitalizationTable')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('ArchiveAndCentralizeOperationDocuments')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('AutomatedDistributionOfAssets')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('UpdateOfShareTransferRegisterOnBlockchain')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('OpenDepositAccount')}
            </Text>
          </HStack>
          <HStack>
            <Icon as={CheckIcon} boxSize="24px" color="green.500" />
            <Text color="gray.600" fontWeight="medium">
              {t('RegistrationAndFormalities')}
            </Text>
          </HStack>
        </SimpleGrid>
      </Stack>
    </Stack>
  );
}
