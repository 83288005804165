import {
  Badge,
  Button,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { Exceptions } from '@blockpulse3/data/shared';
import {
  DocumentStatus,
  OperationDocumentType,
  OperationStatus,
  useDeleteSubscriptionCustomDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionQuery,
  usePreviewIbanDocumentLazyQuery,
  usePreviewSubscriptionDocumentLazyQuery,
} from '@blockpulse3/graphql/hooks';
import { getDocumentIds, getURLObject, isDocumentTypeSubscription } from '@blockpulse3/helpers';
import {
  CONTENT_ERROR,
  CONTENT_LOADING,
  DeleteConfirmModal,
  DocumentLink,
  PreviewDocumentModal,
  useGetDocumentsComplianceOfficerOptions,
  useGetSubscriptionDocumentsOptions,
} from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { AddSubscriptionDocumentModal } from './AddSubscriptionDocumentModal';

type Props = {
  subscriptionId?: string;
};

/**
 * SubscriptionPanelDocuments.
 * Documents section of the subscription side panel.
 *
 * canUploadFilesTypes: Array of OperationDocumentType wich can be uploaded, if null we can only upload CUSTOM_FILE
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscriptionPanelDocuments({
  subscriptionId: subscriptionIdProp = '',
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentTitle = useTranslations('DocumentValues');

  const [templateUrl, setTemplateUrl] = useState<string | symbol | null>(CONTENT_LOADING);

  const [previewTitle, setPreviewTitle] = useState<string>('');
  const deleteRef = useRef(null);

  const deleteModal = useDisclosure();
  const previewDocumentModal = useDisclosure();
  const addDocumentModal = useDisclosure();

  const { subscriptionId = subscriptionIdProp } = useParams();
  const hasManagerRoles = useManagerRole({ subscriptionId });

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const [selectedFileId, setSelectedFileId] = useState<string>('');
  const [errorPreview, setErrorPreview] = useState<string | undefined>(undefined);

  /* ** Query to get pdf url of a document ** */
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  const [deleteCustomDocument, { loading: isCustomDocumentDeletionLoading }] =
    useDeleteSubscriptionCustomDocumentMutation();

  const [previewIbanDocument] = usePreviewIbanDocumentLazyQuery();
  const [previewSubscriptionDocument] = usePreviewSubscriptionDocumentLazyQuery();

  const subscription = data?.subscription;
  const isOperationFinalized = subscription?.operation?.status === OperationStatus.FINALIZED;
  const subscriptionDocuments = subscription?.subscriptionDocuments || [];
  const subscriptionDocument = subscriptionDocuments.find((doc) =>
    isDocumentTypeSubscription(doc.type as OperationDocumentType),
  );

  const simpleOptions = useGetSubscriptionDocumentsOptions();
  const complianceOfficerOptions = useGetDocumentsComplianceOfficerOptions(subscription);
  const uploadableSubscriptionDocuments = subscriptionIdProp
    ? simpleOptions
    : complianceOfficerOptions;

  const documents = getDocumentIds(subscriptionDocuments);
  const holdingMethodCertificate = subscriptionDocuments.find(
    (doc) =>
      doc.status === DocumentStatus.SIGNED &&
      [
        OperationDocumentType.IR_PME_CERTIFICATE,
        OperationDocumentType.PEA_CERTIFICATE,
        OperationDocumentType.OWNERSHIP_CERTIFICATE,
        OperationDocumentType.PEA_PME_CERTIFICATE,
      ].includes(doc.type as OperationDocumentType),
  );

  const SUBSCRIPTION_TYPE =
    subscription?.operation.subscriptionDocumentType || OperationDocumentType.SUBSCRIPTION;

  const customFiles = subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.CUSTOM_FILE,
  );

  const fundsSourceCertificateFiles = subscriptionDocuments.filter(
    (doc) => doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
  );

  /* ** Participation document status badge ** */
  const subscriptionDocumentStatus = useBadge(
    subscriptionDocument?.status,
    [
      { value: DocumentStatus.SIGNED, color: 'green', label: t('Signed') },
      { value: DocumentStatus.ONGOING, color: 'gray', label: t('RequiredAction', { nb: 1 }) },
      {
        value: DocumentStatus.CANCELLED,
        color: 'red',
        label: t('Canceled'),
      },
      {
        value: DocumentStatus.SIGNATURES_PENDING,
        color: 'yellow',
        label: t('InProgress'),
      },
    ],
    { color: 'gray', label: t('RequiredAction', { nb: 1 }) },
  );

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: 2 })}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const handleDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const handleFileDelete = (fileId: string): void => {
    setSelectedFileId(fileId);
    deleteModal.onOpen();
  };

  const handleDelete = (): void => {
    if (!selectedFileId) return;

    deleteCustomDocument({
      variables: {
        subscriptionId,
        documentId: selectedFileId,
      },
      onCompleted: () => {
        refetch();
      },
    });

    deleteModal.onClose();
  };

  const handleIbanDocumentPreview = (): void => {
    if (!subscriptionId) return;

    setTemplateUrl(CONTENT_LOADING);
    setPreviewTitle(t('SubscriptionPersonalIBAN'));
    previewDocumentModal.onOpen();

    previewIbanDocument({
      variables: {
        subscriptionId,
      },
      fetchPolicy: 'no-cache',
      onCompleted({ previewIbanDocument: pdfURL }) {
        const objURL = getURLObject(pdfURL, 'application/pdf');
        setTemplateUrl(objURL);
      },
    });
  };

  const handleSubscriptionDocumentPreview = (documentType: OperationDocumentType): void => {
    if (!subscriptionId) return;

    setTemplateUrl(CONTENT_LOADING);
    setPreviewTitle(i18nDocumentTitle(documentType as IntlDocumentValues));
    previewDocumentModal.onOpen();

    previewSubscriptionDocument({
      variables: {
        subscriptionId,
        documentType,
      },
      fetchPolicy: 'no-cache',
      onCompleted({ pdfURL }) {
        const objURL = getURLObject(pdfURL, 'application/pdf');
        setTemplateUrl(objURL);
      },
      onError(err) {
        setTemplateUrl(CONTENT_ERROR);
        let errorMessage;
        if (err.message === Exceptions.ErrorBuyerIdentityNotApproved) {
          errorMessage = t('ErrorBuyerIdentityNotApproved');
        }
        if (err.message === Exceptions.ErrorSellerIdentityNotApproved) {
          errorMessage = t('ErrorSellerIdentityNotApproved');
        }

        setErrorPreview(errorMessage);
      },
    });
  };

  return (
    <Stack px="4" spacing="2">
      <HStack justifyContent="space-between">
        <Text color="gray.600" fontWeight="600">
          {t('Document', { nb: subscriptionDocuments.length })}
        </Text>
        {hasManagerRoles && (
          <Button
            alignSelf="flex-start"
            disabled={addDocumentModal.isOpen}
            leftIcon={<Icon as={PlusIcon} />}
            size="sm"
            variant="secondary"
            onClick={addDocumentModal.onOpen}
          >
            {t('AddDocument')}
          </Button>
        )}
      </HStack>
      <Stack spacing="2">
        {isOperationFinalized &&
          !documents[SUBSCRIPTION_TYPE] &&
          !fundsSourceCertificateFiles.length &&
          !holdingMethodCertificate &&
          !customFiles.length && (
            <Text color="gray.500" fontSize="sm">
              {t('NoDocumentsLinked')}
            </Text>
          )}
        {(documents[SUBSCRIPTION_TYPE] || !isOperationFinalized) && (
          <DocumentLink
            fileName={i18nDocumentTitle(SUBSCRIPTION_TYPE as IntlDocumentValues)}
            onClick={(): void =>
              documents[SUBSCRIPTION_TYPE]
                ? handleDocumentClick(documents[SUBSCRIPTION_TYPE])
                : handleSubscriptionDocumentPreview(SUBSCRIPTION_TYPE)
            }
          >
            <Badge colorScheme={subscriptionDocumentStatus.badge.color} fontSize="xs">
              {subscriptionDocumentStatus.badge.label}
            </Badge>
          </DocumentLink>
        )}
        {!isOperationFinalized &&
          (documents[OperationDocumentType.IBAN] ? (
            <DocumentLink
              fileName={t('SubscriptionPersonalIBAN')}
              onClick={(): void => handleDocumentClick(documents[OperationDocumentType.IBAN])}
            />
          ) : (
            hasManagerRoles && (
              <DocumentLink
                fileName={t('SubscriptionPersonalIBAN')}
                onClick={handleIbanDocumentPreview}
              />
            )
          ))}
        {fundsSourceCertificateFiles.map((doc, index) => (
          <DocumentLink
            key={doc.id}
            fileName={
              fundsSourceCertificateFiles.length > 1
                ? i18nDocumentTitle('FUNDS_SOURCE_CERTIFICATE') + ' n° ' + (index + 1)
                : i18nDocumentTitle('FUNDS_SOURCE_CERTIFICATE')
            }
            onClick={(): void => handleDocumentClick(doc.id)}
          />
        ))}
        {holdingMethodCertificate && (
          <DocumentLink
            fileName={i18nDocumentTitle(holdingMethodCertificate.type as IntlDocumentValues)}
            onClick={(): void => handleDocumentClick(holdingMethodCertificate.id)}
          />
        )}
        {customFiles.map((file, index) => {
          const fileName =
            file.title === 'CUSTOM_FILE' ? `${t('Annex', { nb: 1 })} ${++index}` : file.title;
          return (
            <HStack key={file.id} justify="space-between">
              <DocumentLink
                fileName={fileName}
                onClick={(): void => handleDocumentClick(file.id)}
              />
              {file.parentDocumentType === 'SubscriptionDocument' && hasManagerRoles && (
                <IconButton
                  aria-label="delete-document"
                  icon={<Icon as={TrashIcon} boxSize="4" />}
                  isDisabled={isCustomDocumentDeletionLoading}
                  size=""
                  variant="icon-delete"
                  onClick={(): void => handleFileDelete(file.id)}
                />
              )}
            </HStack>
          );
        })}
      </Stack>
      <AddSubscriptionDocumentModal
        isOpen={addDocumentModal.isOpen}
        subscriptionId={subscriptionId}
        uploadableSubscriptionDocuments={uploadableSubscriptionDocuments}
        onClose={addDocumentModal.onClose}
      />
      <PreviewDocumentModal
        errorTitle={errorPreview}
        isOpen={previewDocumentModal.isOpen}
        src={templateUrl}
        title={previewTitle}
        onClose={previewDocumentModal.onClose}
      />
      <DeleteConfirmModal
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={deleteRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteDocumentQuestion')}
        onClose={deleteModal.onClose}
        onDelete={handleDelete}
      />
    </Stack>
  );
}

export type MovementPanelDocumentsProps = Props;
