import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { AssetType, useCreateOpportunityMutation } from '@blockpulse3/graphql/hooks';
import { useErrorToast } from '@blockpulse3/ui/commons';

import { NewOpportunityOperationInformations } from './NewOpportunityOperationInformations';
import { IOpportunityOperationInformationsForm } from './types';

/**
 * NewOpportunityOperationCreate.
 *
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationCreate(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const [createOpportunityOperation, { loading: isCreateLoading }] = useCreateOpportunityMutation();

  const handleFormSubmit = (data: IOpportunityOperationInformationsForm): void => {
    const assetId = data.asset.value !== data.asset.type ? data.asset.value : undefined;
    const assetType = data.asset.type;

    const selectedFiscalAdvantages =
      assetType === AssetType.BOND
        ? []
        : data.allowedFiscalAdvantages.map((fiscalAdvantage) => fiscalAdvantage.value);

    createOpportunityOperation({
      variables: {
        createOpportunityInput: {
          companyId,
          name: data.name,
          startSubscriptionDate: data.startSubscriptionDate,
          endSubscriptionDate: data.endSubscriptionDate,
          assetId,
          assetType,
          sharePrice: data.sharePrice,
          createBondAsset: data.createBondAsset,
          minimalAmount: data.minimalAmount,
          maximalAmount: data.maximalAmount,
          allowedFiscalAdvantages: selectedFiscalAdvantages,
        },
      },
      onCompleted: (data) => {
        window.history.replaceState(
          null,
          '',
          generatePath(routes.company.newOpportunity.edit.href, {
            operationId: data.createOpportunity.id,
          }),
        );
        navigate(
          resolvePath(
            routes.company.newOpportunity.edit.documentation.href,
            generatePath(routes.company.newOpportunity.edit.href, {
              operationId: data.createOpportunity.id,
            }),
          ).pathname,
        );
      },
      onError: () => {
        errorToast({ title: t('OperationCreationError') });
      },
    });
  };

  return (
    <NewOpportunityOperationInformations isLoading={isCreateLoading} onSubmit={handleFormSubmit} />
  );
}
