import { Box, Card, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

export function SubscriptionOperationInformations(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  if (loading || !data) {
    return (
      <Skeleton>
        <Card h="80px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="80px" />;
  }

  const { subscription } = data;
  const { fundraising, ...operation } = subscription.operation;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
      {fundraising && (
        <Box bg="gray.50" color="white" p="4" w="full">
          <Text color="gray.500" fontWeight="500">
            {t('FundraisingAmount')}
          </Text>
          <Text color="gray.800" fontSize="lg" fontWeight="600">
            {formatNumberCurrency(fundraising?.hardCap || 0)}
          </Text>
        </Box>
      )}
      <Box bg="gray.50" color="white" p="4" w="full">
        <Text color="gray.500" fontWeight="500">
          {operation.assetType === AssetType.BOND
            ? t('SubscriptionPricePerBond')
            : t('SubscriptionPricePerShare')}
        </Text>
        <Text color="gray.800" fontSize="lg" fontWeight="600">
          {formatNumberCurrency(operation?.sharePrice || 0)}
        </Text>
      </Box>
    </Stack>
  );
}
