import { Text, useDisclosure } from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import { Exceptions, noop } from '@blockpulse3/data/shared';
import {
  MergedSubscriptionDetailsStep,
  SubscriptionStepStatus,
  useGetSubscriptionQuery,
  useValidateSubscriptionDetailsMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, isDocumentTypeSubscription } from '@blockpulse3/helpers';
import { useErrorToast } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';
import { SubscriptionDetailsForm, SubscriptionDetailsModal } from './SubscriptionDetailsModal';

type Props = {
  subscriptionStep: MergedSubscriptionDetailsStep;
  isDisabled: boolean;
};

export function SubscriptionDetailsStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const { isOpen, onClose, onOpen } = useDisclosure();
  const errorToast = useErrorToast();

  const { subscriptionId = '' } = useParams();

  const { identityId } = useIdentity();

  const [validateSubscriptionDetails] = useValidateSubscriptionDetailsMutation();

  const { data, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const holdingMethod = subscription?.holdingMethod;

  const investAmount = subscription?.investAmount;
  const isInvestAmount = !!investAmount && investAmount !== 0;

  const subscriptionDocument = subscription?.data.mergedSteps?.find(
    (step) =>
      step.__typename === 'MergedDocumentSignatureStep' &&
      isDocumentTypeSubscription(step.documentType),
  );
  const isSubscriptionDocumentGenerated =
    subscriptionDocument && subscriptionDocument.status !== SubscriptionStepStatus.NONE;

  const handleModalOpen = (): void => {
    // Prevent modal from opening once subscription document is generated
    if (!isSubscriptionDocumentGenerated) {
      onOpen();
    }
  };

  const handleModalSubmit = (data: SubscriptionDetailsForm): void => {
    if (!subscriptionId) return;

    validateSubscriptionDetails({
      variables: {
        validateSubscriptionDetailsInput: {
          subscriptionId,
          investAmount: data.investAmount,
        },
      },
      onCompleted: ({ validateSubscriptionDetails }) => {
        refetch();

        // Only close modal if step is validated
        if (validateSubscriptionDetails) {
          onClose();
        }
      },
      onError: (err: unknown) => {
        const error = err as Error;

        if (error.message === Exceptions.ExceedsHardCap) {
          errorToast({
            title: t('MaximumFundraisingAmountExceeded'),
          });
        } else if (error.message === Exceptions.WalletMissingBankInformation) {
          errorToast({ title: t('WalletMissingBankInformation') });
        } else {
          errorToast({ title: t('InvalidSubscriptionAmount') });
        }
      },
    });
  };

  const mifidStep = subscription?.data.mergedSteps.find(
    (step) => step.__typename === 'MergedMifidStep',
  );
  const subscriptionDetailsStep = subscription?.data.mergedSteps.find(
    (step) => step.__typename === 'MergedSubscriptionDetailsStep',
  );
  const proofOfFundsSourceThreshold = subscriptionStep.proofOfFundsSourceThreshold;
  const needsFundsSourceCertificate =
    !!mifidStep &&
    proofOfFundsSourceThreshold &&
    !!investAmount &&
    investAmount >= proofOfFundsSourceThreshold &&
    subscriptionDetailsStep?.status !== SubscriptionStepStatus.VALID;

  return (
    <>
      <SubscriptionStep
        icon={CreditCardIcon}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('ChooseAmountAndHoldingMethod')}
        type={subscriptionStep.type}
        onClick={!isDisabled ? handleModalOpen : noop}
      >
        {(isInvestAmount || needsFundsSourceCertificate) && (
          <>
            {isInvestAmount && (
              <Text fontSize="sm">
                {t.rich('SubscribeForAmountAndMethod', {
                  investAmount: formatNumberCurrency(investAmount),
                  holdingMethod: i18nHoldingMethodType(
                    holdingMethod as IntlHoldingMethodTypeValues,
                  ),
                  important: (chunks) => <Text as="b">{chunks}</Text>,
                })}
              </Text>
            )}
            {needsFundsSourceCertificate && (
              <Text color="yellow.700" fontSize="sm">
                {t('MissingOriginCertificate')}
              </Text>
            )}
          </>
        )}
      </SubscriptionStep>
      <SubscriptionDetailsModal
        isOpen={isOpen}
        subscriptionStep={subscriptionStep}
        onClose={onClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
}
