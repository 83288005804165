import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useCreateAuthorizedSellerMutation,
  useGetCompanyShareholdersQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import {
  INewSecondaryOperationSeller,
  NewSecondaryOperationSellerForm,
} from './NewSecondaryOperationSellerForm';

type Props = Omit<ResponsiveModalProps, 'children'>;

/**
 * NewSecondaryOperationSellerModal.
 * Modal implementing the form for adding/removing authorized sellers to an operation.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationSellerModal({ ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '', operationId = '' } = useParams();

  const {
    data: operationData,
    loading: operationLoading,
    refetch: refetchOperation,
  } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: shareholdersData,
    loading: shareholdersLoading,
    refetch: refetchShareholders,
  } = useGetCompanyShareholdersQuery({
    variables: { companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true,
  });

  const [createAuthorizedSeller] = useCreateAuthorizedSellerMutation();

  const handleCreateAuthorizedSeller: SubmitHandler<INewSecondaryOperationSeller> = ({
    sellerIdentity,
  }): void => {
    createAuthorizedSeller({
      variables: {
        createAuthorizedSellerInput: {
          operationId,
          identityId: sellerIdentity.value,
        },
      },
      onCompleted: () => {
        refetchOperation();
        refetchShareholders();
        props.onClose();
      },
    });
  };

  const sellers = operationData?.operation?.authorizedSellers || [];
  const shareholders =
    shareholdersData?.getCompanyShareholders?.filter(
      (shareholder) => shareholder.assetId === operationData?.operation?.asset?.id,
    ) || [];
  const addableSellers = shareholders
    .filter((shareholder) => !sellers.some((seller) => seller.id === shareholder.identity?.id))
    .map((shareholder) => shareholder.identity);

  const isLoading = operationLoading || shareholdersLoading;

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('AddSeller')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <NewSecondaryOperationSellerForm
            addableSellers={addableSellers}
            onSubmit={handleCreateAuthorizedSeller}
          />
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form="add-seller" isLoading={isLoading} type="submit">
            {t('AddTheSeller')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type NewSecondaryOperationSellerModalProps = Props;
