import { Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  FinalizeSwanOnboarding,
  InitiateDistributionView,
  SchedulePaymentsView,
} from '@blockpulse3/web-client/bank';
import { InitiateRefund } from '@blockpulse3/web-client/operation/commons';

import { EmptyLayout } from '../layouts';
import { UserStateRedirects } from './UserStateRedirects';

type Props = unknown;

/**
 * SubscriptionAndPaymentRoutes.
 * Renders subscription and payment routes for individuals and companies.
 *
 * @returns {JSX.Element}
 */
export function BankRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<EmptyLayout />}>
        <Route element={<UserStateRedirects />}>
          <Route path={routes.bank.initiatePayments.href + '/*'}>
            <Route
              element={<InitiateDistributionView />}
              path={routes.bank.initiatePayments.distribution.href}
            >
              <Route
                element={<InitiateDistributionView />}
                path={routes.bank.initiatePayments.distribution.transfer.href}
              />
              <Route index element={<InitiateDistributionView />} />
            </Route>
            <Route
              element={<SchedulePaymentsView />}
              path={routes.bank.initiatePayments.schedule.href}
            />
            <Route element={<InitiateRefund />} path={routes.bank.initiatePayments.refund.href} />
          </Route>
          <Route
            element={<FinalizeSwanOnboarding />}
            path={routes.bank.finalizeSwanOnboarding.href}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export type SubscriptionAndPaymentRoutesProps = Props;
