import { Button, HStack, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { ReceiptRefundIcon } from '@heroicons/react/outline';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  FundraisingWorkflowType,
  OperationInvestStatus,
  OperationStatus,
  SubscriptionStepStatus,
  SubscriptionStepType,
  useGetSubscriptionQuery,
  useValidateCompletedSubscriptionMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  IconButtonWithTooltip,
  Progress,
  ProgressStep,
  WarningCard,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useStepStatusMap } from '@blockpulse3/ui/ui-hooks';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { ConsultIdentityVerification } from '../../OperationPanel/OperationPanelProgressSteps/IdentityVerificationStep/ConsultIdentityVerification';
import { ResetDocumentSignature } from './DocumentSignatureStep/ResetDocumentSignature';
import { ConsultMifid, ValidateMifid } from './MifidStep';
import { ValidatePayment } from './PaymentStep/ValidatePayment';
import { ValidateFundsSourceCertificate } from './SubscriptionDetailsStep/ValidateFundsSourceCertificate';

type Props = {
  openRefundModal: (amount?: number) => void;
};

/**
 * SubscriptionPanelProgressSteps.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionPanelProgressSteps({ openRefundModal }: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const isUserAuthorized = useManagerRole({ subscriptionId });

  /* ** Toasts ** */
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const subscription = data?.subscription;

  const [validateCompletedSubscription, { loading: validateLoading }] =
    useValidateCompletedSubscriptionMutation();

  const [stepStatusMap, steps] = useStepStatusMap(subscription?.data?.mergedSteps);

  const IdentityVerificationStep = useCallback(
    (): JSX.Element | null => <ConsultIdentityVerification subscriptionId={subscriptionId} />,
    [subscriptionId],
  );

  if (loading || error || !subscription?.operation?.fundraising) {
    return (
      <Stack px="4" spacing="4">
        <Text color="gray.600" fontWeight="600">
          {t('InvestorValidationSteps')}
        </Text>
        <Stack spacing="2">
          <Skeleton h="30px" w="60%" />
          <Skeleton h="30px" w="80%" />
          <Skeleton h="30px" w="65%" />
          <Skeleton h="30px" w="70%" />
        </Stack>
      </Stack>
    );
  }

  const balance = subscription?.bankVirtualIban?.balance || 0;

  const subscriptionStepTypes =
    subscription?.data.mergedSteps?.map((step) => {
      return step.type;
    }) || [];

  const isDetailsStepValidated =
    stepStatusMap[SubscriptionStepType.DETAILS] === SubscriptionStepStatus.VALID;
  const isPaymentStepValidated =
    stepStatusMap[SubscriptionStepType.PAYMENT] === SubscriptionStepStatus.VALID;
  const isPaymentStepInError =
    stepStatusMap[SubscriptionStepType.PAYMENT] === SubscriptionStepStatus.ERROR;
  const isDocumentStepValidated =
    stepStatusMap[SubscriptionStepType.DOCUMENT_SIGNATURE] === SubscriptionStepStatus.VALID;
  const isDocumentStepStarted =
    stepStatusMap[SubscriptionStepType.DOCUMENT_SIGNATURE] !== SubscriptionStepStatus.NONE;
  const investAmount = subscription?.investAmount;
  const isAllInSubscriptionWorkflow =
    subscription?.operation?.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const MifidStep = (): JSX.Element | null => {
    const mifidStepStatus = stepStatusMap[SubscriptionStepType.MIFID];
    if (mifidStepStatus === SubscriptionStepStatus.VALID) {
      return <ConsultMifid subscriptionId={subscriptionId} />;
    }
    if (mifidStepStatus === SubscriptionStepStatus.PENDING) {
      return (
        <HStack>
          <ConsultMifid subscriptionId={subscriptionId} />;
          <ValidateMifid subscriptionId={subscriptionId} />
        </HStack>
      );
    }
    return null;
  };

  const PaymentStep = (): JSX.Element | null => {
    if (!investAmount) return null;

    const isFixedCapital = subscription.operation.company.capitalType === CompanyCapitalType.FIXED;
    const isExternalIBAN = subscription.operation.isExternalIBAN;

    if (isPaymentStepValidated || isPaymentStepInError) {
      return (
        <>
          <Text color="gray.800" fontSize="xs" fontWeight="600">
            {t('AmountReceived', {
              amount: formatNumberCurrency(
                subscription.bankVirtualIban ? subscription.bankVirtualIban.balance : investAmount,
              ),
            })}
          </Text>
          {!!subscription.bankVirtualIban?.balance &&
            subscription.bankVirtualIban.balance > investAmount && (
              <HStack spacing="1">
                <WarningCard
                  h="8"
                  py="1"
                  title={t('OverpaidByAmount', {
                    amount: formatNumberCurrency(
                      subscription.bankVirtualIban.balance - investAmount,
                    ),
                  })}
                />
                <IconButtonWithTooltip
                  aria-label="refund"
                  icon={<Icon as={ReceiptRefundIcon} boxSize="18px" color="gray.700" />}
                  label={t('Refund')}
                  size="sm"
                  variant="secondary"
                  onClick={(): void =>
                    openRefundModal(
                      Math.round(
                        ((subscription.bankVirtualIban?.balance || 0) - investAmount) * 100,
                      ) / 100,
                    )
                  }
                />
              </HStack>
            )}
        </>
      );
    } else if (
      isFixedCapital &&
      isExternalIBAN &&
      isDocumentStepValidated &&
      (!isAllInSubscriptionWorkflow || subscription.operation.status === OperationStatus.REVIEWED)
    ) {
      return <ValidatePayment subscriptionId={subscriptionId} />;
    } else if (balance) {
      return (
        <Text color="gray.800" fontSize="xs" fontWeight="600">
          {t('AmountReceived', {
            amount: formatNumberCurrency(balance),
          })}
        </Text>
      );
    }
    return null;
  };

  const SubscriptionDetailsStep = (): JSX.Element | null => {
    if (
      isDetailsStepValidated &&
      subscription &&
      ![OperationInvestStatus.APPROVED, OperationInvestStatus.FINALIZED].includes(
        subscription.investStatus,
      )
    ) {
      return <ValidateFundsSourceCertificate subscriptionId={subscriptionId} />;
    }
    return null;
  };

  const DocumentSignatureStep = (): JSX.Element | null => {
    if (
      isDocumentStepStarted &&
      subscription &&
      ![OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
        subscription.operation.status,
      )
    ) {
      return (
        <ResetDocumentSignature
          operationDocumentType={subscription.operation.subscriptionDocumentType}
          subscriptionId={subscriptionId}
        />
      );
    }
    return null;
  };

  const shouldDisplayValidateSubscription =
    subscription?.investStatus === OperationInvestStatus.COMPLETED && isUserAuthorized;

  /* ** On validate subscription button click */
  const handleValidateCompletedSubscription = (): void => {
    validateCompletedSubscription({
      variables: {
        validateCompletedSubscriptionInput: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        successToast({
          title: t('SubscriptionSuccessfullyValidated'),
        });
        refetch();
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.ExceedsHardCap) {
          errorToast({
            title: t('ValidationRejectedExceededAmount'),
          });
        } else {
          errorToast({ title: t('SubscriptionValidationError') });
        }
      },
    });
  };

  return (
    <Stack px="4" spacing="4">
      <Text color="gray.600" fontWeight="600">
        {t('InvestorValidationSteps')}
      </Text>
      <Progress direction="column">
        <ProgressStep
          key={0}
          index={0}
          textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
          title={t('AccountCreation')}
          status={
            subscription?.buyerIdentity?.isSignedUp
              ? SubscriptionStepStatus.VALID
              : SubscriptionStepStatus.NONE
          }
        />
        <>
          {steps
            .filter((step) => subscriptionStepTypes.includes(step.type))
            .map((step) => (
              <ProgressStep
                key={step.index}
                index={step.index}
                status={step.status}
                textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
                title={step.title}
              >
                <>
                  {step.type === SubscriptionStepType.MIFID && <MifidStep />}
                  {step.type === SubscriptionStepType.IDENTITY_VERIFICATION && (
                    <IdentityVerificationStep />
                  )}
                  {step.type === SubscriptionStepType.DETAILS && <SubscriptionDetailsStep />}
                  {step.type === SubscriptionStepType.DOCUMENT_SIGNATURE && (
                    <DocumentSignatureStep />
                  )}
                  {step.type === SubscriptionStepType.PAYMENT && <PaymentStep />}
                </>
              </ProgressStep>
            ))}
        </>
      </Progress>
      {shouldDisplayValidateSubscription && (
        <Button
          isLoading={validateLoading}
          size="sm"
          variant="secondary"
          onClick={handleValidateCompletedSubscription}
        >
          {t('ValidateCase')}
        </Button>
      )}
    </Stack>
  );
}

export type SubscriptionPanelProgressStepsProps = Props;
