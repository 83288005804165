import {
  IdentityShortInfosFragment,
  IdentityType,
  SubscriptionInfosFragment,
} from '@blockpulse3/graphql/hooks';

import { ISecondaryBuyer } from './types';

export function parseBuyerFromSubscription(subscriber: SubscriptionInfosFragment): ISecondaryBuyer {
  const { buyerIdentity } = subscriber;
  return {
    identityId: buyerIdentity?.id || '',
    name: buyerIdentity?.name || '',
    email: buyerIdentity?.email || '',
    profilePicture: buyerIdentity?.profilePicture || '',
    type: buyerIdentity?.type || IdentityType.COMPANY,
    identifier: buyerIdentity?.identifier || '',
    isVerified: buyerIdentity?.isVerified || false,
    investAmount: subscriber.investAmount || 0,
    subscriptionId: subscriber.id,
  };
}

export function parseBuyerFromIdentity(identity: IdentityShortInfosFragment): ISecondaryBuyer {
  return {
    identityId: identity?.id || '',
    name: identity?.name || '',
    email: identity?.email || '',
    identifier: identity.identifier || '',
    profilePicture: identity?.profilePicture || '',
    type: identity?.type || IdentityType.COMPANY,
    isVerified: identity?.isVerified || false,
    investAmount: 0,
  };
}
