import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Card,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ArchiveIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CaptableInfosFragment,
  IdentityType,
  IdentityVerificationStatus,
  useGetOptionAssetsQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberPercentage } from '@blockpulse3/helpers';
import {
  AssetBadge,
  ErrorQueryCard,
  IdentityAvatar,
  TableContainer,
} from '@blockpulse3/ui/commons';

type Props = {
  capTable: CaptableInfosFragment | null | undefined;
};

/**
 * ShareholderTable.
 *
 * @returns {JSX.Element}
 */
export function ShareholderTable({ capTable }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const optionAssetReq = useGetOptionAssetsQuery({ variables: { companyId } });

  if (optionAssetReq.loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  if (optionAssetReq.loading) {
    return <ErrorQueryCard />;
  }

  const shareholders = capTable?.shareholders || [];
  const optionAssets = optionAssetReq?.data?.getOptionAssets || [];
  const fullyDilutedTotalSupply = capTable?.fullyDilutedTotalSupply || 1;

  return (
    <TableContainer maxHeight="none">
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Shareholder', { nb: 1 })}</Th>
            <Th>{t('HoldingAssets')}</Th>
            <Th isNumeric>{t('CapitalOwnershipFullyDiluted')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!shareholders.length ? (
            <Tr>
              <Td>
                <Text>{t('NoFilterResult')}</Text>
              </Td>
            </Tr>
          ) : (
            <>
              {shareholders.map((shareholder, index) => {
                const isCompany = shareholder.identity?.type === IdentityType.COMPANY;
                return (
                  <Tr key={`${index}-${shareholder.identity.identifier}`}>
                    <Td>
                      <HStack spacing="2.5">
                        <IdentityAvatar identity={shareholder.identity} />
                        <Stack spacing="1">
                          <HStack spacing="1">
                            <Text fontWeight="600">{shareholder.identity?.name}</Text>
                            {shareholder.verificationStatus ===
                              IdentityVerificationStatus.APPROVED && (
                              <Icon as={CheckCircleIcon} boxSize="15px" color="green.400" />
                            )}
                            {isCompany && (
                              <Text fontWeight="500">({shareholder.identity?.identifier})</Text>
                            )}
                          </HStack>
                          <Text color="gray.500" fontSize="sm" fontWeight="400">
                            {shareholder.identity?.email}
                          </Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>
                      <AssetBadge type={shareholder.assetType} value={shareholder.shareCount} />
                    </Td>
                    <Td isNumeric fontWeight="600">
                      <Text>
                        {formatNumberPercentage(shareholder.capitalHeld)} (
                        {formatNumberPercentage(shareholder.fullyDilutedCapitalHeld)})
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
            </>
          )}
          {[...optionAssets]
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((asset) => {
              if (asset.token?.__typename !== 'OptionTokenModel') return null;
              const percentage =
                (asset.token.conversionParity * asset.token.remainingSupply * 100) /
                fullyDilutedTotalSupply;

              return (
                <Tr key={asset.token.code}>
                  <Td>
                    <HStack spacing="2">
                      <Flex
                        alignItems="center"
                        bg="gray.100"
                        boxSize="9"
                        flexShrink="0"
                        justifyContent="center"
                        rounded="sm"
                      >
                        <Icon as={ArchiveIcon} boxSize="50%" />
                      </Flex>
                      <Text fontWeight="600">{asset.name}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <AssetBadge
                      grayed={true}
                      type={asset.assetType}
                      value={asset.token.remainingSupply}
                    />
                  </Td>
                  <Td isNumeric fontWeight="600">
                    <Text>({formatNumberPercentage(percentage)})</Text>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type ShareholderTableProps = Props;
