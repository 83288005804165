import { Box, Icon, Step, StepIndicator, StepSeparator, StepStatus } from '@chakra-ui/react';
import {
  CashIcon,
  CheckCircleIcon,
  CurrencyEuroIcon,
  DocumentRemoveIcon,
  DocumentTextIcon,
  ReceiptRefundIcon,
  RefreshIcon,
  ShieldCheckIcon,
  UploadIcon,
} from '@heroicons/react/solid';
import { ElementType } from 'react';

import { ActivityInfosFragment, ActivityType } from '@blockpulse3/graphql/hooks';

import { ActivityDocumentReset } from './ActivityDocumentReset';
import { ActivityDocumentSigned } from './ActivityDocumentSigned';
import { ActivityDocumentUploaded } from './ActivityDocumentUploaded';
import { ActivityFundsSourceCertificateRefused } from './ActivityFundsSourceCertificateDate';
import { ActivityInvestAmountReviewed } from './ActivityInvestAmountReviewed';
import { ActivitySubscriptionCompleted } from './ActivitySubscriptionCompleted';
import { ActivitySubscriptionValidated } from './ActivitySubscriptionValidated';
import { ActivityTransferReceived } from './ActivityTransferReceived';
import { ActivityTransferSent } from './ActivityTransferSent';

type Props = {
  activity: ActivityInfosFragment;
};

const activityIconMap: Record<ActivityType, ElementType> = {
  [ActivityType.TRANSFER_RECEIVED]: CashIcon,
  [ActivityType.TRANSFER_SENT]: ReceiptRefundIcon,
  [ActivityType.DOCUMENT_SIGNED]: DocumentTextIcon,
  [ActivityType.DOCUMENT_RESET]: RefreshIcon,
  [ActivityType.DOCUMENT_UPLOADED]: UploadIcon,
  [ActivityType.FUNDS_SOURCE_CERTIFICATE_REFUSED]: DocumentRemoveIcon,
  [ActivityType.SUBSCRIPTION_COMPLETED]: CheckCircleIcon,
  [ActivityType.SUBSCRIPTION_VALIDATED]: ShieldCheckIcon,
  [ActivityType.INVEST_AMOUNT_REVIEWED]: CurrencyEuroIcon,
};

/**
 * Activity.
 * Displays the details of an activity within a step of a Stepper.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function Activity({ activity }: Props): JSX.Element {
  const { subscriptionId, data, type, createdAt: date } = activity;
  const ActivityIcon = activityIconMap[type];

  const RenderActivityComponent = (): JSX.Element | null => {
    switch (data.__typename) {
      case 'TransferReceivedData': {
        return <ActivityTransferReceived data={data} date={date} subscriptionId={subscriptionId} />;
      }
      case 'TransferSentData': {
        return <ActivityTransferSent data={data} date={date} />;
      }
      case 'DocumentResetData': {
        return <ActivityDocumentReset data={data} date={date} />;
      }
      case 'DocumentSignedData': {
        return <ActivityDocumentSigned data={data} date={date} />;
      }
      case 'DocumentUploadedData': {
        return <ActivityDocumentUploaded data={data} date={date} />;
      }
      case 'FundsSourceCertificateRefusedData': {
        return <ActivityFundsSourceCertificateRefused data={data} date={date} />;
      }
      case 'SubscriptionCompletedData': {
        return <ActivitySubscriptionCompleted date={date} />;
      }
      case 'SubscriptionValidatedData': {
        return <ActivitySubscriptionValidated data={data} date={date} />;
      }
      case 'InvestAmountReviewedData': {
        return <ActivityInvestAmountReviewed data={data} date={date} />;
      }
      default:
        return null;
    }
  };
  return (
    <Step style={{ width: '100%' }}>
      <StepIndicator>
        <StepStatus incomplete={<Icon as={ActivityIcon} boxSize="16px" color="gray.400" />} />
      </StepIndicator>
      <Box flex="1" fontSize="xs" mb="8">
        <RenderActivityComponent />
      </Box>
      <StepSeparator />
    </Step>
  );
}

export type ActivityProps = Props;
