import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { MenuListProps, SingleValueProps, chakraComponents } from 'chakra-react-select';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, noop } from '@blockpulse3/data/shared';
import { AssetType } from '@blockpulse3/graphql/hooks';

import { OperationAssetOption, OperationAssetOptionGroup } from './types';

type OperationAssetMenuListProps = MenuListProps<
  OperationAssetOption,
  false,
  OperationAssetOptionGroup
> & {
  onClick?: () => void;
  isAddButtonDisabled?: boolean;
};

/**
 * MenuList.
 * Custom menu list component, add a link to the settings.
 *
 * @param {HoldingMethodMenuListProps} props
 * @returns {JSX.Element}
 */
export function MenuList({
  isAddButtonDisabled = false,
  onClick = noop,
  ...props
}: OperationAssetMenuListProps): JSX.Element {
  const t = useTranslations();

  const handleClick = (): void => {
    onClick();
  };

  const isFeatureOperationBond = isFeatureEnabled('fundraisingBond');

  return (
    <chakraComponents.MenuList {...props}>
      {props.children}
      {isFeatureOperationBond && (
        <HStack height="40px" px="3">
          <Button
            isDisabled={isAddButtonDisabled}
            leftIcon={<Icon as={PlusIcon} boxSize="18px" color="gray.600" />}
            variant="link"
            onClick={handleClick}
          >
            <Text fontSize="sm">{t('NewBond')}</Text>
          </Button>
        </HStack>
      )}
    </chakraComponents.MenuList>
  );
}

type OperationAssetSingleValueProps = SingleValueProps<
  OperationAssetOption,
  false,
  OperationAssetOptionGroup
> & {
  /* ** Callback on modify button click, open Modal ** */
  onModifyClick?: (data: OperationAssetOption) => void;
};

export function SingleValue({
  onModifyClick = noop,
  ...props
}: OperationAssetSingleValueProps): JSX.Element {
  const t = useTranslations();

  const showModify = props.data.type === AssetType.BOND;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onModifyClick(props.data);
  };

  return (
    <chakraComponents.SingleValue {...props}>
      <Text maxWidth="200px" variant="ellipsis">
        {props.children}
      </Text>
      {showModify && (
        <Button flexShrink="0" size="xs" variant="ghost" onClick={handleClick}>
          {t('Modify')}
        </Button>
      )}
    </chakraComponents.SingleValue>
  );
}
