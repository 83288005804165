import * as yup from 'yup';

import { requiredStringField } from '@blockpulse3/data/shared';
import { BankDistributionType } from '@blockpulse3/graphql/hooks';

export const bankDistributionTypeOptions = [
  { label: BankDistributionType.STOCK_DIVIDEND, value: BankDistributionType.STOCK_DIVIDEND },
  { label: BankDistributionType.BOND_INTEREST, value: BankDistributionType.BOND_INTEREST },
  {
    label: BankDistributionType.BOND_REIMBURSEMENT,
    value: BankDistributionType.BOND_REIMBURSEMENT,
  },
];

export const createBankDistributionSchema = yup.object({
  distributionName: requiredStringField,
  recipients: requiredStringField,
});
