import {
  Icon,
  IconButton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { TrashIcon, UserGroupIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  Identity,
  IdentityType,
  OperationInfosFragment,
  ShareholderInfosFragment,
  useGetCompanyCaptableQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import {
  AssetBadge,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  TableContainer,
} from '@blockpulse3/ui/commons';

type Props = {
  /* ** Table identity table values ** */
  values: OperationInfosFragment['authorizedSellers'];
  /* ** Callback on susbcription seller delete ** */
  onDeleteSeller?: (id: string) => void;
};

/**
 * NewSecondaryOperationSellerTable.
 * Render available sellers of an operation as subscription infos.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationSellerTable({
  values = [],
  onDeleteSeller = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '', operationId = '' } = useParams();

  const { data: operationData, loading: operationLoading } = useGetOperationQuery({
    variables: { operationId },
  });
  const { data, loading, error } = useGetCompanyCaptableQuery({ variables: { companyId } });

  if (!values || values?.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={UserGroupIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoSeller')}</Text>
      </Stack>
    );
  }

  if (loading || operationLoading) {
    return (
      <Stack layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  const capTable = data?.company?.capTable;
  const shareholders = capTable?.shareholders || [];

  /* ** Return shareholder first match from the captable ** */
  const getCapTableEntry = (identityId: Identity['id']): ShareholderInfosFragment | undefined => {
    return shareholders.find(
      (key) =>
        key.identity.id === identityId && key.assetId === operationData?.operation?.asset?.id,
    );
  };

  const handleDeleteSeller = (id: string): void => {
    onDeleteSeller(id);
  };

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Seller', { nb: 1 })}</Th>
            <Th>{t('HoldingAssets')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {values.map((seller) => {
            const capTableEntry = getCapTableEntry(seller.id);

            return (
              <Tr key={seller.id}>
                <Td fontWeight="600">
                  <IdentityCard>
                    <IdentityAvatar boxSize="9" src={seller.profilePicture} type={seller.type} />
                    <Stack spacing="0">
                      <IdentityCardTitle
                        fontSize="md"
                        fontWeight="500"
                        isChecked={seller.isVerified}
                      >
                        <Text fontWeight="600">{seller.name}</Text>
                        {seller.type === IdentityType.COMPANY && (
                          <Text fontWeight="500">({seller.identifier})</Text>
                        )}
                      </IdentityCardTitle>
                      <IdentityCardDescription>
                        <Text color="gray.500" fontSize="sm">
                          {seller.email}
                        </Text>
                      </IdentityCardDescription>
                    </Stack>
                  </IdentityCard>
                </Td>
                <Td>
                  {capTableEntry ? (
                    <AssetBadge type={capTableEntry.assetType} value={capTableEntry.shareCount} />
                  ) : (
                    <Text>-</Text>
                  )}
                </Td>
                <Td padding="0">
                  <IconButton
                    aria-label="delete-seller"
                    icon={<Icon as={TrashIcon} boxSize="4" />}
                    size="sm"
                    variant="icon-delete"
                    onClick={(): void => handleDeleteSeller(seller.id)}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type NewSecondaryOperationSellerTableProps = Props;
