import { AssetInfosFragment } from '@blockpulse3/graphql/hooks';

import { IBondAssetDraft, bondAssetDraftDefaultValues } from '../CreateBondAssetDraftModal';
import { OperationAssetOption } from './types';

export function getBondTokenDraftInfos(draft?: AssetInfosFragment | null): IBondAssetDraft {
  if (!draft || !draft.data || draft.data.__typename !== 'BondAssetData') {
    return bondAssetDraftDefaultValues;
  }

  return {
    assetId: draft.id,
    assetType: { label: draft.assetType, value: draft.assetType },
    durationUnit: { label: draft.data.durationUnit, value: draft.data.durationUnit },
    name: draft.name,
    date: draft.data.date,
    period: draft.data.period,
    maturityDate: draft.data.maturityDate,
    nominalValue: draft.data.nominalValue,
    issuancePremium: draft.data.issuancePremium,
    redemptionPremium: draft.data.redemptionPremium,
    interestRate: draft.data.interestRate,
    interestPeriod: draft.data.interestPeriod,
    interestPaymentInterval: draft.data.interestPaymentInterval,
    reimbursementInterval: draft.data.reimbursementInterval,
    transferable: draft.data.transferable,
    accelerable: draft.data.accelerable,
    extendable: draft.data.extendable,
  };
}

/**
 * getBondTokenDraftsOptions.
 * Returns draft bonds asset options to fill configuration drop down.
 *
 * @param {AssetInfosFragment[]} [bondAssetDrafts]
 * @returns {OperationAssetOption[]}
 */
export function getBondTokenDraftsOptions(
  bondAssetDrafts?: AssetInfosFragment[],
): OperationAssetOption[] {
  if (!bondAssetDrafts) {
    return [];
  }

  return bondAssetDrafts.map((draft) => ({
    label: draft.name,
    type: draft.assetType,
    value: draft.id,
    data: getBondTokenDraftInfos(draft),
  }));
}
