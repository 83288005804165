import { Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { RepresentativeRole, useGetSpvQuery } from '@blockpulse3/graphql/hooks';
import { CardIdentity } from '@blockpulse3/ui/commons';

/**
 * SummaryRoleInfos.
 * Summary of the role for the SPV funnel.
 *
 * @returns {JSX.Element}
 */
export function SummaryRoleInfos(): JSX.Element {
  const t = useTranslations();
  const i18nRoleValues = useTranslations('RoleValues');

  const { companyId = '' } = useParams();

  const { data } = useGetSpvQuery({ variables: { companyId } });

  const representatives = data?.company.companyRepresentative || [];
  const president = representatives.find((rep) => {
    return rep.role === RepresentativeRole.PRESIDENT;
  });

  const presidentIdentity = president?.representativeIdentity;
  const subTitle = presidentIdentity?.name;

  const notCreatorOrViewerIdentityIds = representatives
    .filter((rep) => ![RepresentativeRole.CREATOR, RepresentativeRole.VIEWER].includes(rep.role))
    .map((rep) => rep.representativeIdentity.id);

  // Remove creator and viewers that have other roles
  const otherRoles = representatives.filter(
    (rep) =>
      rep.role !== RepresentativeRole.PRESIDENT &&
      !(
        [RepresentativeRole.CREATOR, RepresentativeRole.VIEWER].includes(rep.role) &&
        notCreatorOrViewerIdentityIds.includes(rep.representativeIdentity.id)
      ),
  );

  return (
    <Stack spacing="4">
      <Text fontSize="xl" fontWeight="bold">
        {t('RolesAndAccess')}
      </Text>
      <CardIdentity
        subTitle={subTitle}
        title={i18nRoleValues(RepresentativeRole.PRESIDENT)}
        type={presidentIdentity?.type}
      />
      {otherRoles.map((otherRole) => {
        const identity = otherRole.representativeIdentity;
        const subTitle = identity.name;

        // if role is CREATOR display in front "Collaborateur"
        const roleTitle =
          otherRole.role === RepresentativeRole.CREATOR
            ? i18nRoleValues(RepresentativeRole.VIEWER)
            : i18nRoleValues(otherRole.role);
        return (
          <CardIdentity
            key={identity.id}
            subTitle={subTitle}
            title={roleTitle}
            type={otherRole.representativeIdentity.type}
          />
        );
      })}
    </Stack>
  );
}
