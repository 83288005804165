import { LinkIcon } from '@chakra-ui/icons';
import { Avatar, HStack, Icon, Link, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import { IdentityType } from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PhoneBanner,
  UserIdentityAvatar,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useManagedIndividual } from '@blockpulse3/web-client/auth';
import { Wallet } from '@blockpulse3/web-client/shareholding';

import { GenderPicker } from './GenderPicker';
import { IdentityHeader } from './IdentityHeader';
import { InvitationList } from './InvitationList';

type Props = unknown;

/**
 * UserDashboardView.
 * Dashboard of the authentified user.
 *
 * @returns {JSX.Element}
 */
export function UserDashboardView(): JSX.Element {
  const t = useTranslations();

  const isManagementRoute = useMatch(routes.space.href + '/*');

  const { individual, refetch } = useManagedIndividual();
  const { user: authUser } = useAuthUser();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');

  const handleFormSubmit = (): void => {
    refetch();
  };

  const profilePicture = individual?.identity?.profilePicture || '';
  const isVerified = individual?.identity?.isVerified || false;

  return (
    <Stack spacing="6">
      {!authUser?.individualIdentity?.gender && (
        <GenderPicker defaultValues={{ gender: '' }} user={authUser} onSubmit={handleFormSubmit} />
      )}
      <IdentityHeader>
        <IdentityCard onMouseEnter={onOpen} onMouseLeave={onClose}>
          <IdentityAvatar boxSize="14" src={profilePicture} type={IdentityType.INDIVIDUAL} />
          <Stack spacing="0">
            <HStack spacing="3">
              <IdentityCardTitle isChecked={isVerified}>
                <Text>{individual?.name}</Text>
                {isManagementRoute && (
                  <HStack spacing="1">
                    <Avatar
                      bg="transparent"
                      borderColor="gray.900"
                      borderWidth="1px"
                      boxSize="4"
                      icon={<Icon as={LinkIcon} boxSize="50%" color="gray.900" />}
                    />
                    <UserIdentityAvatar
                      borderColor="gray.900"
                      borderWidth="1px"
                      boxSize="4"
                      src={authUser?.individualIdentity?.identity?.profilePicture}
                    />
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      maxWidth="130px"
                      overflow="hidden"
                      textAlign="left"
                      variant="ellipsis"
                    >
                      {`${authUser?.individualIdentity?.firstName} ${authUser?.individualIdentity?.lastName}`}
                    </Text>
                  </HStack>
                )}
              </IdentityCardTitle>
              {isOpen && (
                <HStack fontSize="sm" spacing="1">
                  <Icon as={PencilIcon} />
                  <Link fontWeight="400" href={routes.me.settings.href}>
                    {t('EditInformation')}
                  </Link>
                </HStack>
              )}
              <Link
                display={{ base: 'block', md: 'none' }}
                fontWeight="400"
                href={routes.me.settings.href}
              >
                <Icon as={PencilIcon} />
              </Link>
            </HStack>
            <IdentityCardDescription>
              <Text>{individual?.address?.formatted}</Text>
              <Text>{individual?.email}</Text>
              <Text>{individual?.phone}</Text>
            </IdentityCardDescription>
          </Stack>
        </IdentityCard>
      </IdentityHeader>
      {renderPhoneBanner && <PhoneBanner />}
      <Wallet />
      <InvitationList />
    </Stack>
  );
}

export type UserDashboardViewProps = Props;
