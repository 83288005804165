import * as yup from 'yup';

import {
  requiredFutureDateField,
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
  requiredTodayOrFutureDateField,
} from '@blockpulse3/data/shared';
import { AssetType } from '@blockpulse3/graphql/hooks';
import { defaultFiscalAdvantageOptions } from '@blockpulse3/web-client/operation/commons';

import { IOpportunityOperationInformationsForm } from './types';

export const opportunityOperationInformationsSchema = yup.object({
  name: requiredStringField,
  startSubscriptionDate: requiredTodayOrFutureDateField,
  endSubscriptionDate: requiredFutureDateField,
  asset: requiredSelectField,
  sharePrice: yup.number().when('asset', {
    is: (asset: { type: AssetType }) => asset.type === AssetType.ORDINARY_SHARE,
    then: () => requiredNumberField,
  }),
}) as yup.ObjectSchema<IOpportunityOperationInformationsForm>;

export const opportunityOperationInformationsDefaults: IOpportunityOperationInformationsForm = {
  name: '',
  startSubscriptionDate: '',
  endSubscriptionDate: '',
  asset: {
    label: 'default',
    value: AssetType.ORDINARY_SHARE,
    type: AssetType.ORDINARY_SHARE,
  },
  sharePrice: 0,
  maximalAmount: null,
  minimalAmount: null,
  allowedFiscalAdvantages: defaultFiscalAdvantageOptions,
};
