import {
  GetSubscriptionQuery,
  IdentityType,
  MergedSubscriptionDetailsStep,
} from '@blockpulse3/graphql/hooks';

import { IOperationSubscriber } from '../../OperationSubscribersModal';

export const formatSubscription = (
  subscription: GetSubscriptionQuery['subscription'],
): IOperationSubscriber => {
  const { buyerIdentity } = subscription;

  const subscriptionDetailsStep = subscription?.data.mergedSteps.find(
    (step) => step.__typename === 'MergedSubscriptionDetailsStep',
  ) as MergedSubscriptionDetailsStep;
  const minimalAmount =
    subscription?.minimalAmount || subscriptionDetailsStep?.minimalAmount || null;
  const maximalAmount =
    subscription?.maximalAmount || subscriptionDetailsStep?.maximalAmount || null;

  return {
    isLegal: buyerIdentity?.type === IdentityType.COMPANY,
    subscriber: {
      admissionFees: subscription.admissionFees,
      amount: subscription.investAmount,
      email: buyerIdentity?.email || '',
      firstName: buyerIdentity?.individualIdentity?.firstName || '',
      lastName: buyerIdentity?.individualIdentity?.lastName || '',
      identificationNumber: buyerIdentity?.companyIdentity?.registrationNumber || '',
      name: buyerIdentity?.companyIdentity?.name || '',
      verified: buyerIdentity?.isVerified || false,
      maximalAmount,
      minimalAmount,
    },
  };
};
