import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  Operation,
  OperationType,
  useCompleteOperationMutation,
  useGetAssetsByCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import { completeOperationFormDefaults, completeOperationFormSchema } from './schema';
import { ICompleteOperationForm } from './type';

type Props = {
  onClose: () => void;
  operationId: Operation['id'];
  onCompleted: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

export function CompleteOperationModal({
  onClose,
  operationId,
  onCompleted,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nOperationTypes = useTranslations('OperationTypes');

  const { companyId = '' } = useParams();

  const [completeOperation, { loading }] = useCompleteOperationMutation();

  const { data } = useGetOperationQuery({
    variables: {
      operationId,
    },
    skip: !operationId,
  });
  const { data: assetData } = useGetAssetsByCompanyQuery({ variables: { companyId } });
  const assets = assetData?.getAssetsByCompany || [];

  const operation = data?.operation;

  const typeOptions = Object.keys(OperationType).map((type) => ({
    label: i18nOperationTypes(type as OperationType),
    value: type,
  }));

  const assetOptions = assets.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const handleModalClose = (): void => {
    onClose();
  };

  const { register, control, formState, handleSubmit, reset } = useForm<ICompleteOperationForm>({
    defaultValues: completeOperationFormDefaults,
    resolver: yupResolver(completeOperationFormSchema),
  });

  useEffect(() => {
    reset({
      ...completeOperationFormDefaults,
      name: operation?.name || '',
      type: operation?.type
        ? typeOptions.find((op) => op.value === operation.type)
        : { label: '', value: '' },
      asset: operation?.asset?.id
        ? assetOptions.find((a) => a.value === operation.asset?.id)
        : { label: '', value: '' },
      sharePrice: operation?.sharePrice || 0,
      closingDate: operation?.closingDate ? formatDate(operation.closingDate, 'YYYY-MM-DD') : '',
    });
  }, [operation, reset]);

  const handleFormSubmit: SubmitHandler<ICompleteOperationForm> = (formData) => {
    if (!operation) return;

    completeOperation({
      variables: {
        completeOperationInput: {
          operationId,
          name: formData.name,
          type: formData.type.value as OperationType,
          assetId: formData.asset.value,
          sharePrice: formData.sharePrice,
          closingDate: formData.closingDate,
        },
      },
      onCompleted: () => {
        onCompleted();
      },
    });
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('CompleteOperation')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Alert mb="4" status="info">
            <AlertIcon />
            <AlertTitle>{t('ImportedOperationToComplete')}</AlertTitle>
          </Alert>
          <form id="complete-operation" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors?.name}>
                <FormLabel htmlFor="name">{t('OperationName')}</FormLabel>
                <Input id="name" type="text" {...register('name')} />
                <ErrorMessage error={formState.errors?.name} />
              </FormControl>
              <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.type}>
                      <FormLabel htmlFor="type">{t('OperationType')}</FormLabel>
                      <Select id="type" menuPlacement="auto" options={typeOptions} {...field} />
                      <ErrorMessage error={formState.errors?.type?.value} />
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="asset"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.asset}>
                      <FormLabel htmlFor="asset">{t('Asset', { nb: 1 })}</FormLabel>
                      <Select id="asset" menuPlacement="auto" options={assetOptions} {...field} />
                      <ErrorMessage error={formState.errors?.asset?.value} />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.sharePrice}>
                  <FormLabel htmlFor="sharePrice">
                    <HStack>
                      <Text>{t('PricePerShare')}</Text>
                      <Tooltip hasArrow label={t('PricePerShareTooltip')} placement="top">
                        <Icon as={InfoIcon} color="gray.600" />
                      </Tooltip>
                    </HStack>
                  </FormLabel>
                  <InputGroup>
                    <Input id="sharePrice" step="0.01" type="number" {...register('sharePrice')} />
                    <InputRightElement color="gray.500">€</InputRightElement>
                  </InputGroup>
                  <ErrorMessage error={formState.errors.sharePrice} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.closingDate}>
                  <FormLabel htmlFor="closingDate">{t('OperationEndDate')}</FormLabel>
                  <Input id="closingDate" type="date" {...register('closingDate')} />
                  <ErrorMessage error={formState.errors.closingDate} />
                </FormControl>
              </Stack>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form="complete-operation" isDisabled={loading} isLoading={loading} type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
