import { AssetType, OperationInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { AllowedFiscalAdvantageOption } from '@blockpulse3/web-client/operation/commons';

import { IOpportunityOperationInformationsForm } from './types';

export function getOperationParametersDefaults(
  operation: OperationInfosFragment,
): IOpportunityOperationInformationsForm {
  const purchaseIntentStep = operation.subscriptionSteps?.find(
    (step) => step.__typename === 'PurchaseIntentStepType',
  );

  // Default minimal and maximal amount
  let minimalAmount = null;
  let maximalAmount = null;
  if (purchaseIntentStep && purchaseIntentStep.__typename === 'PurchaseIntentStepType') {
    minimalAmount = purchaseIntentStep.minimalAmount ?? null;
    maximalAmount = purchaseIntentStep.maximalAmount ?? null;
  }

  // Default fiscal advantages
  const fiscalAdvantagesOption: AllowedFiscalAdvantageOption[] =
    operation?.allowedFiscalAdvantages?.map((fiscalAdvantage) => {
      return {
        label: fiscalAdvantage.toString(),
        value: fiscalAdvantage,
      };
    }) || [];

  // Default asset
  const { asset } = operation;
  let label = 'default';
  let value = AssetType.ORDINARY_SHARE.toString();
  let assetType = AssetType.ORDINARY_SHARE;
  if (asset) {
    label = asset.name || 'default';
    value = asset.id;
    assetType = asset.assetType;
  }

  return {
    name: operation.name || '',
    startSubscriptionDate: formatDate(operation.startSubscriptionDate, 'YYYY-MM-DD' || ''),
    endSubscriptionDate: formatDate(operation.endSubscriptionDate, 'YYYY-MM-DD' || ''),
    asset: { label, value, type: assetType },
    sharePrice: operation.sharePrice || 0,
    minimalAmount,
    maximalAmount,
    allowedFiscalAdvantages: fiscalAdvantagesOption,
  };
}
