import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  SearchIcon,
  UploadIcon,
  UserAddIcon,
  UserGroupIcon,
  UserRemoveIcon,
} from '@heroicons/react/outline';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  SubscriptionSide,
  useCreateDefaultAuthorizedIdentitiesMutation,
  useDeleteAuthorizedIdentitiesMutation,
  useGetOperationQuery,
  useGetSubscriptionsQuery,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import {
  OperationCSVType,
  OperationSubscribersCSVModal,
} from '@blockpulse3/web-client/operation/commons';

import { NewSecondaryOperationBuyerModal } from './NewSecondaryOperationBuyerModal';

type Props = {
  onSearchInputChange: (input: string) => void;
};

/**
 * NewSecondaryOperationBuyerTableControls.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationBuyerTableControls({
  onSearchInputChange,
}: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const confirmRef = useRef(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const buyerCreateModal = useDisclosure();
  const buyersImportModal = useDisclosure();
  const buyersDeleteModal = useDisclosure();

  const {
    data,
    refetch: refetchOperation,
    loading: operationLoading,
  } = useGetOperationQuery({
    variables: { operationId },
    notifyOnNetworkStatusChange: true,
  });
  const operation = data?.operation;
  const opportunityId = operation?.opportunityId;
  const hasUniqueSeller = operation?.authorizedSellers?.length === 1;

  const { refetch: refetchSubscriptions, loading: subscriptionsLoading } = useGetSubscriptionsQuery(
    {
      variables: { operationId, first: 150 },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetch = (): void => {
    refetchOperation();
    if (hasUniqueSeller) refetchSubscriptions();
  };

  const [createAuthorizedBuyers, { loading: createAuthorizedBuyersLoading }] =
    useCreateDefaultAuthorizedIdentitiesMutation();
  const [deleteAuthorizedBuyers, { loading: deleteAuthorizedBuyersLoading }] =
    useDeleteAuthorizedIdentitiesMutation();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onSearchInputChange(event.target.value);
  };

  const handleCreateBuyer = (): void => {
    buyerCreateModal.onOpen();
  };

  const handleCreateAllBuyers = (): void => {
    if (!operationId) return;

    createAuthorizedBuyers({
      variables: {
        createDefaultAuthorizedIdentities: {
          operationId,
          identitySide: SubscriptionSide.BUYER,
        },
      },
      onCompleted: () => {
        refetch();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingBuyers') });
      },
    });
  };

  const handleDeleteAllBuyers = (): void => {
    if (!operationId) return;

    deleteAuthorizedBuyers({
      variables: {
        deleteAuthorizedIdentities: {
          operationId,
          identitySide: SubscriptionSide.BUYER,
        },
      },
      onCompleted: () => {
        refetch();
        buyersDeleteModal.onClose();
      },
    });
  };

  const handleBuyerSubmitComplete = (): void => {
    successToast({ title: t('BuyerAdded', { nb: 1 }) });
    buyerCreateModal.onClose();
    refetch();
  };

  const handleBuyerSubmitError = (): void => {
    errorToast({ title: t('ErrorAddingBuyer') });
  };

  const handleImportBuyers = (): void => {
    buyersImportModal.onOpen();
  };

  const addBuyersDisabled =
    !operation || (!!opportunityId && (operation.subscriptionCount > 0 || !hasUniqueSeller));
  const deleteBuyersDisabled = !operation?.authorizedBuyers?.length;

  const isLoading =
    createAuthorizedBuyersLoading ||
    deleteAuthorizedBuyersLoading ||
    subscriptionsLoading ||
    operationLoading;

  return (
    <Stack>
      <Heading size="md">{t('BuyersList')}</Heading>
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" spacing="4">
        <InputGroup w={{ base: 'full', md: '300px' }}>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} boxSize="18px" />
          </InputLeftElement>
          <Input placeholder={t('SearchBuyer')} onChange={handleSearchInputChange} />
        </InputGroup>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="2">
          <Button
            isDisabled={isLoading}
            leftIcon={<Icon as={UserAddIcon} boxSize="24px" />}
            onClick={handleCreateBuyer}
          >
            {t('AddBuyer')}
          </Button>
          <Menu>
            <MenuButton
              as={Button}
              isDisabled={isLoading}
              rightIcon={isLoading ? <Spinner size="xs" /> : <ChevronDownIcon />}
              variant="secondary"
            >
              {t('Action', { nb: 2 })}
            </MenuButton>
            <MenuList>
              <MenuItem
                fontWeight="600"
                icon={<Icon as={UserGroupIcon} boxSize="5" />}
                isDisabled={addBuyersDisabled || isLoading}
                onClick={handleCreateAllBuyers}
              >
                {t(opportunityId ? 'InviteAllInterestedBuyers' : 'AddAllShareholders')}
              </MenuItem>
              <MenuItem
                fontWeight="600"
                icon={<Icon as={UploadIcon} boxSize="5" />}
                onClick={handleImportBuyers}
              >
                {t('CSVImport')}
              </MenuItem>
              <MenuItem
                fontWeight="600"
                icon={<Icon as={UserRemoveIcon} boxSize="5" />}
                isDisabled={deleteBuyersDisabled || isLoading}
                onClick={buyersDeleteModal.onOpen}
              >
                {t('DeleteAllBuyers')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Stack>
      <NewSecondaryOperationBuyerModal
        amountRequired={operation?.authorizedSellers?.length === 1}
        isOpen={buyerCreateModal.isOpen}
        onClose={buyerCreateModal.onClose}
        onSubmitComplete={handleBuyerSubmitComplete}
        onSubmitError={handleBuyerSubmitError}
      />
      <OperationSubscribersCSVModal
        isOpen={buyersImportModal.isOpen}
        operationCsvType={OperationCSVType.SECONDARY_BUYER}
        operationId={operationId}
        onClose={buyersImportModal.onClose}
        onComplete={refetch}
      />
      <DeleteConfirmModal
        isOpen={buyersDeleteModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteBuyerQuestion', { nb: 2 })}
        onClose={buyersDeleteModal.onClose}
        onDelete={handleDeleteAllBuyers}
      />
    </Stack>
  );
}

export type NewSecondaryOperationBuyerTableControlsProps = Props;
