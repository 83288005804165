import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  FiscalAdvantage,
  HoldingMethod,
  OperationStatus,
  useCreateWalletMutation,
  useGetSubscriptionQuery,
  useGetWalletsQuery,
  useUpdateSubscriptionHoldingMethodMutation,
  useUpdateWalletMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  HoldingMethodSelect,
  IWalletForm,
  WalletFormModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { isFiscalAdvantageAllowed } from './utils';

type Props = {
  allowedFiscalAdvantages?: FiscalAdvantage[];
  isSeller?: boolean;
  hasDefaultHoldingMethod?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: () => void;
};

/**
 * SubscriptionHoldingMethod.
 * Does not render if subscription is a company.
 *
 * @param {Props}
 * @returns {JSX.Element | null}
 */
export function SubscriptionHoldingMethod({
  allowedFiscalAdvantages,
  isSeller = false,
  hasDefaultHoldingMethod = true,
  setIsLoading = noop,
  onChange = noop,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const createFormModal = useDisclosure();
  const updateFormModal = useDisclosure();

  const { identityId } = useIdentity();
  const { subscriptionId = '' } = useParams();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  const walletsReq = useGetWalletsQuery({
    variables: { identityId },
    skip: !identityId,
  });

  const [createWallet] = useCreateWalletMutation();
  const [updateWallet] = useUpdateWalletMutation();
  const [updateSubscriptionHoldingMethod] = useUpdateSubscriptionHoldingMethodMutation();

  const [walletPartial, setWalletPartial] = useState<Partial<IWalletForm> | undefined>();

  const { subscription } = subscriptionReq.data || {};
  /* ** Allowed holding methods for filtering ** */
  const subscriptionAllowedFiscalAdvantages =
    allowedFiscalAdvantages || subscription?.operation?.allowedFiscalAdvantages || [];

  if (subscriptionReq.loading || walletsReq.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (subscriptionReq.error || walletsReq.error) {
    return <ErrorQueryCard />;
  }

  if (!subscriptionReq.data || !walletsReq.data || !subscription) {
    return <ErrorQueryCard />;
  }

  /* ** Conditions to add and update holding method ** */
  const canAddHoldingMethod = isFiscalAdvantageAllowed(allowedFiscalAdvantages) && !isSeller;
  const isFundraisingClosed = [OperationStatus.FINALIZED, OperationStatus.CLOSED].includes(
    subscription?.operation.status,
  );

  const handleWalletCreateModalOpening = (holdingMethod: HoldingMethod): void => {
    setWalletPartial({ holdingMethod });
    createFormModal.onOpen();
  };

  const handleWalletUpdateModalOpening = (wallet: IWalletForm): void => {
    setWalletPartial(wallet);
    updateFormModal.onOpen();
  };

  const handleHoldingMethodChange = (holdingMethod: HoldingMethod): void => {
    if (!subscriptionId || !holdingMethod) return;

    setIsLoading(true);
    updateSubscriptionHoldingMethod({
      variables: {
        updateSubscriptionHoldingMethodInput: {
          subscriptionId,
          holdingMethod,
        },
      },
      onCompleted: async () => {
        await subscriptionReq.refetch();
        onChange();
        setIsLoading(false);
      },
      onError: () => {
        errorToast({ title: t('ErrorRecordingOwnershipMode') });
        setIsLoading(false);
      },
    });
  };

  const handleWalletCreate = async (data: IWalletForm): Promise<void> => {
    if (!identityId) return;

    createWallet({
      variables: {
        createWalletInput: {
          identityId,
          bankAccountNumber: data.bankAccountNumber,
          holdingMethod: data.holdingMethod,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: (data) => {
        walletsReq.refetch();
        const wallet = data.createWallet;

        updateSubscriptionHoldingMethod({
          variables: {
            updateSubscriptionHoldingMethodInput: {
              subscriptionId: subscription.id,
              holdingMethod: wallet.holdingMethod,
            },
          },
          onCompleted: () => {
            successToast({ title: t('HoldingMethodRecorded') });
            onChange();
            createFormModal.onClose();
            subscriptionReq.refetch();
          },
          onError: () => {
            errorToast({ title: t('ErrorRecordingOwnershipMode') });
          },
        });
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingOwnershipMode') });
      },
    });
  };

  const handleWalletUpdate = async (data: IWalletForm): Promise<void> => {
    if (!identityId || !data.id) return;

    updateWallet({
      variables: {
        updateWalletInput: {
          walletId: data.id,
          identityId,
          bankAccountNumber: data.bankAccountNumber,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: (data) => {
        walletsReq.refetch();
        const wallet = data.updateWallet;

        updateSubscriptionHoldingMethod({
          variables: {
            updateSubscriptionHoldingMethodInput: {
              subscriptionId: subscription.id,
              holdingMethod: wallet.holdingMethod,
            },
          },
          onCompleted: () => {
            successToast({ title: t('HoldingMethodRecorded') });
            onChange();
            updateFormModal.onClose();
            subscriptionReq.refetch();
          },
          onError: () => {
            errorToast({ title: t('ErrorRecordingOwnershipMode') });
          },
        });
      },
      onError: () => {
        errorToast({ title: t('ErrorRecordingOwnershipMode') });
      },
    });
  };

  const assetId = isSeller ? subscription.operation?.asset?.id || '' : '';

  return (
    <>
      <Stack spacing="2">
        {!isSeller && (
          <HStack>
            <Text fontWeight="500">{t('OnTheSupport')}</Text>
            <Tooltip hasArrow label={t('FiscalAdvantageEligibilityInstructions')} placement="top">
              <Icon as={InfoIcon} color="gray.600" />
            </Tooltip>
          </HStack>
        )}
        <HoldingMethodSelect
          allowedFiscalAdvantages={subscriptionAllowedFiscalAdvantages}
          assetId={assetId}
          defaultValue={subscription?.holdingMethod}
          hasDefaultHoldingMethod={hasDefaultHoldingMethod}
          identityId={identityId}
          isDisabled={isFundraisingClosed}
          onHoldingMethodChange={handleHoldingMethodChange}
          onWalletCreate={handleWalletCreateModalOpening}
          onWalletUpdate={handleWalletUpdateModalOpening}
        />
        {canAddHoldingMethod && (
          <Alert status="warning">
            <AlertIcon as={ExclamationIcon} />
            <AlertTitle>{t('PEAInvestmentInfo')}</AlertTitle>
          </Alert>
        )}
      </Stack>
      {createFormModal.isOpen && (
        <WalletFormModal
          formPartialValues={walletPartial}
          isOpen={createFormModal.isOpen}
          onClose={createFormModal.onClose}
          onSubmit={handleWalletCreate}
        />
      )}
      {updateFormModal.isOpen && (
        <WalletFormModal
          formPartialValues={walletPartial}
          isOpen={updateFormModal.isOpen}
          onClose={updateFormModal.onClose}
          onSubmit={handleWalletUpdate}
        />
      )}
    </>
  );
}
