import { HStack, Icon, Radio, Stack, StackDirection, Text, useRadioGroup } from '@chakra-ui/react';
import { OfficeBuildingIcon, UserIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { IdentityType } from '@blockpulse3/graphql/hooks';

export type SwitchIdentityTypeChange = { prev: IdentityType; next: IdentityType };

type Props = {
  /* ** Current identity type value ** */
  defaultValue?: IdentityType;
  /* ** Direction ** */
  direction?: StackDirection;
  /* ** Is switch group disabled ** */
  disabled?: boolean;
  /* ** On identity type change, return by args previous and next state ** */
  onChange?: (values: SwitchIdentityTypeChange) => void;
};

/**
 * SwitchIdentityType.
 * TODO: use useRadioGroup() but difficult to set value outside component.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SwitchIdentityType({
  defaultValue = IdentityType.COMPANY,
  disabled = false,
  direction = 'row',
  onChange = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const handleRadioChange = (nextValue: IdentityType): void => {
    onChange({ prev: defaultValue, next: nextValue });
  };

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'identityType',
    defaultValue,
    onChange: handleRadioChange,
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  return (
    <Stack direction={direction} spacing="5" {...getRootProps()}>
      <Radio
        isDisabled={disabled}
        {...getRadioProps({ value: IdentityType.COMPANY })}
        variant="switcher"
      >
        <HStack alignItems="center" spacing="3">
          <Icon as={OfficeBuildingIcon} boxSize="24px" />
          <Text className="title">{t('ItIsACompany')}</Text>
        </HStack>
      </Radio>
      <Radio
        isDisabled={disabled}
        {...getRadioProps({ value: IdentityType.INDIVIDUAL })}
        variant="switcher"
      >
        <HStack alignItems="center" spacing="3">
          <Icon as={UserIcon} boxSize="24px" />
          <Text className="title">{t('IndividualPersonType')}</Text>
        </HStack>
      </Radio>
    </Stack>
  );
}

export type SwitchIdentityTypeProps = Props;
