import { Skeleton } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetOperationQuery, useUpdateOpportunityMutation } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';

import { NewOpportunityOperationInformations } from './NewOpportunityOperationInformations';
import { IOpportunityOperationInformationsForm } from './types';
import { getOperationParametersDefaults } from './utils';

/**
 * NewOpportunityOperationParameters.
 *
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationParameters(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { operationId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });

  const [updateOpportunityOperation, { loading: isUpdateLoading }] = useUpdateOpportunityMutation();

  if (loading) {
    return <Skeleton h="400px" width="full" />;
  }

  if (!data || error) {
    return <ErrorQueryCard h="400px" width="full" />;
  }

  const handleFormSubmit = (data: IOpportunityOperationInformationsForm): void => {
    const selectedFiscalAdvantages = data.allowedFiscalAdvantages.map(
      (fiscalAdvantage) => fiscalAdvantage.value,
    );

    const assetId = data.asset.value !== data.asset.type ? data.asset.value : undefined;
    const assetType = data.asset.type;

    updateOpportunityOperation({
      variables: {
        updateOpportunityInput: {
          operationId,
          startSubscriptionDate: data.startSubscriptionDate,
          endSubscriptionDate: data.endSubscriptionDate,
          name: data.name,
          sharePrice: data.sharePrice,
          assetId,
          assetType,
          minimalAmount: data.minimalAmount,
          maximalAmount: data.maximalAmount,
          allowedFiscalAdvantages: selectedFiscalAdvantages,
        },
      },
      onCompleted: () => {
        navigate(routes.company.newOpportunity.edit.documentation.href);
        refetch();
      },
      onError: () => {
        errorToast({ title: t('OperationUpdateError') });
      },
    });
  };

  const defaultValues = getOperationParametersDefaults(data.operation);

  return (
    <NewOpportunityOperationInformations
      defaultValues={defaultValues}
      isLoading={isUpdateLoading}
      onSubmit={handleFormSubmit}
    />
  );
}
