import { Spinner, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useInitiateBankDistributionMutation } from '@blockpulse3/graphql/hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

let isPaymentInitiated = false;

export function InitiateDistributionView(): JSX.Element {
  const t = useTranslations();

  const { distributionId = '', transferId = '' } = useParams();
  const { identityId } = useIdentity();

  const navigate = useNavigate();

  const [initiateBankDistribution] = useInitiateBankDistributionMutation();

  useEffect(() => {
    if (identityId && !isPaymentInitiated) {
      isPaymentInitiated = true;
      initiateBankDistribution({
        variables: {
          initiateBankDistributionInput: {
            bankDistributionId: distributionId,
            bankTransferId: transferId,
          },
        },
        onCompleted: (data) => {
          setTimeout(() => {
            const initiatePaymentUrl = data.initiateBankDistribution;
            window.location.href = initiatePaymentUrl;
          }, 2000);
        },
        onError: () => {
          navigate(routes.closePopup.href);
        },
      });
    }
  }, [identityId, transferId, navigate, initiateBankDistribution]);

  return (
    <Stack alignItems="center" h="full" justifyContent="center" p="8" spacing="4">
      <Text color="gray.600" fontSize="lg" fontWeight="600">
        {t('PreparingCreditTransfer', { nb: transferId ? 1 : 2 })}
      </Text>
      <Spinner color="gray.600" />
    </Stack>
  );
}
