import * as yup from 'yup';

import { requiredNumberField, requiredStringField } from '@blockpulse3/data/shared';

export const extendOperationSchema = yup.object({
  duration: requiredNumberField,
});

export const extendFundraisingOperationSchema = yup.object({
  duration: requiredNumberField,
  pvExerciceFile: requiredStringField,
});
