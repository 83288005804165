import {
  Card,
  Link as ChakraLink,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  GetManagementsDocument,
  GetManagementsQuery,
  GetManagementsQueryVariables,
  Identity,
  IdentityType,
  ManagementType,
  Manager,
  ManagerEntityInfosFragment,
  Member,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PaginationButtons,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../utils';

type Props = {
  type: ManagementType;
  /* ** Callback on delete button click ** */
  onRowDeleteClick?: (managerId: Manager['id']) => void;
};

/**
 * SpaceManagementsTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceManagementsTable({ type, onRowDeleteClick = noop }: Props): JSX.Element {
  const t = useTranslations();

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const [tooltipId, setTooltipId] = useState<Member['id'] | null>(null);

  const paginationProps = usePagination<
    GetManagementsQuery,
    GetManagementsQueryVariables,
    ManagerEntityInfosFragment
  >({
    queryDocument: GetManagementsDocument,
    queryOptions: {
      variables: {
        spaceId,
        first: PAGE_SIZE,
        type,
      },
      skip: !spaceId,
      fetchPolicy: 'network-only',
    },
    dataName: 'managers',
    pageSize: PAGE_SIZE,
  });

  if (paginationProps.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (!paginationProps.results) {
    return <ErrorQueryCard h="200px" />;
  }

  const handleIdCopyClick = (id: Identity['id']): void => {
    navigator.clipboard.writeText(id);
    setTooltipId(id);
    setTimeout(() => {
      setTooltipId(null);
    }, 3000);
  };

  const handleRowDeleteClick = (id: Manager['id']): void => {
    onRowDeleteClick(id);
  };

  const { results } = paginationProps;

  if (results.length === 0) {
    return (
      <Stack layerStyle="emptyState">
        <Text>{t('NoResult')}</Text>
      </Stack>
    );
  }

  return (
    <Stack>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Name')}</Th>
              <Th>id</Th>
              <Th>{t('Share', { nb: 2 })}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {results.map(({ id, identity }) => {
              const managementLink =
                identity.type === IdentityType.COMPANY
                  ? generatePath(routes.manage.company.full, {
                      spaceId,
                      companyId: identity.companyIdentity?.id || '',
                    })
                  : generatePath(routes.manage.individual.full, {
                      spaceId,
                      individualIdentityId: identity.individualIdentity?.id || '',
                    });

              return (
                <Tr key={identity.id}>
                  <Td>
                    <IdentityCard>
                      <IdentityAvatar src={identity.profilePicture} type={identity.type} />
                      <Stack alignItems="flex-start" spacing="1">
                        <ChakraLink as={Link} to={managementLink}>
                          <IdentityCardTitle
                            fontSize="md"
                            fontWeight="500"
                            isChecked={identity.isVerified}
                          >
                            <Text fontWeight="600">{identity.name}</Text>
                            {identity.type === IdentityType.COMPANY && (
                              <Text fontWeight="500">({identity.identifier})</Text>
                            )}
                          </IdentityCardTitle>
                        </ChakraLink>
                        <IdentityCardDescription fontSize="md" fontWeight="400">
                          {identity.email}
                        </IdentityCardDescription>
                      </Stack>
                    </IdentityCard>
                  </Td>
                  <Td>
                    <HStack spacing="1">
                      <Text color="gray.500">{identity.id}</Text>
                      <Tooltip
                        hasArrow
                        isOpen={tooltipId === identity.id}
                        label={t('Copied')}
                        placement="top"
                      >
                        <Icon
                          as={DocumentDuplicateIcon}
                          boxSize="20px"
                          cursor="pointer"
                          onClick={(): void => handleIdCopyClick(identity.id)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="delete"
                      icon={<Icon as={TrashIcon} boxSize="18px" color="gray.700" />}
                      variant="secondary"
                      onClick={(): void => handleRowDeleteClick(id)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={paginationProps.currentPage}
        hasNextPage={paginationProps.pageInfo.hasNextPage}
        hasPreviousPage={paginationProps.pageInfo.hasPreviousPage}
        loading={paginationProps.isLoadingMore}
        pageCount={paginationProps.pageCount}
        onNextPage={paginationProps.handleNextPage}
        onPreviousPage={paginationProps.handlePreviousPage}
      />
    </Stack>
  );
}

export type SpaceManagementsTableProps = Props;
